import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import { ToastContainer, toast } from "react-toastify";

const ProductCategoryPopup = ({
  setRefresh,
  Refresh,
  ProductCategoryOpen,
  setProductCategoryOpen,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const handleClose = () => setProductCategoryOpen(false);

  const [categoryname, setCategoryName] = useState("");
  // console.log("categoreyname", categoryname);
  const [RecordId, setRecordId] = useState("");

  var d = 1;
  function getTimestampInSeconds() {
    d++;
    setRecordId(Math.floor(Date.now() / 1000) + AppCode + d);
  }
  const product = async () => {
    if (categoryname === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let field = {
        AppCode: AppCode,
        ProductCategoryName: categoryname,
        UserId: userId,
        RecordId: RecordId,
      };
      const response = await global.$axios
        .post("category/add", field)
        .then(async (response) => {
          if (response.data.status === 200) {
            setCategoryName("");
            setRefresh(!Refresh);
            handleClose();
            toast.success("Product catagory Added");
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("productcatagory", e);
        });
    }
  };
  const [err, seterr] = useState(0);

  const validation = async (val) => {
    // category/validateproductcategory/:AppCode/:ProductcategoryName
    const response = await global.$axios
      .get(`/category/validateproductcategory/${AppCode}/${val}`)
      .then((response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("productcatrgorypopvaludation", e);
      });
  };
  useEffect(() => {
    getTimestampInSeconds();
  }, []);

  return (
    <div>
      <Modal
        open={ProductCategoryOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 20px 0px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Add Product Category
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            value={categoryname}
            onChange={(e) => setCategoryName(e.target.value)}
            fullWidth
            placeholder="New Product Category Here*"
            size="small"
            onKeyUp={(e) => {
              e.target.value !== "" && validation(e.target.value);
            }}
            error={err === 1 ? true : false}
            helperText={
              err === 1 ? (
                <span style={{ color: "red" }}>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>KJHGF</span>
              )
            }
            required
          />

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              disabled={err === 1 ? true : false}
              variant="contained"
              onClick={() => {
                product();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default ProductCategoryPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
