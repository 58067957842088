import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, useMediaQuery } from "@material-ui/core";

const SolutionPopup = ({
  Refresh,
  setRefresh,
  SolutionOpen,
  setSolutionOpen,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const handleClose = () => setSolutionOpen(false);
  const [ticket, setTicket] = useState(null);
  const [ticketDrop, setTicketDrop] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const plainString = content.replace(/<[^>]+>/g, "");
  const ticketList = async () => {
    const dropdownResponse = await global.$axios
      .get(`/solutionconnect/dropdown/${AppCode}`)
      .then((dropdownResponse) => {
        setTicketDrop(dropdownResponse.data.Message);
      })
      .catch((e) => {
        console.error("solutionpop", e);
      });
  };

  const AddSolution = async () => {
    if (ticket === null) {
      toast.error("Please Choose The Ticket");
      return;
    } else if (plainString === "") {
      toast.error("Please Give Solution To The Ticket");
      return;
    } else {
      const response = await global.$axios
        .post(`/solutionconnect/add`, {
          AppCode: AppCode,
          IssueId: ticket.IssueId || "",
          IssueSolution: plainString,
          UserId: userId,
        })
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Solution Added");
            handleClose();
            setRefresh(!Refresh);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("solutionadd", e);
        });
    }
  };

  useEffect(() => {
    ticketList();
  }, []);

  return (
    <>
      <Dialog
        open={SolutionOpen}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Add Ticket Solution
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Autocomplete
            size="small"
            id="auto-complete"
            options={ticketDrop}
            value={ticket}
            onChange={(event, newValue) => {
              setTicket(newValue);
            }}
            getOptionLabel={(option) => option.IssueName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.IssueId}>
                {option.IssueName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Ticket" variant="outlined" />
            )}
          />
          <p
            style={{
              fontSize: "14px",
              margin: "15px 0px 0px 0px ",
            }}
          >
            Ticket Solution
          </p>
          <Grid item xs={12}>
            <p
              style={{
                padding: "10px 15px",
                background: "rgba(0, 113, 188, 0.1)",
                margin: "0px",
              }}
            >
              Description
            </p>
            <JoditEditor
              ref={editor}
              value={content}
              onBlur={(e) => setContent(e)}
              onChange={(e) => {
                setContent(e);
              }}
            />
          </Grid>
          <center style={{ marginTop: "20px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              onClick={() => {
                AddSolution();
                setRefresh(!Refresh);
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Dialog>
    </>
  );
};

export default SolutionPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
