import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  RightArrowIcon,
  NewIcon,
  ProgressIcon,
  HoldIcon,
  CompletedIcon,
  SolvedIcon,
  ViewIcon,
} from "../Assets/Icon";
import ProgressTable from "./myworkspaceComponent/ProgressTable";
import HoldTable from "./myworkspaceComponent/HoldTable";
import CompletedTable from "./myworkspaceComponent/CompletedTable";
import SolvedTable from "./myworkspaceComponent/SolvedTable";
import { useNavigate } from "react-router-dom";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useDemoData } from "@mui/x-data-grid-generator";
import moment from "moment";
import { Paper, Tooltip } from "@material-ui/core";
import AllTable from "./myworkspaceComponent/AllTable";
import dayjs from "dayjs";
import clsx from "clsx";
import NewTable from "./myworkspaceComponent/NewTable";

export default function MyWorkSpace() {
  const Navigate = useNavigate();
  const [head, setHead] = useState("New");
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [New, setNew] = useState([]);
  const [searchNew, setSearchNew] = useState([]);
  const [ticketCount, setTicketCount] = useState([]);
  const [slacount, setSlaCount] = useState([]);

  const NewList = async () => {
    const response = await global.$axios
      .post(`ticket/mNewTickets/${AppCode}/${userId}`)
      .then(async (response) => {
        setNew(response.data.Message);
        setSearchNew(response.data.Message);
      })
      .catch((e) => {
        console.log("MNew List Issue", e);
      });
  };

  const myWorkSpaceCount = async () => {
    const response = await global.$axios
      .get(`/ticket/myworkspaceCount/${AppCode}/${userId}`)
      .then(async (response) => {
        setTicketCount(response.data.msg[0]);
      })
      .catch((e) => {
        console.error("My Workspace Count Issue", e);
      });
  };

  const slacounts = async () => {
    const response = await global.$axios
      .get(`ticket/myworkspaceSLA/${AppCode}/${userId}`)

      .then(async (response) => {
        setSlaCount(response.data.msg[0]);
      })
      .catch((e) => {
        console.error("My Workspace SLA Count Issue", e);
      });
  };

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = New.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const UpdatedAt = String(
          moment(item?.UpdatedAt, "YYYY-MM-DD hh:mm").format("DD-MM-YY hh.mm A")
        );
        const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.assigned?.UserFirstName);
        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedAt.toLowerCase().indexOf(text_data) > -1 ||
          Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setSearchNew(ticketData);
    } else {
      setSearchNew(New);
    }
  }

  //Mui Premium

  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: ["Status"],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
      cellClassName: (params) => {
        let startDate = dayjs();
        var date = `${startDate.$d.getFullYear()}-${
          startDate.$d.getMonth() + 1 < 10
            ? "0" + (startDate.$d.getMonth() + 1)
            : startDate.$d.getMonth() + 1
        }-${
          startDate.$d.getDate() < 10
            ? "0" + startDate.$d.getDate()
            : startDate.$d.getDate()
        }`;
        var date3 = moment(params.row.ECAt, "YYYY-MM-DD hh:mm A").format(
          "YYYY-MM-DD"
        );
        var date4 = moment(
          params.row.TicketClosedTime,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD");
        return clsx("super-app", {
          Sla: date3 < date && date4 > date3,
        });
      },
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions ",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("/app/TicketView", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },

    // {
    //   field: "Updated By",
    //   headerName: "Updated By ",
    //   width: 150,
    //   headerClassName: "super-app-theme--header",
    //   valueGetter: UpdatedFullName,
    //   align: "center",
    //   headerAlign: "center",
    // },
    // {
    //   field: "Updated At",
    //   headerName: "Updated At",
    //   width: 150,
    //   headerClassName: "super-app-theme--header",
    //   valueGetter: (fields) =>
    //     moment(fields?.row?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
    //       "DD-MM-YY hh.mm A"
    //     ) || "",
    // },
    {
      field: "Assigned By",
      headerName: "Assigned By ",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.created
          ? `${fields?.row?.created?.UserFirstName || ""} ${
              fields?.row?.created?.UserLastName || ""
            }`
          : fields?.row?.contact?.ContactName,
    },
    {
      field: "Created At",
      headerName: "Created At",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.CreatedAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YY hh.mm A"
        ) || "",
    },
    {
      field: "ECAt",
      headerName: " Time To Complete",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YY hh.mm A"
        ) || "",
    },
  ];

  useEffect(() => {
    setHead(
      localStorage.getItem("MyWorkspace Tab") === null
        ? "New"
        : localStorage.getItem("MyWorkspace Tab")
    );
    NewList();
    myWorkSpaceCount();
    slacounts();
  }, []);

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item style={{ margin: "5px 0px 0px 0px" }}>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("MyWorkspace Tab", "All");
              setHead("All");
            }}
          >
            My Workspace
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            {head}
          </span>
        </Grid>
        <Grid item style={{ margin: "5px 0px 0px 0px" }}>
          <Button
            onClick={() => Navigate("/app/AddTicket")}
            style={{
              textTransform: "none",
              maxHeight: "22px",
              minHeight: "22px",
              padding: "12px",
              fontSize: "12px",
            }}
            variant="contained"
          >
            + Add More Ticket
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px 10px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("MyWorkspace Tab", "New");
            setHead("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>New</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <NewIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>{ticketCount.New}</p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.New}
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("MyWorkspace Tab", "Progress");
            setHead("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Progress</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <ProgressIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Progress}
            </p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Progress}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("MyWorkspace Tab", "Hold");
            setHead("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Hold</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <HoldIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Holding}
            </p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Holding}
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("MyWorkspace Tab", "Completed");
            setHead("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Completed</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <CompletedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Completed}
            </p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Completed}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("MyWorkspace Tab", "Solved");
            setHead("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Solved</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <SolvedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Solved}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Solved}
            </p>
          </div>
        </Grid>
        <Grid item lg={0} md={3.9} sm={5.9} xs={12} style={{}}></Grid>
      </Grid>

      {head === "New" && (
        <>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
              New
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                List
              </span>
            </p>
            <Box
              id="searcBox"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "3px",
                background: "#FFFFFF",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#2323234D",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <input
                placeholder="Search..."
                type="text"
                className="searcInput"
                id="searchInput"
                style={styles.searchInput}
                autoComplete="off"
                onChange={(e) => filterList(e.target.value)}
              />

              <GridSearchIcon style={{ color: "#2323234D" }} />
            </Box>
          </Grid>

          <Paper sx={{ overflow: "hidden" }}>
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .super-app-theme--header": {
                  color: "#0071BC",
                },
              }}
            >
              <DataGridPremium
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    margin: "0px",
                  },
                  ".MuiTablePagination-displayedRows": {
                    margin: "0px",
                  },

                  "& .super-app.Sla": {
                    backgroundColor: "rgb(252,204,203)",
                  },
                }}
                rowHeight={34}
                pagination={true}
                pageSize={10}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                {...data}
                apiRef={apiRef}
                columns={columns}
                rows={searchNew ? searchNew : []}
                getRowId={(row) => row.TicketId}
                loading={loading}
                disableRowSelectionOnClick
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              />
            </Box>
          </Paper>
        </>
      )}

      {head === "All" && <AllTable />}
      {/* {head === "New" && <NewTable />} */}
      {head === "Progress" && <ProgressTable />}
      {head === "Hold" && <HoldTable />}
      {head === "Completed" && <CompletedTable />}
      {head === "Solved" && <SolvedTable />}
    </>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};
