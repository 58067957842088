import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function EditBranchPopup() {
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const location = useLocation();

  const Editdata = location.state.data;

  //Dropdown
  const [Country, SetCountry] = useState([]);
  const [State, SetState] = useState([]);
  const [City, SetCity] = useState([]);

  //values
  const [BranchName, SetBranchName] = useState("");
  const [Bn, SetBN] = useState("");

  const [Code, SetCode] = useState("");
  const [Email, SetEmail] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [Mn, setMn] = useState("");

  const [Address, SetAddress] = useState("");
  const [Area, SetArea] = useState("");
  const [CountryValue, SetCountryValue] = useState([]);
  const [StateValue, SetStateValue] = useState([]);
  const [CityValue, SetCityvalue] = useState([]);

  //Api

  const CountryDrop = async () => {
    const response = await global.$axios
      .get("/userinfo/branch/country")
      .then(async (response) => {
        SetCountry(response.data.message);
      })
      .catch((e) => {
        console.error("editbranchcountrydrop", e);
      });
  };
  const StateDrop = async (id) => {
    const response = await global.$axios
      .get(`/userinfo/branch/states/${id}`)
      .then(async (response) => {
        SetState(response.data.message);
      })
      .catch((e) => {
        console.error("editbranchstatedrop", e);
      });
  };
  const CityDrop = async (id) => {
    const response = await global.$axios
      .get(`/userinfo/branch/cities/${id}`)
      .then(async (response) => {
        SetCity(response.data.message);
      })
      .catch((e) => {
        console.error("editbranchcitydrop", e);
      });
  };

  //Add
  const BranchAdd = async () => {
    if (BranchName == "" || MobileNumber == "") {
      toast.error("Please Fill Required Fields");
    } else if (MobileNumber != "" && MobileNumber.length !== 10) {
      toast.error("Number Must Be 10 Digits");
      return;
    } else if (Email != "" && NoValidEmail) {
      toast.error("Please Provide Valid Mail");
      return;
    } else {
      const data = {
        AppCode: AppCode,
        BranchCode: Code,
        BranchName: BranchName,
        BranchEmail: Email,
        BranchMobileNumber: MobileNumber,
        Area: Area,
        Address: Address,
        Country: CountryValue?.CountryId,
        State: StateValue?.StateId,
        City: CityValue?.CityId,
        UserId: userId,
      };

      const response = await global.$axios
        .post(`userinfo/branch/edit/${Editdata.BranchId}`, data)
        .then(async (response) => {
          if (response.data.status == 200) {
            toast.success("Branch Edited Successfully ");
            setTimeout(() => {
              Navigate(-1);
            }, [1500]);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("editbranchpopoup", e);
        });
    }
  };

  // error validation
  const [Err, setErr] = useState(0);
  const Validate = async (val) => {
    const response = await global.$axios
      .get(`/userinfo/branch/validatebranch/${AppCode}/${val}`)
      .then(async (response) => {
        setErr(response.data.Message);
      })
      .catch((e) => {
        console.error("editbranch validation", e);
      });
  };

  // error validation

  const [numberErr, setNumberErr] = useState(0);

  const contactValidation = async (val) => {
    const response = await global.$axios
      .get(`userinfo/branch/validatebranchmn/${AppCode}/${val}`)
      .then(async (response) => {
        setNumberErr(response.data.Message);
      })
      .catch((e) => {
        console.error("editbranch numbervalidate", e);
      });
  };
  const [mobileerr, setmobileerr] = useState(false);
  const mobile = () => {
    if (MobileNumber.length !== 10) {
      setmobileerr(true);
    } else {
      setmobileerr();
    }
  };

  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };

  //edit
  const Edit = async () => {
    const response = await global.$axios
      .get(`/userinfo/branch/view/${AppCode}/${Editdata.BranchId}`)
      .then(async (response) => {
        var res = response?.data?.Message;
        CountryDrop();
        StateDrop(res?.Countries?.CountryId);
        CityDrop(res?.States?.StateId);
        SetBranchName(res?.BranchName);
        SetBN(res?.BranchName);
        SetCode(res?.BranchCode);
        SetEmail(res?.BranchEmail);
        setMobileNumber(res?.BranchMobileNumber);
        setMn(res?.BranchMobileNumber);
        SetAddress(res?.Address);
        SetArea(res?.Area);
        SetCountryValue(res?.Countries);
        SetStateValue(res?.States);
        SetCityvalue(res?.Cities);
      })
      .catch((e) => {
        console.error("editbrancheditview", e);
      });
  };
  useEffect(() => {
    Edit();
  }, []);

  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => {
              Navigate(-1);
            }}
          >
            Users /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Edit Branch
          </span>
        </div>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Branch Name *</p>
            <TextField
              type="text"
              fullWidth
              value={BranchName}
              placeholder="Enter Branch Name"
              size="small"
              onChange={(e) => SetBranchName(e.target.value)}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Code </p>
            <TextField
              fullWidth
              value={Code}
              placeholder="Enter Code"
              size="small"
              onChange={(e) => SetCode(e.target.value)}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Email Id </p>
            <TextField
              type="email"
              fullWidth
              value={Email}
              placeholder="Enter Email Id"
              size="small"
              onChange={(e) => SetEmail(e.target.value)}
              onKeyUp={(e) => {
                e.target.value != "" && MailValidate(e.target.value);
              }}
              helperText={
                NoValidEmail ? (
                  <span style={{ color: "darkred" }}>Not a Valid Mail</span>
                ) : (
                  <span style={{ color: "transparent" }}>Good</span>
                )
              }
            />
          </Grid>

          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Mobile Number *</p>
            <TextField
              type="text"
              fullWidth
              placeholder="Enter Mobile Number"
              size="small"
              inputProps={{ maxLength: "10" }}
              error={numberErr === 1 && MobileNumber != Mn ? true : false}
              onChange={(e) => setMobileNumber(e.target.value)}
              value={MobileNumber}
              onKeyUp={(e) => {
                e.target.value !== "" && contactValidation(e.target.value);
                mobile(e.target.value);
              }}
              helperText={
                mobileerr === true ? (
                  <span style={{ color: "darkred" }}>10 Digit Required</span>
                ) : numberErr === 1 ? (
                  <span style={{ color: "darkred" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>"help"</span>
                )
              }
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Address</p>
            <TextField
              fullWidth
              value={Address}
              placeholder="Enter Address"
              size="small"
              onChange={(e) => SetAddress(e.target.value)}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Area</p>
            <TextField
              fullWidth
              value={Area}
              placeholder="Enter Area"
              size="small"
              onChange={(e) => SetArea(e.target.value)}
            />
          </Grid>

          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Country </p>
            <Autocomplete
              id="Area"
              size="small"
              value={CountryValue}
              options={Country}
              getOptionLabel={(option) => option.CountryName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Country "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                SetCountryValue(newValue);
                StateDrop(newValue.CountryId);
                SetStateValue([]);
                SetCityvalue([]);
              }}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>State </p>
            <Autocomplete
              id="Area"
              size="small"
              value={StateValue}
              options={State}
              getOptionLabel={(option) => option.StateName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select State "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                CityDrop(newValue.StateId);
                SetStateValue(newValue);
                SetCityvalue([]);
              }}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>City </p>
            <Autocomplete
              id="Area"
              size="small"
              value={CityValue}
              options={City}
              getOptionLabel={(option) => option.CityName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select City "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                SetCityvalue(newValue);
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0px 30px 0px",
            borderTop: "1px solid rgb(0,0,0,0.2)",
            padding: "10px",
          }}
        >
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            disabled={Err === 1 ? true : false}
            size="small"
            variant="contained"
            onClick={() => {
              BranchAdd();
            }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}

export default EditBranchPopup;
const style1 = {
  txttitle: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};
