import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  DataGridPremium,
  GridSearchIcon,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import clsx from "clsx";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

function Sms() {
  //usestate
  const [PeriodSelect, SetPeriodSelect] = useState({
    PeriodId: "1",
    PeriodName: "All",
  });
  const [OwnerDrop, SetOwnerDrop] = useState([]);
  const [OwnerValue, SetOwnerValue] = useState([]);
  const [Date, SetDate] = useState(dayjs());
  // console.log(Date, "dateeee");

  const options = ["Option 1", "Option 2"];

  //Mui Premium
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });
  // const columns = [
  //     {
  //         field: "TicketId",
  //         headerName: "Ticket ID",
  //         width: 80,
  //         headerClassName: "super-app-theme--header",
  //         align: "center",
  //         cellClassName: (params) => {
  //             let startDate = dayjs();
  //             var date = `${startDate.$d.getFullYear()}-${startDate.$d.getMonth() + 1 < 10
  //                 ? "0" + (startDate.$d.getMonth() + 1)
  //                 : startDate.$d.getMonth() + 1
  //                 }-${startDate.$d.getDate() < 10
  //                     ? "0" + startDate.$d.getDate()
  //                     : startDate.$d.getDate()
  //                 }`;
  //             var date3 = moment(params.row.ECAt, "YYYY-MM-DD hh:mm A").format(
  //                 "YYYY-MM-DD"
  //             );
  //             console.log(date3, date, params.row.TicketId, "finaldata");
  //             var date4 = moment(
  //                 params.row.TicketClosedTime,
  //                 "YYYY-MM-DD hh:mm A"
  //             ).format("YYYY-MM-DD");
  //             console.log(date3, date, params.row.TicketId, "finaldata");
  //             // return (date3 < date && date4 > date3) ? 'Sla' : 'cold';
  //             return clsx("super-app", {
  //                 Sla: date3 < date && date4 > date3,
  //             });
  //         },
  //     },
  //     // {
  //     //     field: "AttachmentFile" || "",
  //     //     headerName: "Actions ",
  //     //     width: 100,
  //     //     headerClassName: "super-app-theme--header",
  //     //     renderCell: (cellValues) => {
  //     //         return (
  //     //             <>
  //     //                 <Tooltip title="Ticket Transfer">
  //     //                     <CompareArrowsIcon
  //     //                         style={{ width: "23px", height: "20px" }}
  //     //                         onClick={() => {
  //     //                             SetEdit(cellValues.row);
  //     //                         }}
  //     //                     />
  //     //                 </Tooltip>

  //     //                 <Tooltip title="View Ticket">
  //     //                     <ViewIcon
  //     //                         style={{ width: "20px", height: "20px" }}
  //     //                         onClick={() =>
  //     //                             Navigate("AllView", {
  //     //                                 state: { data: cellValues.row },
  //     //                             })
  //     //                         }
  //     //                     />
  //     //                 </Tooltip>
  //     //             </>
  //     //         );
  //     //     },
  //     //     align: "center",
  //     //     headerAlign: "center",
  //     // },
  //     {
  //         field: "CompanyName",
  //         headerName: "Company Name",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
  //     },
  //     {
  //         field: "ContactName",
  //         headerName: "Contact Name",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) => fields?.row?.contact?.ContactName,
  //     },
  //     {
  //         field: "Department",
  //         headerName: "Department",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) => fields?.row?.dept?.DepartmentName,
  //     },
  //     {
  //         field: "ProductName",
  //         headerName: "Product Name",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) => fields?.row?.product?.ProductName || "",
  //     },
  //     {
  //         field: "IssueName",
  //         headerName: "Issue Name",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
  //     },
  //     {
  //         field: "TicketDescription",
  //         headerName: "Description",
  //         width: 200,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) => {
  //             var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
  //             var format = space.replace(/\&nbsp;/g, "");
  //             return format || "";
  //         },
  //     },
  //     {
  //         field: "Status",
  //         headerName: "Status",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     {
  //         field: "ECAt",
  //         headerName: " Time To Complete",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) =>
  //             moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
  //                 "DD-MM-YY hh.mm A"
  //             ) || "",
  //     },
  //     {
  //         field: "Assigned By",
  //         headerName: "Assigned By ",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) =>
  //             fields?.row?.created
  //                 ? `${fields?.row?.created?.UserFirstName || ""} ${fields?.row?.created?.UserLastName || ""
  //                 }`
  //                 : fields?.row?.contact?.ContactName,
  //     },

  //     {
  //         field: "Updated At",
  //         headerName: "Created At",
  //         width: 150,
  //         headerClassName: "super-app-theme--header",
  //         valueGetter: (fields) =>
  //             moment(fields?.row?.CreatedAt, "YYYY-MM-DD hh:mm").format(
  //                 "DD-MM-YY hh.mm A"
  //             ) || "",
  //     },

  //     // {
  //     //   field: "TicketView" || "",
  //     //   headerName: "Actions",
  //     //   width: 80,
  //     //   headerClassName: "super-app-theme--header",
  //     //   renderCell: (cellValues) => {
  //     //     return (

  //     //     );
  //     //   },
  //     //   align: "center",
  //     //   headerAlign: "center",
  //     // },
  //     // (Id.length != 0 ?  NewList()  : null)
  // ];
  //Search
  // async function filterList(term) {
  //     let ticketData = [];
  //     // console.log(term, "term");
  //     if (term !== "") {
  //         ticketData = All.filter((item) => {
  //             // console.log(item, "iteemmmm");
  //             const TicketId = String(item.TicketId);
  //             const CompanyName = String(item?.contact?.account?.CompanyName);
  //             const ContactName = String(item?.contact?.ContactName);
  //             const ProductName = String(item?.product?.ProductName);
  //             const IssueName = String(item?.issue?.IssueName);
  //             const UpdatedBy = String(item?.updated?.UserFirstName);
  //             const ETC = String(
  //                 moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
  //             );
  //             const ClosedTime = String(
  //                 moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
  //                     "DD-MM-YYYY hh:mm A"
  //                 )
  //             );
  //             // const Description = String(item?.TicketDescription);
  //             const AssignedBy = String(item?.created?.UserFirstName);
  //             const Status = String(item?.Status);

  //             const text_data = term.toLowerCase();
  //             // console.log(text_data, "neeed");
  //             return (
  //                 TicketId.indexOf(text_data) > -1 ||
  //                 CompanyName.toLowerCase().indexOf(text_data) > -1 ||
  //                 ContactName.toLowerCase().indexOf(text_data) > -1 ||
  //                 ProductName.toLowerCase().indexOf(text_data) > -1 ||
  //                 IssueName.toLowerCase().indexOf(text_data) > -1 ||
  //                 UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
  //                 ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
  //                 // Description.toLowerCase().indexOf(text_data) > -1 ||
  //                 AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
  //                 ETC.toLowerCase().indexOf(text_data) > -1 ||
  //                 Status.toLowerCase().indexOf(text_data) > -1
  //             );
  //         });
  //         Setsearch(ticketData);
  //     } else {
  //         Setsearch(All);
  //     }
  // }

  const Period = [
    {
      PeriodId: "1",
      PeriodName: "All",
    },
    {
      PeriodId: "2",
      PeriodName: "Daily",
    },
    {
      PeriodId: "3",
      PeriodName: "Monthly",
    },
    {
      PeriodId: "4",
      PeriodName: "Yearly",
    },
    {
      PeriodId: "5",
      PeriodName: "Duration",
    },
  ];
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];
  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   localStorage.setItem("All Tab", "All");
            //   setTable("All");
            // }}
          >
            Communication
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            SMS Log
          </span>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          marginTop: "20px",
        }}
      >
        <Grid
          container
          item
          xs={8}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={options}
              value={OwnerValue}
              disableClearable
              // isOptionEqualToValue={(option, value) =>
              //     option.DepartmentName == value.DepartmentName
              // }
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.DepartmentId}>
                  {option.DepartmentName}
                </Box>
              )}
              getOptionLabel={(option) => option.options || ""}
              size="small"
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label=" Owner" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={Period}
              value={PeriodSelect}
              disableClearable
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.PeriodId}>
                  {option.PeriodName}
                </Box>
              )}
              getOptionLabel={(option) => option.PeriodName || ""}
              size="small"
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label="Period" />
              )}
              onChange={(event, newValue) => {
                SetPeriodSelect(newValue);
              }}
            />
          </Grid>
          {PeriodSelect.PeriodName == "Daily" ? (
            <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={Date}
                  disableFuture
                  onChange={(newValue) => SetDate(newValue)}
                  label="Select the date"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : PeriodSelect.PeriodName == "Monthly" ? (
            <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={Date}
                  disableFuture
                  views={["month", "year"]}
                  onChange={(newValue) => SetDate(newValue)}
                  label="Select the Month"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : PeriodSelect.PeriodName == "Yearly" ? (
            <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={Date}
                  disableFuture
                  views={["year"]}
                  onChange={(newValue) => SetDate(newValue)}
                  label="Select the Year"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : PeriodSelect.PeriodName == "Duration" ? (
            <Grid item xs={12} md={4.8} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  disableFuture
                  label="Select the Year"
                  onChange={(newValue) => SetDate(newValue)}
                  slotProps={{ textField: { size: "small" } }}
                  localeText={{ start: "From", end: "To" }}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            <Grid item xs={3.8}></Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          All
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            // onChange={(e) => filterList(e.target.value)}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
            "& .super-app.Sla": {
              backgroundColor: "rgb(252,204,203)",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
              "& .super-app-theme--New": {
                backgroundColor: "rgb(229,243,253)",
                "&:hover": {
                  backgroundColor: "rgb(179,229,251)",
                },
              },
            }}
            rowHeight={33}
            {...data}
            apiRef={apiRef}
            columns={columns}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            // rows={Search ? Search : []}
            rows={rows}
            getRowId={(row) => row.id}
            loading={loading}
            pagination={true}
            initialState={initialState}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            slots={{ toolbar: GridToolbar }}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.Status}`
            }
          />
        </Box>
      </Paper>
    </>
  );
}

export default Sms;
