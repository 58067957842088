import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../Assets/Icon";
import { useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function TagUsers({
  Popupopen,
  setPopupopen,
  TicketId,
  Edittag,
  setRefresh,
  refresh,
  editview,
}) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const handleClose = () => {
    setPopupopen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //Usestate Dropdown
  const [Departmentdrop, SetDepartment] = useState([]);
  const [UserDrop, SetUserDrop] = useState([]);

  //Usestate DropValue
  const [DepatementValue, SetDepartmentValue] = useState(
    Edittag.Departement ? Edittag.Departement : []
  );
  const [UserValue, SetUserValue] = useState(
    Edittag.TagedUsers ? Edittag.TagedUsers : []
  );
  const [reject, setReject] = useState("0");
  // console.log(reject, "disabled");

  //API
  const Departmentapi = async () => {
    const response = await global.$axios
      .get(`/ticket/department/${AppCode}`)
      .then(async (response) => {
        SetDepartment(response.data.Message);
        Userapi(
          Edittag?.Departement
            ? Edittag?.Departement
            : [response.data.Message[0].DepartmentId]
        );
      })
      .catch((e) => {
        console.error("DepartmentApi", e);
      });
  };

  const Userapi = async (id) => {
    var departmap = id?.map((e) => e.DepartmentId);
    const response = await global.$axios
      .put(`/ticket/team/user/${AppCode}`, {
        DepartmentId: departmap,
      })
      .then(async (response) => {
        SetUserDrop(response.data.Message);
        wecan(response.data.Message, UserValue);
      })
      .catch((e) => {
        console.error("DepartmentApi", e);
      });
  };

  const TagApi = async () => {
    const userid = UserValue.map((e) => e.UserId);
    const Depatid = DepatementValue.map((e) => e.DepartmentId);
    const response = await global.$axios
      .post(`/ticket/taguser`, {
        DepartmentId: Depatid,
        Taguser: userid,
        AppCode: AppCode,
        TicketId: TicketId,
      })
      .then(async (response) => {
        // console.log(response, "finalresponse");
        if (response.data.Status == "200") {
          toast.success("Users has been Taged SuccessFully");
          setRefresh(!refresh);
          editview();
          setTimeout(() => {
            handleClose();
          }, 1000);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  const wecan = (response, newvalue) => {
    SetUserValue(
      response.filter((x) => newvalue.some((y) => x.UserId === y.UserId))
    );
  };

  useEffect(() => {
    Departmentapi();
  }, []);
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={Popupopen}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby="responsive-dialog-title"
        sx={{
          ".css - twia2z - MuiPaper - root - MuiDialog - paper": {
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px",
            margin: "10px",
          }}
        >
          <div
            style={{
              margin: "10px",
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Tag Users
          </div>
          <div>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container style={{ paddingTop: "5px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={Departmentdrop}
              fullWidth
              multiple
              value={DepatementValue}
              isOptionEqualToValue={(option, value) =>
                option.DepartmentName == value.DepartmentName
              }
              getOptionLabel={(option) => option.DepartmentName || ""}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label=" Department" />
              )}
              onChange={(event, newvalue) => {
                SetDepartmentValue(newvalue);
                Userapi(newvalue);
                // if (newvalue.length == 0) {
                // SetUserValue([])
                // }
              }}
            />
          </Grid>
          <Grid container style={{ paddingTop: "5px", marginTop: "5px" }}>
            <Autocomplete
              id="combo-box-demo"
              multiple
              options={UserDrop}
              fullWidth
              value={UserValue}
              isOptionEqualToValue={(option, value) =>
                option.UserName == value.UserName
              }
              getOptionLabel={(option) => option.UserName || ""}
              size="small"
              renderInput={(params) => <TextField {...params} label=" User" />}
              onChange={(event, newvalue) => {
                SetUserValue(newvalue);

                setReject(1);
              }}
            />
          </Grid>
        </DialogContent>
        <center style={{ marginTop: "10px", paddingBottom: "10px" }}>
          {UserValue.length >= 1 ? (
            <Button
              variant="contained"
              onClick={() => TagApi()}
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => TagApi()}
              disabled
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
            >
              Submit
            </Button>
          )}
        </center>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}
