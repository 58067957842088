import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";

const EditBrandPopup = ({ data, EditPopupOpen, setEditPopupOpen, active }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setEditPopupOpen(true);
  const handleClose = () => setEditPopupOpen(false);

  const [Activelist, setActiveList] = useState([]);

  const [brandname, setBrandName] = useState(data.BrandName);

  //validation edit

  const [err, seterr] = useState(0);

  const validation = async (val) => {

    const response = await global.$axios
      .get(`product/validatebrandname/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("editbrandvalidation", e);
      });
  };

  const brand = async () => {
    if (brandname === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let field = {
        AppCode: AppCode,
        BrandName: brandname,
        UserId: userId,
      };
      const response = await global.$axios
        .post(`product/brand/edit/${data.BrandId}`, field)
        .then(async (response) => {
          if (response.data.status === 200) {
            active();
            handleClose();
            setBrandName("");
            toast.success("Brand Edited");
          }
        })
        .catch((e) => {
          console.error("brandeditpopup", e);
        });
    }
  };
  useEffect(() => {
    setActiveList();
  }, []);

  return (
    <div>
      <Modal
        open={EditPopupOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 20px 0px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Edit Brand Name
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          {/* <Grid item xs={12}> */}
          <TextField
            onChange={(e) => setBrandName(e.target.value)}
            sx={{ minWidth: 300 }}
            size="small"
            defaultValue={data.BrandName}
            onKeyUp={(e) => {
              e.target.value !== "" && validation(e.target.value);
            }}
            error={err === 1 && data.BrandName != brandname ? true : false}
            helperText={
              err === 1 && data.BrandName != brandname ? (
                <span style={{ color: "red" }}>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>KJHGF</span>
              )
            }
          />

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                brand();
              }}
            >
              Update
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default EditBrandPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
