import { Box, Button, Grid, IconButton, Modal, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CloseIcon } from "../../../Assets/Icon";

const CustomerCodePopup = ({
  CustomerCodeOpen,
  setCustomerCodeOpen,
  Refresh,
  setRefresh,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //Usestate
  const [CCode, setCCode] = useState("");
  const handleClose = () => setCustomerCodeOpen(false);
  const [Err, setErr] = useState(0);

  //API
  const AddCategory = async () => {
    if (CCode === "") {
      toast.error("Please provide Code");
      return;
    } else {
      const response = await global.$axios.post(`/customercode/add`, {
        AppCode: AppCode,
        CustomerCode: CCode,
        UserId: userId,
      })
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Code Added");
            handleClose();
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("catagorycodepopup", e)
        })

    }
  };

  const Validate = async (name) => {
    const response = await global.$axios.get(
      `customercode/validatecustomercode/${AppCode}/${name}`
    )
      .then(async (response) => {
        setErr(response.data.Message);
      })
      .catch((e) => {
        console.error("custonercode", e)
      })
  };
  return (
    <div>
      <Modal
        open={CustomerCodeOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                color: "#0071BC",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {" "}
              Add Customer's Code
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            value={CCode}
            onChange={(e) => setCCode(e.target.value)}
            onKeyUp={(e) => {
              e.target.value !== "" && Validate(e.target.value);
            }}
            error={Err === 1 ? true : false}
            helperText={
              Err === 1 ? (
                <span>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>All</span>
              )
            }
            fullWidth
            placeholder="Add Code Here"
            id="outlined-size-small"
            size="small"
            required
          />

          <center style={{ marginTop: "0px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={Err === 1 ? true : false}
              onClick={() => {
                AddCategory();
                setRefresh(!Refresh);
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default CustomerCodePopup;
