import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  DataGridPremium,
  GridSearchIcon,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { TicketIdIcon } from "../../Assets/Icon";
import FiberManualRecordTwoToneIcon from "@mui/icons-material/FiberManualRecordTwoTone";

function Whatsapp() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //usestate
  const [PeriodSelect, SetPeriodSelect] = useState({
    PeriodId: "2",
    PeriodName: "Daily",
  });
  const [OwnerDrop, SetOwnerDrop] = useState([]);
  const [OwnerValue, SetOwnerValue] = useState([]);

  const [Date, SetDate] = useState(dayjs());

  //tableusestate
  const [TableData, SetTableData] = useState([]);
  const [TableSearch, SetTableSearch] = useState([]);
  const [DepartmentAll, setDepartmentAll] = useState([]);
  // timefield shortcut
  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = dayjs();
        return [today.startOf("week"), today.endOf("week")];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, "day"), today];
      },
    },

    { label: "Reset", getValue: () => [null, null] },
  ];

  //api
  const getuers = async () => {
    const response = await global.$axios
      .post(`ticket/getuserlog/${AppCode}`, {
        UserId: [userId],
      })
      .then(async (response) => {
        if (response.data.Message.length >= 1) {
          var mapdata = response.data.Message.map((e) => e.UserId);
          SetOwnerDrop([
            ...[{ UserId: "All", username: "All" }],
            ...response.data.Message,
          ]);
          SetOwnerValue({ UserId: "All", username: "All" });
          setDepartmentAll(mapdata);
          tabledata(mapdata, PeriodSelect, Date, []);
        } else {
          SetOwnerDrop([response?.data?.Message]);
          SetOwnerValue(response?.data?.Message);
          setDepartmentAll([response?.data?.Message?.UserId]);
          tabledata(response?.data?.Message?.UserId, PeriodSelect, Date, []);
        }
      });
  };
  const tabledata = async (userid, period, Datevalue, Datevalue1) => {
    var datemonth =
      Datevalue != null &&
      `${Datevalue.$d.getFullYear()}-${Datevalue.$d.getMonth() + 1 < 10
        ? "0" + (Datevalue.$d.getMonth() + 1)
        : Datevalue.$d.getMonth() + 1
      }`;
    var datedaily =
      Datevalue != null &&
      `${Datevalue.$d.getFullYear()}-${Datevalue.$d.getMonth() + 1 < 10
        ? "0" + (Datevalue.$d.getMonth() + 1)
        : Datevalue.$d.getMonth() + 1
      }-${Datevalue.$d.getDate() < 10
        ? "0" + Datevalue.$d.getDate()
        : Datevalue.$d.getDate()
      } `;
    var dateyear = Datevalue != null && `${Datevalue.$d.getFullYear()}`;
    if (
      Datevalue1.length >= 1 &&
      Datevalue1[0] != null &&
      Datevalue1[0].$d != "Invalid Date" &&
      Datevalue1[1] != null &&
      Datevalue1[1].$d != "Invalid Date"
    ) {
      var dateFrom =
        Datevalue1[0] != null &&
        `${Datevalue1[0].$d.getFullYear()}-${Datevalue1[0].$d.getMonth() + 1 < 10
          ? "0" + (Datevalue1[0].$d.getMonth() + 1)
          : Datevalue1[0].$d.getMonth() + 1
        }-${Datevalue1[0].$d.getDate() < 10
          ? "0" + Datevalue1[0].$d.getDate()
          : Datevalue1[0].$d.getDate()
        } `;
      var dateTo =
        Datevalue1[1] != null &&
        `${Datevalue1[1].$d.getFullYear()}-${Datevalue1[1].$d.getMonth() + 1 < 10
          ? "0" + (Datevalue1[1].$d.getMonth() + 1)
          : Datevalue1[1].$d.getMonth() + 1
        } -${Datevalue1[1].$d.getDate() < 10
          ? "0" + Datevalue1[1].$d.getDate()
          : Datevalue1[1].$d.getDate()
        } `;
    }
    const data = {
      AppCode: AppCode,
      UserId: userid,
      frequency:
        period.PeriodName === "Daily"
          ? 0
          : period.PeriodName === "Monthly"
            ? 1
            : period.PeriodName === "Yearly"
              ? 2
              : period.PeriodName === "Duration"
                ? 3
                : "",
      fromdate: dateFrom ? dateFrom : "",
      todate: dateTo ? dateTo : "",
      whatsDateTime:
        period.PeriodName === "Daily"
          ? datedaily
          : period.PeriodName === "Monthly"
            ? datemonth
            : period.PeriodName === "Yearly"
              ? dateyear
              : "",
    };
    const response = await global.$axios
      .post(`ticket/getwhatsapplog`, data)
      .then(async (response) => {
        SetTableData(response.data.Message);
        SetTableSearch(response.data.Message);
      });
  };

  //Mui Premium
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  const Period = [
    // {
    //     PeriodId: "1",
    //     PeriodName: "All"
    // },
    {
      PeriodId: "2",
      PeriodName: "Daily",
    },
    {
      PeriodId: "3",
      PeriodName: "Monthly",
    },
    {
      PeriodId: "4",
      PeriodName: "Yearly",
    },
    {
      PeriodId: "5",
      PeriodName: "Duration",
    },
  ];
  // Search
  async function filterList(term) {
    let ticketData = [];
    // console.log(term, "term");
    if (term !== "") {
      ticketData = TableData.filter((item) => {
        // console.log(item, "iteemmmm");
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        // const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        // console.log(text_data, "neeed");
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      SetTableSearch(ticketData);
    } else {
      SetTableSearch(TableData);
    }
  }

  function contant(cellValues) {
    // console.log(cellValues, "cellValues");

    const data = JSON.parse(cellValues?.row?.whatappsContent)?.request
      ?.parameters
      ? JSON.parse(cellValues?.row?.whatappsContent)?.request?.parameters
      : [];

    return (
      <div>
        {data?.map((e, index) => (
          <div
            style={{
              display: "flex",
              gap: "5px",
              padding: "5px 0px ",
              alignItems: "center",
            }}
            key={index}
          >
            <FiberManualRecordTwoToneIcon
              sx={{ pr: "5px", width: "20px", height: "20px" }}
            />
            <span style={{ textTransform: "capitalize" }}>{e?.name}</span>:
            <span style={{ textTransform: "capitalize" }}>
              {e?.value ? e?.value : "-"}
            </span>
          </div>
        ))}
      </div>
    );
  }
  const columns = [
    {
      field: "whatappsLogId",
      headerName: "Id",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ToMobileNumber",
      headerName: "MobileNumber",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "whatsDateTime",
      headerName: " Date Time",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.whatsDateTime, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YY hh.mm A"
        ) || "",
    },
    {
      field: "whatappsContent",
      headerName: "Contant",
      width: 640,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return contant(cellValues);
      },
    },
  ];

  useEffect(() => {
    getuers();
    tabledata();
  }, []);
  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
          // onClick={() => {
          //   localStorage.setItem("All Tab", "All");
          //   setTable("All");
          // }}
          >
            Communication
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            Whatsapp Log
          </span>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          marginTop: "20px",
        }}
      >
        <Grid
          container
          item
          xs={8}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={OwnerDrop}
              value={OwnerValue}
              disableClearable
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.UserId}>
                  {option.username}
                </Box>
              )}
              getOptionLabel={(option) => option.username || ""}
              size="small"
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label=" Owner" />
              )}
              onChange={(event, newValue) => {
                SetOwnerValue(newValue);
                if (newValue.UserId === "All") {
                  tabledata(DepartmentAll, PeriodSelect, Date, []);
                } else {
                  tabledata([newValue.UserId], PeriodSelect, Date, []);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={Period}
              value={PeriodSelect}
              disableClearable
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.PeriodId}>
                  {option.PeriodName}
                </Box>
              )}
              getOptionLabel={(option) => option.PeriodName || ""}
              size="small"
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label="Period" />
              )}
              onChange={(event, newValue) => {
                SetPeriodSelect(newValue);
                if (OwnerValue.UserId === "All") {
                  tabledata(DepartmentAll, newValue, Date, []);
                } else {
                  tabledata([OwnerValue.UserId], newValue, Date, []);
                }
              }}
            />
          </Grid>
          {PeriodSelect.PeriodName == "Daily" ? (
            <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={Date}
                  disableFuture
                  format="DD-MM-YYYY"
                  onChange={(newValue) => {
                    SetDate(newValue);
                    if (OwnerValue.UserId === "All") {
                      tabledata(DepartmentAll, PeriodSelect, newValue, []);
                    } else {
                      tabledata(
                        [OwnerValue.UserId],
                        PeriodSelect,
                        newValue,
                        []
                      );
                    }
                  }}
                  label="Select the date"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : PeriodSelect.PeriodName == "Monthly" ? (
            <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={Date}
                  disableFuture
                  format="MM-YYYY"
                  onChange={(newValue) => {
                    SetDate(newValue);
                    if (OwnerValue.UserId === "All") {
                      tabledata(DepartmentAll, PeriodSelect, newValue, []);
                    } else {
                      tabledata(
                        [OwnerValue.UserId],
                        PeriodSelect,
                        newValue,
                        []
                      );
                    }
                  }}
                  label="Select the Month"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : PeriodSelect.PeriodName == "Yearly" ? (
            <Grid item xs={12} md={3.5} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={Date}
                  disableFuture
                  views={["year"]}
                  onChange={(newValue) => {
                    SetDate(newValue);
                    if (OwnerValue.UserId === "All") {
                      tabledata(DepartmentAll, PeriodSelect, newValue, []);
                    } else {
                      tabledata(
                        [OwnerValue.UserId],
                        PeriodSelect,
                        newValue,
                        []
                      );
                    }
                  }}
                  label="Select the Year"
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Grid>
          ) : PeriodSelect.PeriodName == "Duration" ? (
            <Grid item xs={12} md={4.8} style={{ marginBottom: "8px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  disableFuture
                  label="Select the Year"
                  format="DD-MM-YYYY"
                  // defaultValue={[dayjs().startOf('month'), dayjs()]}
                  onChange={(newValue) => {
                    SetDate(newValue);
                    if (OwnerValue.UserId === "All") {
                      tabledata(DepartmentAll, PeriodSelect, null, newValue);
                    } else {
                      tabledata(
                        [OwnerValue.UserId],
                        PeriodSelect,
                        null,
                        newValue
                      );
                    }
                  }}
                  slotProps={{
                    textField: { size: "small" },
                    shortcuts: {
                      items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                  }}
                  calendars={2}
                  localeText={{ start: "From", end: "To" }}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            <Grid item xs={3.8}></Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
            "& .super-app.Sla": {
              backgroundColor: "rgb(252,204,203)",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
              "& .super-app-theme--New": {
                backgroundColor: "rgb(229,243,253)",
                "&:hover": {
                  backgroundColor: "rgb(179,229,251)",
                },
              },
            }}
            getRowHeight={() => "auto"}
            {...data}
            apiRef={apiRef}
            columns={columns}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rows={TableSearch ? TableSearch : []}
            getRowId={(row) => row.whatappsLogId}
            loading={loading}
            pagination={true}
            initialState={initialState}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            slots={{ toolbar: GridToolbar }}
            getRowClassName={(params) =>
              `super- app - theme--${params.row.Status} `
            }
          />
        </Box>
      </Paper>
    </>
  );
}

export default Whatsapp;
