import { Button, Dialog, Grid, useMediaQuery } from "@material-ui/core";
import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CloseIcon } from "../../Assets/Icon";

const ProductAdd = ({
  ProductAddOpen,
  setProductAddOpen,
  AccountsId,
  ContactId,
  setProductDrop,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const handleClose = () => setProductAddOpen(false);
  const [Product, setProduct] = useState([]);
  const [ProductDrop, setProductDroplist] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //MultiSelector
  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const ProductList = async () => {
    const response = await global.$axios.get(`/product/dropdown/${AppCode}`);
    setProductDroplist(response.data.Message);
  };

  const AddProduct = async () => {
    const productArray = Product.map((x) => x.ProductId);

    if (productArray.length == 0) {
      toast.error("Please Add Product");
      return;
    } else {
      const response = await global.$axios.post(`/product/addproduct`, {
        AppCode: AppCode,
        AccountsId: AccountsId,
        ProductId: productArray,
        ContactId: ContactId,
        UserId: userId,
      });
      if (response.data.status === 200) {
        setProductDrop(response.data.Message);
        toast.success("Product Added To This Contact");
        handleClose();
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    ProductList();
  }, []);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"sm"}
        open={ProductAddOpen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "20px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 20px 0px",
            }}
          >
            {" "}
            <p
              style={{
                margin: "0px 0px 0px 0px",
                fontSize: "16px",
                fontWeight: "500",
                color: "#0071BC",
              }}
            >
              Add Product *
            </p>
            <IconButton style={{ padding: "0px" }}>
              <CloseIcon onClick={() => handleClose()} />
            </IconButton>
          </Grid>

          <Autocomplete
            multiple
            disableCloseOnSelect
            size="small"
            id="auto-complete"
            options={ProductDrop}
            value={Product}
            onChange={(event, newValue) => {
              setProduct(newValue);
            }}
            getOptionLabel={(option) => option.ProductName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.ProductId}>
                {option.ProductName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="outlined" />
            )}
          />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0px 0px 0px ",
            }}
          >
            <Button
              style={{
                textTransform: "none",
                maxHeight: "22px",
                minHeight: "22px",
                background: "#0071BC",
                color: "#FFF",
              }}
              variant="contained"
              onClick={() => AddProduct()}
            >
              Submit
            </Button>
          </Grid>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
};

export default ProductAdd;
const stylebox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  outline: "transparent",
};
