import * as React from "react";
import Paper from "@mui/material/Paper";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { useDemoData } from "@mui/x-data-grid-generator";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

export default function Branchtables({ ContactOwner }) {
  //UseStates
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Navigate = useNavigate();
  const [BranchActive, setBranchActive] = useState([]);
  const [BranchSearch, setBranchSearch] = useState([]);
  const [open, setOpen] = useState(false);

  const [BranchData, setBranchData] = useState([]);

  //APIs

  const BranchList = async () => {
    if (ContactOwner !== null) {
      const response = await global.$axios
        .post(`/company/branches/activelist/${AppCode}`, {
          UserId: ContactOwner,
        })
        .then(async (response) => {
          setBranchActive(response.data.Message);
          setBranchSearch(response.data.Message);
        })
        .catch((e) => {
          console.error("Branch List Issue", e);
        });
    } else {
      setBranchActive([]);
    }
  };
  const BranchDelete = async () => {
    const response = await global.$axios
      .post("/company/branches/deletebranch", {
        AppCode: AppCode,
        AccountsBranchId: [BranchData?.AccountsBranchId],
      })
      .then(async (response) => {
        if (response.data.status == 200) {
          toast.success("Moved To InActive");
          BranchList();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error(" Branch Delete", e);
      });
  };
  useEffect(() => {
    BranchList();
  }, [ContactOwner]);

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = BranchActive.filter((item) => {
        const BranchId = String(item.AccountsBranchId);
        const CompanyName = String(item?.account?.CompanyName);
        const BranchName = String(item?.BranchName);
        const ContactNumber = String(item?.BranchContactNumber);
        const EmailId = String(item?.BranchEmail);
        const Area = String(item?.Area);
        const Address = String(item?.StreetAddress);

        const text_data = term.toLowerCase();
        return (
          BranchId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          BranchName.toLowerCase().indexOf(text_data) > -1 ||
          ContactNumber.toLowerCase().indexOf(text_data) > -1 ||
          EmailId.toLowerCase().indexOf(text_data) > -1 ||
          Area.toLowerCase().indexOf(text_data) > -1 ||
          Address.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setBranchSearch(ticketData);
    } else {
      setBranchSearch(BranchActive);
    }
  }

  //Mui Premium

  const [sortModel, setSortModel] = React.useState([
    {
      field: "AccountsBranchId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 10,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  const columns = [
    {
      field: "AccountsBranchId",
      headerName: "ID",
      width: 60,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "TicketView" || "",
      headerName: "Action",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditIcon
                style={{
                  width: "20px",
                  height: "20px",
                  padding: "2px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  Navigate("EditBranch", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Delete
                style={{
                  width: "20px",
                  height: "20px",
                  padding: "2px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // BranchDelete(cellValues.row.AccountsBranchId)
                  setBranchData(cellValues?.row);
                  setOpen(true);
                }}
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "TicketView" || "",
    //   headerName: "Actions",
    //   width: 100,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (cellValues) => {
    //     return (
    //       <IconButton
    //         aria-label="expand row"
    //         size="small"
    //         onClick={() => setOpen(!open)}
    //       >
    //         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    //       </IconButton>
    //     );
    //   },
    // },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 250,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.account?.CompanyName,
    },
    {
      field: "BranchName",
      headerName: "Branch Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.BranchName,
    },
    {
      field: "BranchContactNumber",
      headerName: "Contact No",
      width: 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "BranchEmail",
      headerName: "Email",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Area",
      headerName: "Area",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "StreetAddress",
      headerName: "Address",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
  ];
  return (
    <>
      <Grid
        item
        md={2.7}
        style={{
          marginBottom: "5px",
        }}
      >
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
              width: "100%",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            height: 450,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
            }}
            rowHeight={30}
            columnHeaderHeight={30}
            pagination={true}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={BranchSearch ? BranchSearch : []}
            getRowId={(row) => row.AccountsBranchId}
            loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Box>
      </Paper>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={open}
        onClose={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontWeight: "400",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              margin: "0px",
            }}
          >
            Do You Want To Delete
          </p>{" "}
          <p
            style={{
              fontSize: "px",
              margin: "0px",
              color: "#0071BC",
              fontWeight: "600",
            }}
          >
            {BranchData?.BranchName}
          </p>
        </DialogTitle>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            onClick={() => {
              BranchDelete();
              setOpen(false);
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            onClick={() => setOpen(false)}
          >
            No{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
