import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  Grid,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import CustomerCategoryEdit from "../PopupScreens/EditPopupscreeen/CustomerCategoryEdit";
import SearchIcon from "@mui/icons-material/Search";

const CustomerCategoryTable = ({ Refresh, setRefresh }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  //UseState
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  //Edit Popup
  const [EditOpen, setEditOpen] = useState(false);
  const [Edit, setEdit] = useState("");


  //

  //Dialog
  const [open, setOpen] = useState(false);
  const [DeleteData, setDeleteData] = useState("");

  //API
  const CActiveList = async () => {
    const response = await global.$axios.get(
      `/customercategory/activelist/${AppCode}`
    )
      .then(async (response) => {
        setCCategorySearch([...response.data.Message]);
        setCCategoryNameRow([...response.data.Message]);
      })
      .catch((e) => {
        console.error("Customercategory", e)
      })

  };

  const DeleteList = async (id) => {
    const response = await global.$axios.put(
      `customercategory/delete`, {
      id: id,
      AppCode: AppCode,
    }
    )
      .then(async (response) => {
        if (response.data.status === 200) {
          toast.success("Category Deleted");
          CActiveList();
        } else if (response.data.status === 201) {
          toast.error(`${response.data.Message}`);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("customercatagorydelet", e)
      })

  };

  //Search
  const [CCategoryNameRow, setCCategoryNameRow] = useState([]);
  const [CCategorySearch, setCCategorySearch] = useState([]);

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = CCategoryNameRow.filter((item) => {
        const CustomerCategoryId = String(item.CustomerCategoryId);
        const Category = String(item?.Category);
        const UpdatedBy = String(item?.updated?.UpdatedBy);
        const UpdatedAt = String(item?.UpdatedAt);
        const ETC = String(
          moment(item?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );

        const text_data = term.toLowerCase();
        return (
          CustomerCategoryId.indexOf(text_data) > -1 ||
          Category.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedAt.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1
        );
      });

      setCCategorySearch(ticketData);
    } else {
      setCCategorySearch(CCategoryNameRow);
    }
  }
  const [sortModel, setSortModel] = useState([
    {
      field: "CustomerCategoryId",
      sort: "desc",
    },
  ]);
  const columns = [
    {
      field: "CustomerCategoryId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 80,
    },
    {
      field: "Edit",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditIcon
                onClick={() => {
                  setEdit(cellValues.row);
                  setEditOpen(true);
                }}
                style={{
                  width: "23px",
                  height: "23px",
                  cursor: "pointer",
                  padding: "2px",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon
                onClick={() => {
                  setDeleteData(cellValues.row);
                  setOpen(true);
                }}
                style={{
                  width: "23px",
                  height: "23px",
                  cursor: "pointer",
                  padding: "2px",
                }}
              />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "Category",
      headerClassName: "super-app-theme--header",
      headerName: "Category",
      width: 300,
    },

    {
      field: "UpdatedBy",
      headerClassName: "super-app-theme--header",
      headerName: "Updated By",
      width: 200,
      valueGetter: (cellValues) => cellValues?.row?.updated?.UpdatedBy,
    },

    {
      field: "UpdatedAt",
      headerName: "Updated At",
      width: 290,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.UpdatedAt !== ""
          ? moment(fields?.row?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
          : "-";
      },
    },
  ];
  useEffect(() => {
    CActiveList();
  }, [Refresh]);
  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            margin: "10px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>

      <div style={{ height: 470, width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...CCategoryNameRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={CCategorySearch}
          columns={columns}
          rowHeight={33}
          pageSize={10}
          getRowId={(row) => row.CustomerCategoryId}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          components={{
            Footer: () => (
              <>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0px 5px 0px 10px"
                }}>
                  <Grid container item xs={12} style={{ alignItems: "center" }}>
                    {rowSelectionModel.length >= 1 ? <Grid item xs={2} >
                      <DeleteIcon onClick={() => DeleteList(rowSelectionModel)}
                        style={{ cursor: "pointer" }} />
                    </Grid> : <Grid item xs={2}>
                    </Grid>}
                    <Grid item xs={10}>
                      <GridPagination
                      />
                    </Grid>

                  </Grid>

                </div>

              </>
            ),
          }}
        />
      </div>
      <div>
        <Dialog
          fullWidth
          maxWidth={"xs"}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "400",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                margin: "0px",
              }}
            >
              Do You Want To Delete
            </p>{" "}
            <p
              style={{
                fontSize: "px",
                margin: "0px",
                color: "#0071BC",
                fontWeight: "600",
              }}
            >
              {DeleteData.Category}
            </p>
          </DialogTitle>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                DeleteList([DeleteData.CustomerCategoryId]);
                setOpen(false);
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => setOpen(false)}
            >
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
      {EditOpen && (
        <CustomerCategoryEdit
          id={Edit}
          EditOpen={EditOpen}
          setEditOpen={setEditOpen}
          Refresh={Refresh}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default CustomerCategoryTable;
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },

};
