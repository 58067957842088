import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

import { useNavigate } from "react-router-dom";
import DateRangeSharpIcon from "@mui/icons-material/DateRangeSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import PAllTable from "./ReportComponents/Product Reports/PAllTable";
import PNewTable from "./ReportComponents/Product Reports/PNewTable";
import PProgressTable from "./ReportComponents/Product Reports/PProgressTable";
import PHoldTable from "./ReportComponents/Product Reports/PHoldTable";
import PCompletedTable from "./ReportComponents/Product Reports/PCompletedTable";
import PSolvedTable from "./ReportComponents/Product Reports/PSolvedTable";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

function ProductReport() {
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  var PProduct = localStorage.getItem("Product Drop");
  const [ProductShow, setProductShow] = useState(null);
  const [ProducttDrop, setProducttDrop] = useState([]);
  const [TCount, setTCount] = useState("");
  const [SLACount, setSLACount] = useState("");
  var PDate = localStorage.getItem("ProductTime");
  var spli = PDate && PDate.split(",");
  //Date Button
  var today = dayjs();
  var prevWeek = today.subtract(7, "day");
  var prevMonth = today.subtract(1, "month");
  const [Date, setDate] = useState([dayjs(), dayjs()]);
  const [Date1, setDate1] = useState([
    spli !== null ? dayjs(spli[0]) : dayjs(),
    spli !== null ? dayjs(spli[1]) : dayjs(),
  ]);
  var DForm =
    Date[0] &&
    `${Date[0].$d.getFullYear()}-${Date[0].$d.getMonth() + 1 < 10
      ? "0" + (Date[0].$d.getMonth() + 1)
      : Date[0].$d.getMonth() + 1
    }-${Date[0].$d.getDate() < 10
      ? "0" + Date[0].$d.getDate()
      : Date[0].$d.getDate()
    }`;
  var DForm1 =
    Date[1] &&
    `${Date[1].$d.getFullYear()}-${Date[1].$d.getMonth() + 1 < 10
      ? "0" + (Date[1].$d.getMonth() + 1)
      : Date[1].$d.getMonth() + 1
    }-${Date[1].$d.getDate() < 10
      ? "0" + Date[1].$d.getDate()
      : Date[1].$d.getDate()
    }`;
  const [ProductTab, setProductTab] = useState("All");
  //APIs

  const ProductList = async () => {
    const response = await global.$axios
      .get(`/ticket/productwise/${AppCode}`)
      .then(async (response) => {
        setProducttDrop(response.data.Message);
        setProductShow(
          PProduct !== null ? JSON.parse(PProduct) : response.data.Message[0]
        );
        TotalCount(
          PProduct !== null
            ? JSON.parse(PProduct)?.ProductId
            : response.data.Message[0]?.ProductId,
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
        SlaCount(
          PProduct !== null
            ? JSON.parse(PProduct)?.ProductId
            : response.data.Message[0]?.ProductId,
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
      })
      .catch((e) => {
        console.error("Product Drop List", e);
      });
  };

  const TotalCount = async (ProductID, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/daycountproduct/${AppCode}`, {
        ProductId: [ProductID],
        fromday: FromD,
        currentday: ToD,
        frequency: 0,
      })
      .then(async (response) => {
        setTCount(response.data.Message[0]);
      })
      .catch((e) => {
        console.error("Product Total Count Issue", e);
      });
  };

  const SlaCount = async (ProductID, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/productdaySLA`, {
        ProductId: [ProductID],
        fromday: FromD,
        currentday: ToD,
        frequency: 0,
        AppCode: AppCode,
      })
      .then(async (response) => {
        setSLACount(response.data.Message[0]);
      })
      .catch((e) => {
        console.error("Product SLA Count Issue");
      });
  };

  useEffect(() => {
    // setDate();
    setProductTab(
      localStorage.getItem("Product Tab") === null
        ? "All"
        : localStorage.getItem("Product Tab")
    );
    ProductList();
  }, []);
  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("Product Tab", "All");
              setProductTab("All");
            }}
          >
            Analytics
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            Product Reports
          </span>
        </div>
      </Grid>

      <Grid
        container
        lg={8}
        md={10}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item md={5} xs={12} sx={{ marginBottom: "5px" }}>
          <Autocomplete
            fullWidth
            id="auto-complete"
            size="small"
            disableClearable
            options={ProducttDrop}
            value={ProductShow}
            isOptionEqualToValue={(option, value) =>
              option.ProductName == value.ProductName
            }
            onChange={(event, newValue) => {
              localStorage.setItem("Product Drop", JSON.stringify(newValue));
              setProductShow(newValue);
              TotalCount(
                newValue?.ProductId,
                spli != null ? spli[0] : DForm,
                spli != null ? spli[1] : DForm1
              );
              SlaCount(
                newValue?.ProductId,
                spli != null ? spli[0] : DForm,
                spli != null ? spli[1] : DForm1
              );
            }}
            getOptionLabel={(option) => option.ProductName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.ProductId}>
                {option.ProductName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>

        <Grid
          container
          item
          md={6.7}
          xs={12}
          sx={{
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item md={9} xs={12} sx={{}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={Date1}
                disableFuture
                onChange={(newValue) => {
                  setDate(newValue);
                  setDate1(newValue);
                  localStorage.setItem(
                    "ProductTime",
                    `${newValue[0].$d},${newValue[1] != null ? newValue[1].$d : newValue[0].$d
                    }`
                  );
                  TotalCount(
                    ProductShow?.ProductId,
                    newValue[0] && newValue[0].$d,
                    newValue[1] && newValue[1].$d
                  );
                  SlaCount(
                    ProductShow?.ProductId,
                    newValue[0] && newValue[0].$d,
                    newValue[1] && newValue[1].$d
                  );
                }}
                label="DD-MM-YYYY"
                format="DD-MM-YYYY"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            md={3}
            xs={12}
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ProductTime",
                  `${prevWeek.startOf("week").$d},${prevWeek.endOf("week") != null
                    ? prevWeek.endOf("week").$d
                    : prevWeek.startOf("week").$d
                  }`
                );

                TotalCount(
                  ProductShow?.ProductId,
                  prevWeek.startOf("week").$d,
                  prevWeek.endOf("week").$d
                );
                SlaCount(
                  ProductShow?.ProductId,
                  prevWeek.startOf("week").$d,
                  prevWeek.endOf("week").$d
                );
                setDate1([prevWeek.startOf("week"), prevWeek.endOf("week")]);
                setDate([prevWeek.startOf("week"), prevWeek.endOf("week")]);
              }}
            >
              <Tooltip title="Last Week">
                <DateRangeSharpIcon color="primary" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ProductTime",
                  `${prevMonth.startOf("month").$d},${prevMonth.endOf("month") != null
                    ? prevMonth.endOf("month").$d
                    : prevMonth.startOf("month").$d
                  }`
                );

                TotalCount(
                  ProductShow?.ProductId,
                  prevMonth.startOf("month").$d,
                  prevMonth.endOf("month").$d
                );
                SlaCount(
                  ProductShow?.ProductId,
                  prevMonth.startOf("month").$d,
                  prevMonth.endOf("month").$d
                );
                setDate1([
                  prevMonth.startOf("month"),
                  prevMonth.endOf("month"),
                ]);
                setDate([prevMonth.startOf("month"), prevMonth.endOf("month")]);
              }}
            >
              <Tooltip title="Last Month">
                <CalendarMonthSharpIcon color="success" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ProductTime",
                  `${today.subtract(7, "day").$d},${today != null ? today.$d : today.subtract(7, "day").$d
                  }`
                );

                TotalCount(
                  ProductShow?.ProductId,
                  today.subtract(7, "day").$d,
                  today.$d
                );
                SlaCount(
                  ProductShow?.ProductId,
                  today.subtract(7, "day").$d,
                  today.$d
                );
                setDate1([today.subtract(7, "day"), today]);
                setDate([today.subtract(7, "day"), today]);
              }}
            >
              <Tooltip title="Last 7 Days">
                <TodayTwoToneIcon sx={{ mt: "2px" }} color="secondary" />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "5px 0px 0px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Product Tab", "New");
            setProductTab("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              New
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.New}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.New}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Product Tab", "Progress");
            setProductTab("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Progress
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Progress}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Progress}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Product Tab", "Hold");
            setProductTab("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Hold
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Holding}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Holding}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Product Tab", "Completed");
            setProductTab("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Completed
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Completed}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Completed}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Product Tab", "Solved");
            setProductTab("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Solved
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Solved}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Solved}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid item md={3.9} sm={5.9} xs={12}></Grid>
      </Grid>
      {ProductTab == "All" && (
        <PAllTable
          ProductID={ProductShow?.ProductId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ProductTab == "New" && (
        <PNewTable
          ProductID={ProductShow?.ProductId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ProductTab == "Progress" && (
        <PProgressTable
          ProductID={ProductShow?.ProductId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ProductTab == "Hold" && (
        <PHoldTable
          ProductID={ProductShow?.ProductId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ProductTab == "Completed" && (
        <PCompletedTable
          ProductID={ProductShow?.ProductId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ProductTab == "Solved" && (
        <PSolvedTable
          ProductID={ProductShow?.ProductId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
    </>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 500,
    fontSize: "14px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};

export default ProductReport;
