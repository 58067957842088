import { Button, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import UserPopup from "./PopupScreens/Userpopup";
import UseractiveTable from "./ActiveTables/UseractiveTable";
import UserInactiveTable from "./InActiveTables/UserInactiveTable";
import UserResignedTable from "./UserResignedTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Users = ({ setTab }) => {
  const Navigate = useNavigate();
  const [sub, setSub] = useState("Active");
  const [tab, settab] = useState("Users");
  const [refresh, setRefresh] = useState(false);

  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => Navigate(-1)}
          >
            Masters /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Users
          </span>
        </div>
        <Grid item>
          <UserPopup refresh={refresh} setRefresh={setRefresh} />
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          md={9}
          sm={9}
          xs={12}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Active" ? "contained" : "text"}
            onClick={() => setSub("Active")}
          >
            Active
          </Button>
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Inactive" ? "contained" : "text"}
            onClick={() => setSub("Inactive")}
          >
            Inactive
          </Button>
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            variant={sub === "Resigned" ? "contained" : "text"}
            onClick={() => setSub("Resigned")}
          >
            Resigned
          </Button>
        </Grid>

        {(sub === "Active" && (
          <UseractiveTable refresh={refresh} setRefresh={setRefresh} />
        )) ||
          (sub === "Inactive" && <UserInactiveTable />) ||
          (sub === "Resigned" && <UserResignedTable />)}

        {/* */}

        {/**/}
      </Grid>
    </div>
  );
};

export default Users;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};
