import { Button, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import PredefinedTable from "./ActiveTables/PredefinedTable";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PredefinedInactiveTable from "./InActiveTables/PredefinedInactiveTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Predefined = ({ setTab }) => {
  const [sub, setSub] = useState("Active");
  const [Refresh, setRefresh] = useState(false);
  const Navigate = useNavigate();
  return (
    <div>
      {" "}
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{ color: "#0071BC", cursor: "pointer" }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{ width: "20px", height: "15px", marginBottom: "3px" }}
              />
            </Tooltip>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => Navigate(-1)}
          >
            Masters /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Predefined Replies(Templates)
          </span>
        </div>
        <Button
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            padding: "12px",
          }}
          size="small"
          variant="contained"
          onClick={() => Navigate("AddPredefinedReply")}
        >
          + Add New Replies
        </Button>
      </Grid>
      <Grid
        xs={12}
        container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "5px",
        }}
      >
        {/* Tab*/}
        <Grid
          item
          md={9.3}
          style={{ margin: "0px 0px 5px 0px", display: "flex", gap: "10px" }}
        >
          <Button
            variant={sub === "Active" ? "contained" : "text"}
            sx={{
              maxHeight: "22px",
              minHeight: "22px",
              padding: "12px",
              textTransform: "capitalize",
            }}
            onClick={() => setSub("Active")}
          >
            Active
          </Button>
          <Button
            variant={sub === "InActive" ? "contained" : "text"}
            sx={{
              maxHeight: "22px",
              minHeight: "22px",
              padding: "12px",
              textTransform: "capitalize",
            }}
            onClick={() => setSub("InActive")}
          >
            Inactive
          </Button>
        </Grid>
        {(sub === "Active" && (
          <PredefinedTable Refresh={Refresh} setRefresh={setRefresh} />
        )) ||
          (sub === "InActive" && <PredefinedInactiveTable />)}
      </Grid>
      <ToastContainer
        position="topright"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default Predefined;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};
