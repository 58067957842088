import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const CustomerDesignationPopup = ({
  CustomerDesignationOpen,
  setCustomerDesignationOpen,
  Refresh,
  setRefresh,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  //USESTATE
  const handleClose = () => setCustomerDesignationOpen(false);
  const [NewDesignation, setNewDesignation] = useState("");
  const [Err, setErr] = useState(0);
  //API
  const AddDesignation = async () => {
    const response = await global.$axios.post(`/customerdesignation/add`, {
      AppCode: AppCode,
      CDesignationName: NewDesignation,
      UserId: userId,
    })
      .then(async (response) => {
        if (response.data.status === 200) {
          toast.success("Department Added");
          handleClose();
          setRefresh(!Refresh);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("customerdesignation", e)
      })

  };

  const Validate = async (val) => {
    const response = await global.$axios.get(
      `customerdesignation/validatedesignation/${AppCode}/${val}`
    ).then(async (response) => {
      setErr(response.data.Message);
    })
      .catch((e) => {
        console.error("customerdesignation", e)

      })
  };
  return (
    <div>
      <Modal
        open={CustomerDesignationOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                color: "#0071BC",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {" "}
              Add Customer's Designation
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            onChange={(e) => setNewDesignation(e.target.value)}
            onKeyUp={(e) => {
              e.target.value !== "" && Validate(e.target.value);
            }}
            error={Err === 1 ? true : false}
            helperText={
              Err === 1 ? (
                <span style={{ color: "red" }}>
                  Already Exist
                </span>) : (
                <span style={{ color: "transparent" }}>Already Exist</span>
              )
            }
            fullWidth
            placeholder="Add Designation Here"
            id="outlined-size-small"
            size="small"
            required
          />

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={Err === 1 ? true : false}
              onClick={() => {
                AddDesignation();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default CustomerDesignationPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
