import { Button, Grid } from "@mui/material";
import React from "react";

import { Buttonticket } from "../Assets/Icon";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const Navigate = useNavigate();

  var DH = new Date().getHours();
  var TT = DH >= 0 && DH < 12 ? 1 : DH >= 12 && DH <= 15 ? 2 : 3;
  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item style={{ margin: "5px 0px 0px 0px" }}>
          <span style={{ fontSize: "16px", fontWeight: 500 }}>Welcome</span>
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {global.$userData.logindata.UserName}
          </span>
        </Grid>

        <Button
          variant="contained"
          onClick={() => Navigate("/app/AddTicket")}
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            padding: "12px",
            margin: "5px 0px 0px 0px",
          }}
        >
          {" "}
          + New Ticket{" "}
          <span style={{ paddingLeft: "5px" }}>
            <Buttonticket />
          </span>
        </Button>
      </Grid>
      <Grid container>
        <Grid
          item
          style={{
            margin: "20px 0px 0px 0px",
            border: "3px solid rgb(0,0,0,0.04)",
            borderRadius: "15px",
            padding: "10px",
          }}
        >
          {TT === 1 ? (
            <span style={{ textTransform: "capitalize" }}>
              May Your Cup filled up with blessings today.
              <span style={{ fontWeight: 500 }}> Good Morning!</span>
            </span>
          ) : TT === 2 ? (
            <span style={{ textTransform: "capitalize" }}>
              LIFE Is 10% What Happens To YOU and 90% how you react to IT.{" "}
              <span style={{ fontWeight: 500 }}> Good Afternoon!</span>
            </span>
          ) : (
            <span style={{ textTransform: "capitalize" }}>
              Every sunset brings the promise of a new dawn.
              <span style={{ fontWeight: 500 }}> Good Evening!</span>
            </span>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ display: { lg: "block", md: "block", sm: "none", xs: "none" } }}
      >
        <Grid
          item
          style={{
            fontSize: "150px",
            fontWeight: 1000,
            letterSpacing: "35px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 0px",
            margin: "60px 0px 0px 0px",
            color: "rgba(0,0,0,0.04)",
          }}
        >
          Treeone
        </Grid>
      </Grid>
    </div>
  );
}
