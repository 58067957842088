const user = {
  userid: "",
  password: "",
  logged_in: false,
};

const userReducer = (state = user, action) => {
  if (action.type === "SET_USER_IN") {
    return { ...action, logged_in: true, ...action };
  } else if (action.type === "SET_USER_OUT") {
    return { logged_in: false };
  }
  return state;
};
export default userReducer;
