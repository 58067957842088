
import { Button, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { AddIcon, DeleteIcon } from "../../Assets/Icon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";


const AddEditStaticIp = ({ setTab }) => {
  const [add, setAdd] = useState("");
  const [formValues, setFormValues] = useState([{ name: "" }]);
  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { name: "" }]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };

  const Navigate = useNavigate();
  return (
    <div>
      <div>
        <span
          onClick={() => Navigate(-1)}
          style={{
            color: "#0071BC",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Back">
            <ArrowBackIosNewIcon
              sx={{
                width: "20px",
                height: "15px",
                marginBottom: "3px",
              }}
            />
          </Tooltip>
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: 600,
            margin: "0px",
            color: "#0071BC",
            cursor: "pointer",
          }}
          onClick={() => Navigate(-1)}
        >
          Masters /
        </span>{" "}
        <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
          {" "}
          Add/Edit Static IP
        </span>
      </div>
      <Grid
        container
        style={{
          background: "#FFFFFF",
          margin: "10px 0px 5px 0px",
          boxShadow: "5px 3px 5px #c2c2c2",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <p
            style={{
              margin: "0px",
              fontSize: "16px",
              textAlign: "center",
              letterSpacing: "2px",
            }}
          >
            Enter Static IP
          </p>
          <Tooltip title="Add New">
            <IconButton
              style={{ padding: "0px 0px 0px 10px" }}
              onClick={() => setAdd("New")}
            >
              <AddIcon
                style={{ width: "50px", height: "22px" }}
                onClick={() => addFormFields()}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          conatiner
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            borderBottom: "1px solid #c2c2c2",
            margin: "0px 0px 10px 0px",
          }}
        >
          <Grid
            item
            md={8}
            xs={12}
            style={{
              border: "1px solid #c2c2c2",
              margin: "0px 0px 20px 0px",
              padding: "10px",
            }}
          >
            <Grid style={{ display: "flex" }}>
              <Grid item xs={2}>
                SNo
              </Grid>
              <Grid item xs={10}>
                IP
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                margin: "5px 0px",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <p style={{ margin: "0px", fontSize: "14px" }}>1 .</p>
              </Grid>
              <Grid
                item
                xs={10}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  sx={{
                    minWidth: 330,

                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "7px 0px ",
                    },
                  }}
                  label="Static IP Here"
                  id="outlined-size-small"
                  size="small"
                />
                <Tooltip title="Delete">
                  <IconButton
                    style={{ padding: "0px", margin: "0px 0px 0px 10px" }}
                  >
                    <DeleteIcon style={{ width: "50px", height: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container style={{ margin: "5px 0px" }}>
              <Grid
                item
                xs={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <p style={{ margin: "0px", fontSize: "14px" }}>2 .</p>
              </Grid>
              <Grid
                item
                xs={10}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  sx={{
                    minWidth: 330,
                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "7px 0px ",
                    },
                  }}
                  label="Static IP Here"
                  id="outlined-size-small"
                  size="small"
                />
                <Tooltip title="Delete">
                  <IconButton
                    style={{ padding: "0px", margin: "0px 0px 0px 10px" }}
                  >
                    <DeleteIcon style={{ width: "50px", height: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container style={{ margin: "5px 0px" }}>
              <Grid
                item
                xs={2}
                style={{ display: "flex", alignItems: "center" }}
              >
                <p style={{ margin: "0px", fontSize: "14px" }}>3 .</p>
              </Grid>
              <Grid
                item
                xs={10}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  sx={{
                    minWidth: 330,
                    ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "7px 0px ",
                    },
                  }}
                  label="Static IP Here"
                  id="outlined-size-small"
                  size="small"
                />
                <Tooltip title="Delete">
                  <IconButton
                    style={{ padding: "0px", margin: "0px 0px 0px 10px" }}
                  >
                    <DeleteIcon style={{ width: "50px", height: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid
              onSubmit={handleSubmit}
              container
              style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "row",
                margin: "3px 0px",
              }}
            >
              {formValues.map((element, index) => (
                <>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <p style={{ margin: "0px", fontSize: "14px" }}>
                      {index + 4} .
                    </p>
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "3px 0px",
                    }}
                    key={index}
                  >
                    <TextField
                      sx={{
                        minWidth: 330,
                        ".css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "7px 0px ",
                        },
                      }}
                      label="Static IP Here"
                      id="outlined-size-small"
                      size="small"
                      value={element.name || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                    <IconButton
                      style={{
                        padding: "0px",
                        margin: "0px 0px 0px 10px",
                      }}
                      onClick={() => setAdd("New")}
                    >
                      <DeleteIcon
                        style={{ width: "50px", height: "22px" }}
                        onClick={() => removeFormFields(index)}
                      />
                    </IconButton>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          <Button size="small" variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditStaticIp;
