import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import { toast, ToastContainer } from "react-toastify";

const TicketSourcePopup = ({
  Refresh,
  setRefresh,
  TicketSourceOpen,
  setTicketSourceOpen,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  // console.log(AppCode, userId);

  const handleClose = () => setTicketSourceOpen(false);
  const [age, setAge] = React.useState("");
  const [sourceName, setSourceName] = useState("");
  // console.log(sourceName, "sourceName");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const ticketSource = async () => {
    if (sourceName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        TicketSourceName: sourceName,
        UserId: userId,
      };
      const response = await global.$axios.post("source/add", data)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Ticket Source Added");
            setSourceName("");
            handleClose();
            setRefresh(!Refresh);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("ticketsourcepopadd", e)
        })

    }
  };
  const [err, seterr] = useState(0);

  const validation = async (val) => {
    // source/validatesource/:AppCode/:TicketSourceName
    const response = await global.$axios.get(
      `/source/validatesource/${AppCode}/${val}`
    )
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("ticketsourcevalidation", e)
      })

  };
  return (
    <div>
      <Modal
        open={TicketSourceOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 20px 0px",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              {" "}
              Add Ticket Source
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            required
            value={sourceName}
            onChange={(e) => setSourceName(e.target.value)}
            sx={{ minWidth: 330 }}
            onKeyUp={(e) => {
              e.target.value !== "" && validation(e.target.value);
            }}
            error={err === 1 ? true : false}
            helperText={
              err === 1 ? (
                <span style={{ color: "red" }}>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>iuftghj</span>
              )
            }
            placeholder="Add Source Here"
            id="outlined-size-small"
            size="small"
          />

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => ticketSource()}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="topright"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default TicketSourcePopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
