import * as React from "react";
import Paper from "@mui/material/Paper";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { useState, useEffect } from "react";
import moment from "moment";
import { ViewIcon } from "../../Assets/Icon";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { Box, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
export default function CCompletedTable() {
  const userId = global.$userData.logindata.UserId;
  const AppCode = global.$userData.logindata.AppCode;
  const Navigate = useNavigate();
  const [Completed, SetCompleted] = useState([]);
  const [CompletedSearch, setCompletedSearch] = useState([]);

  const CompletedList = async () => {
    const response = await global.$axios
      .post(`ticket/cClosedTickets/${AppCode}/${userId}`)
      .then(async (response) => {
        SetCompleted(response.data.Message);
        setCompletedSearch(response.data.Message);
      })
      .catch((e) => {
        console.error("Created Completed Issue", e);
      });
  };

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = Completed.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        // const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        const Description = String(item?.TicketDescription);
        const AssignedTo = String(item?.assigned?.UserFirstName);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          AssignedTo.toLowerCase().indexOf(text_data) > -1 ||
          Description.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setCompletedSearch(ticketData);
    } else {
      setCompletedSearch(Completed);
    }
  }

  //Mui Premium

  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });
  function AssignedFullName(fields) {
    return `${fields?.row?.assigned?.UserFirstName || ""} ${
      fields?.row?.assigned?.UserLastName || ""
    }`;
  }

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            {/* {cellValues.row.AttachmentFile === "" ? (
              ""
            ) : (
              <Tooltip
                style={{ paddingRight: "5px" }}
                title={
                  <div style={{ height: "102px", width: "180px" }}>
                    <img
                      src={cellValues.row.AttachmentFile}
                      style={{
                        width: "180px",
                        position: "absolute",
                        rigth: "80%",
                      }}
                    />
                  </div>
                }
              >
                <Link to={cellValues.row.AttachmentFile} target="blank">
                  <ViewIcon style={{ width: "20px", height: "20px" }} />
                </Link>
              </Tooltip>
            )} */}
            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("/app/CreatedView", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },
    {
      field: "ECAt",
      headerName: " Time To Complete",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YYYY hh:mm A"
        ) || "",
    },
    {
      field: "TicketClosedTime" || "",
      headerName: "Closed Time",
      width: 180,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.TicketClosedTime !== ""
          ? moment(fields?.row?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Assigned To",
      headerName: "Assigned To ",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: AssignedFullName,
    },
  ];

  useEffect(() => {
    (async () => {
      CompletedList();
    })();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          Completed
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
            }}
            rowHeight={34}
            pagination={true}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={CompletedSearch ? CompletedSearch : []}
            getRowId={(row) => row.TicketId}
            loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Box>
      </Paper>
    </>
  );
}
