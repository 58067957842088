import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../screens/login/Login";
import ForgotPassword from "../screens/login/ForgotPassword";
import LoginLayout from "../layout/LoginLayout";
export default function AuthRoutes() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpass" element={<ForgotPassword />} />
      </Route>
    </Routes>
  );
}
