import { Grid } from "@mui/material";

import React, { useState } from "react";

import { Mainimg, Loginimg } from "../Assets/Icon";
import Login from "../screens/login/Login";
import ForgotPassword from "../screens/login/ForgotPassword";

export default function LoginLayout() {
  const [forget, setforget] = useState("login");
  return (
    <>
      <Grid container style={{}}>
        <Grid
          item
          lg={6}
          md={6}
          sm={0}
          xs={0}
          sx={{ display: { lg: "block", md: "block", sm: "none", xs: "none" } }}
        >
          <Mainimg
            style={{
              position: "relative",
              width: "50vw",
              height: "100vh",
            }}
          />
        </Grid>
        <Grid
          container
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: { lg: "block", md: "block", sm: "none", xs: "none" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            background: "rgb(45,113,188)",
            borderTopLeftRadius: "180px",
            borderBottomLeftRadius: "180px",
          }}
        >
          <Grid
            container
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: { lg: "none", md: "none", sm: "block", xs: "block" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              background: "rgb(45,113,188)",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              xs={10}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loginimg style={{ width: "140px", height: "140px" }} />
              {forget === "login" && (
                <>
                  <Login setforget={setforget} />
                </>
              )}
              {forget === "forget" && (
                <div>
                  <ForgotPassword />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
const style = {
  text: {
    margin: "0px 0px 5px 0px",
    fontSize: "14px",
  },
};
