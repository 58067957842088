import { Button, Grid, IconButton } from "@mui/material";
import React from "react";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import { DPIcon } from "../Assets/Icon";

const Profile = () => {
  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          item
          md={4}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "3px solid rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "10px",
            lineHeight: "30px",
            background: "#FFF",
            boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
          }}
        >
          <DPIcon />
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              gap: "10px",
            }}
          >
            <span style={{ margin: "0px" }}>
              {global.$userData.logindata.UserName}
            </span>
            <IconButton style={{ padding: " 0px" }}>
              <EditSharpIcon fontSize="small" />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "80%",
            }}
          >
            <Button
              size="small"
              sx={{
                margin: " 0px",
                textTransform: "capitalize",
                fontSize: "12px",
              }}
            >
              Edit Profile
            </Button>

            <div style={{ display: "none" }}>
              <input type="file" id="actual-btn" />
            </div>
            <label
              for="actual-btn"
              style={{
                color: "#0071BC",
                fontSize: "12px",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Choose Profile Picture
            </label>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;
