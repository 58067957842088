import Modal from "@mui/material/Modal";
import React from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";

const CustomerDesignationEdit = ({
  EditOpen,
  setEditOpen,
  Refresh,
  setRefresh,
  id,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //Usestate
  const [CDesignation, setCDesignation] = useState("");
  const [DesignationName, setDesignationName] = useState("");
  const handleClose = () => setEditOpen(false);
  const [Err, setErr] = useState(0);
  // console.log(Err, "eror");

  //API
  const EditView = async () => {
    const response = await global.$axios
      .get(`/customerdesignation/view/${AppCode}/${id.CDesignationId}`)
      .then(async (response) => {
        setCDesignation(response?.data?.Message?.CDesignationName);
        setDesignationName(response?.data?.Message?.CDesignationName);
      })
      .catch((e) => {
        console.error("customerdesigination editview", e);
      });
  };

  const EditDesignation = async () => {
    if (CDesignation === "") {
      toast.error("Please provide Designation Name");
      return;
    } else {
      const response = await global.$axios
        .post(`/customerdesignation/edit/${id.CDesignationId}`, {
          AppCode: AppCode,
          CDesignationName: CDesignation,
          UserId: userId,
        })
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Designation Edited");
            handleClose();
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("customerdesignation", e);
        });
    }
  };

  const Validate = async (val) => {
    const response = await global.$axios
      .get(`customerdesignation/validatedesignation/${AppCode}/${val}`)
      .then(async (response) => {
        setErr(response.data.Message);
      })
      .catch((e) => {
        console.error("customerdesignation", e);
      });
  };

  useEffect(() => {
    EditView();
  }, []);
  return (
    <div>
      <Modal
        open={EditOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              Edit Customer's Designation{" "}
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12}>
              <TextField
                value={CDesignation}
                onChange={(e) => setCDesignation(e.target.value)}
                onKeyUp={(e) => {
                  e.target.value !== "" && Validate(e.target.value);
                }}
                error={
                  Err === 1 && CDesignation != DesignationName ? true : false
                }
                fullWidth
                placeholder="Designation Here"
                id="outlined-size-small"
                size="small"
                helperText={
                  Err === 1 && CDesignation != DesignationName ? (
                    <span style={{ color: "red" }}>Already Exist</span>
                  ) : (
                    <span style={{ color: "transparent" }}>sdfgh</span>
                  )
                }
              />
            </Grid>
          </Grid>

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={Err === 1 ? true : false}
              onClick={() => {
                setRefresh(!Refresh);
                EditDesignation();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default CustomerDesignationEdit;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  },
};
