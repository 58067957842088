import { Box, Paper, Tooltip } from "@material-ui/core";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ViewIcon } from "../../Assets/Icon";
import { useState } from "react";
import moment from "moment";
import { useDemoData } from "@mui/x-data-grid-generator";
import { Grid } from "@mui/material";

function CaseHistory() {
  var company = localStorage.getItem("pending");
  var companytable = JSON.parse(company);
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [workspaceRow, setWorkspaceRow] = useState([]);
  const [AllSearch, setAllSearch] = useState([]);
  const location = useLocation();

  //api

  const tableapi = async () => {
    const response = await global.$axios.get(
      `ticket/issuehistory/${AppCode}/${companytable?.issue?.IssueId}`
    );
    setWorkspaceRow(response.data.Message);
    setAllSearch(response.data.Message);
  };

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = workspaceRow.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        // const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setAllSearch(ticketData);
    } else {
      setAllSearch(workspaceRow);
    }
  }
  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
    },

    {
      field: "ProductName",
      headerName: "Product Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },

    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue.IssueName || "",
    },
    {
      field: "UserFirstName",
      headerName: "Solved by",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.assigned.UserFirstName || "",
    },

    {
      field: "PriorityLevel",
      headerName: "PriorityLevel",
      width: 200,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "ECAt",
      headerName: "Time To Complete",
      width: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.ECAt
          ? moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YY hh.mm A"
          )
          : "-",
    },

    {
      field: "TicketClosedTime" || "",
      headerName: "Closed Time",
      width: 190,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.TicketClosedTime !== ""
          ? moment(fields?.row?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
          : "-";
      },
      align: "center",
      headerAlign: "center",
    },
    // {
    //     field: "created" || "",
    //     headerName: "Assigned By",
    //     width: 200,
    //     headerClassName: "super-app-theme--header",
    //     valueGetter: CreatedFullName,
    // },
  ];
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    maxColumns: 6,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "desc" }],
      },
    },
  });
  //Mui Premium

  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  //Concat
  function CreatedFullName(fields) {
    return `${fields.row.created.UserFirstName || ""} ${fields.row.created.UserLastName || ""
      }`;
  }

  useEffect(() => {
    tableapi();
  }, []);
  return (
    <>
      <Grid
        container
        xs={12}
        style={{ margin: "0px 0px", display: "flex", alignItems: "center" }}
      >
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Case History /{" "}
          </span>
          <span style={styles.activehdr}>{companytable.issue.IssueName}</span>
        </div>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          All
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
            }}
            rowHeight={34}
            pagination={true}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={AllSearch ? AllSearch : []}
            getRowId={(row) => row.TicketId}
            loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Box>
      </Paper>
    </>
  );
}

export default CaseHistory;

const styles = {
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
  },
};
