import {
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function AddNotes() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const navigate = useNavigate();
  //usestate
  const [descripition, Setdescripition] = useState("");
  const [Title, Settitle] = useState("");

  //api

  const Notespost = async () => {
    const data = {
      AppCode: AppCode,
      For: 5,
      UserId: userId,
      Description: descripition,
      Title: Title,
    };
    const response = await global.$axios
      .post(`/ticket/createAppNotes`, data)
      .then((response) => {
        toast.success("Notes Added Successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1500);
      })
      .catch((e) => {
        console.error("notespost data", e);
      });
  };

  return (
    <>
      <Grid container xs={12} style={{ display: "flex", alignItems: "center" }}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Notes /
          </span>

          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px 0px 0px 5px",
            }}
          >
            Add Notes
          </span>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        lg={12}
        style={{
          display: "flex",
          margin: "5px 10px 0px 0px",
        }}
      >
        <Card
          style={{
            marginTop: "20px",
            height: "60vh",
            width: "100vw",
            overflowY: "scroll",
            padding: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px ",
            }}
          >
            {/* <PushPinOutlinedIcon sx={{ mr: 1, my: 0.5 }} /> */}
            <TextField
              id="standard-multiline-flexible"
              label="Title"
              onChange={(e) => Settitle(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PushPinOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ display: "flex", marginTop: "10px" }}>
            <textarea
              placeholder="Start Typing"
              onChange={(e) => Setdescripition(e.target.value)}
              style={{
                width: "100%",
                // border: "1px solid gray",
                // background: "#f5f5f5",
                // borderRadius: "5px",
                padding: "10px",
                fontWeight: "200",
                height: "100vh",
                outline: "none",
                border: "hidden",
              }}
            ></textarea>
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        xs={12}
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Button
          variant="contained"
          // color='primary'
          size="small"
          onClick={() => Notespost()}
          style={{
            color: "white",
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            margin: "5px 0px 0px 0px",
            background: "#0071BC",
          }}
        >
          Save
        </Button>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}

export default AddNotes;
