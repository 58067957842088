import axios from "axios";

// axios start
// axios.defaults.baseURL = "http://172.16.5.67:4000";
// axios.defaults.baseURL = "http://172.16.5.67:4002";
// axios.defaults.baseURL = "http://172.16.5.27:4003";
// axios.defaults.baseURL = "http://172.16.5.67:4020";
//
//staging
//axios.defaults.baseURL = "https://bkapistaging.treeone.one/";
//live
axios.defaults.baseURL = "https://bkticketapi.treeone.one/";
//
global.$axios = axios;
global.$test = "axios";
// axios end
