import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect, useState } from "react";
import Save from "../../../assets/icon/Sucess.gif";

export default function ForgotPassword() {
  const [otp, setotp] = useState("Emailsend");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const refresh = () => window.location.reload(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submit = () => {
    setOpen(false);
    handleOpen();
    setTimeout(() => {
      refresh();
    }, 3000);
  };

  return (
    <>
      <Grid
        container
        md={12}
        xs={12}
        style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
      >
        <span style={{ fontSize: "18px", fontWeight: "400", color: "white" }}>
          {" "}
          OTP Verification
        </span>
      </Grid>
      {otp === "Emailsend" && (
        <>
          <Grid
            container
            md={12}
            xs={12}
            style={{ fontSize: "14px", color: "white", marginTop: "10px" }}
          >
            We will send you an One Time Password on this verified Mail Id
          </Grid>

          <Grid container md={12} xs={12}>
            <TextField
              fullWidth
              id="email"
              variant="outlined"
              type="email"
              size="small"
              style={{
                background: "rgba(255, 255, 255, 1)",
                marginTop: "10px",
                borderRadius: "10px",
              }}
            />
          </Grid>

          <Grid
            container
            md={12}
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              style={{
                minHeight: "35px",
                maxHeight: "35px",
                color: "rgb(45,113,188)",
                backgroundColor: "white",
                borderRadius: "10px",
                fontWeight: "700",
                marginTop: "10px",
                textTransform: "none",
              }}
              onClick={() => setotp("verify")}
            >
              Get OTP
            </Button>
          </Grid>
        </>
      )}
      {otp === "verify" && (
        <>
          <Grid
            container
            md={12}
            xs={12}
            style={{
              fontSize: "14px",
              color: "white",
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Enter the OTP sent to santhosh.m@tabtree.in
          </Grid>
          <Grid
            container
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MuiOtpInput
              length={4}
              value=""
              TextFieldsProps={{
                sx: {
                  color: "black",
                  height: "40px",
                  width: "55px",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  borderColor: "@232323",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              }}
            />
          </Grid>
          <Grid
            container
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              style={{
                minHeight: "35px",
                maxHeight: "35px",
                color: "rgb(45,113,188)",
                backgroundColor: "white",
                borderRadius: "10px",
                fontWeight: "700",
                textTransform: "none",
              }}
              onClick={() => setotp("confirm")}
            >
              Verify
            </Button>
          </Grid>
        </>
      )}
      {otp === "confirm" && (
        <>
          <Grid container md={12} xs={12} style={{ marginTop: "10px" }}>
            <span
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: "600",
                margin: "2px",
                textTransform: "none",
              }}
            >
              New Password
            </span>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                background: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
            >
              <OutlinedInput
                fullWidth
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <span
              style={{
                fontSize: "14px",
                color: "white",
                fontWeight: "600",
                margin: "2px",
                textTransform: "none",
              }}
            >
              Confirm Password
            </span>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                background: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
              }}
            >
              <OutlinedInput
                fullWidth
                // placeholder='confirm password'
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          <Grid
            container
            md={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
            }}
          >
            <Button
              variant="contained"
              style={{
                minHeight: "35px",
                maxHeight: "35px",
                color: "rgb(45,113,188)",
                backgroundColor: "white",
                borderRadius: "10px",
                fontWeight: "700",
                textTransform: "none",
              }}
              onClick={submit}
            >
              Confirm{" "}
            </Button>
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img style={{ width: "200px", height: "180px" }} src={Save} />
                <div>Your Password has been Changed Sucessfully.....!</div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};
