import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const BrandPopup = ({ refresh, setrefresh, Branopen, SetBrandOpen }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const handleClose = () => SetBrandOpen(false);
  const [age, setAge] = React.useState("");
  const [brandName, setBrandName] = useState("");

  const brand = async () => {
    if (brandName == "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let field = {
        AppCode: AppCode,
        BrandName: brandName,
        UserId: userId,
      };
      const response = await global.$axios.post("product/brand/add", field)
        .then(async (response) => {
          if (response.data.status === 200) {
            handleClose();
            setrefresh(!refresh);
            toast.success("Brand Added");
          }
        })
        .catch((e) => {
          console.error("Branadd", e)
        })

    }
  };
  const [err, seterr] = useState(0);
  const validation = async (val) => {
    // /product/validatebrandname/:AppCode/:BrandName
    const response = await global.$axios.get(
      `/product/validatebrandname/${AppCode}/${val}`
    )
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("branvalidation", e)
      })

  };
  return (
    <div>
      <Modal
        open={Branopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 20px 0px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Add Brand Name
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            sx={{ minWidth: 300 }}
            onKeyUp={(e) => {
              e.target.value !== "" && validation(e.target.value);
            }}
            error={err === 1 ? true : false}
            helperText={
              err === 1 ? (
                <span style={{ color: "red" }}>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>KJHGF</span>
              )
            }
            placeholder="New Brand Name Here*"
            size="small"
            required
          />

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={err === 1 ? true : false}
              onClick={() => {
                brand();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default BrandPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
