/* eslint-disable no-unused-vars */
import * as React from "react";
import Paper from "@mui/material/Paper";
import { useDemoData } from "@mui/x-data-grid-generator";
import EditIcon from "@mui/icons-material/Edit";
import {
    DataGridPremium,
    GridSearchIcon,
    GridToolbar,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import { useEffect } from "react";
import { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, Tooltip } from "@mui/material";
import EditContactPopup from "./EditContactPopup";
import RestoreIcon from "@mui/icons-material/Restore";
import { ToastContainer, toast } from "react-toastify";


function ContactInactive({ ContactOwner, Refresh, setRefresh }) {

    const AppCode = global.$userData.logindata.AppCode;
    const userId = global.$userData.logindata.UserId;


    //UseStates
    const [ContactActive, setContactActive] = useState([]);
    const [ContactSearch, setContactSearch] = useState([]);
    const [ContactId, setContactId] = useState("");
    const [open, setOpen] = useState(false)
    //Popup
    const [ContactEditOpen, setContactEditOpen] = useState(false);
    //API
    const ContactInactiveList = async () => {
        if (ContactOwner !== null) {
            const response = await global.$axios
                .get(`/company/contact/inactivelist/${AppCode}`)
                .then(async (response) => {
                    setContactActive([...response.data.Message]);
                    setContactSearch([...response.data.Message]);
                })
                .catch((e) => {
                    console.error("Contact List Issue", e);
                });
        } else {
            setContactActive([]);
        }
    };

    const ContactRestore = async (ContId) => {
        const Response = await global.$axios.post("/company/contact/restorecontact", {
            ContactId: [ContactId],
            AppCode: AppCode
        })
            .then(async (response) => {
                if (response.data.status == 200) {
                    toast.success("Moved to Active")
                    ContactInactiveList()
                } else {
                    toast.error("Something Went Wrong")
                }
            })
            .catch((e) => {
                console.error("Contact Restore", e)
            })
    }

    //Search
    async function filterList(term) {
        let ticketData = [];
        if (term !== "") {
            ticketData = ContactActive.filter((item) => {
                const ContactId = String(item.ContactId);
                const CompanyName = String(item?.account?.CompanyName);
                const BranchName = String(item?.accounts_branch);
                const ContactNumber = String(item?.MobileNumber);
                const EmailId = String(item?.EmailId);
                const Designation = String(item?.Designation);
                const Department = String(item?.Department);

                const text_data = term.toLowerCase();
                return (
                    ContactId.indexOf(text_data) > -1 ||
                    CompanyName.toLowerCase().indexOf(text_data) > -1 ||
                    BranchName.toLowerCase().indexOf(text_data) > -1 ||
                    ContactNumber.toLowerCase().indexOf(text_data) > -1 ||
                    EmailId.toLowerCase().indexOf(text_data) > -1 ||
                    Designation.toLowerCase().indexOf(text_data) > -1 ||
                    Department.toLowerCase().indexOf(text_data) > -1
                );
            });

            setContactSearch(ticketData);
        } else {
            setContactSearch(ContactActive);
        }
    }

    //Mui Premium
    const [sortModel, setSortModel] = React.useState([
        {
            field: "ContactId",
            sort: "desc",
        },
    ]);

    const { data, loading } = useDemoData({
        dataSet: "Status",
        rowLength: 10,
        editable: true,
        visibleFields: ["Status"],
    });
    const apiRef = useGridApiRef();

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
            rowGrouping: {
                ...data.initialState?.rowGrouping,
                model: [""],
            },
            sorting: {
                sortModel: [{ field: "__row_group_by_columns_group__", sort: "desc" }],
            },
        },
    });

    const columns = [
        {
            field: "ContactId",
            headerName: "ID",
            width: 80,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "TicketView" || "",
            headerName: "Actions",
            width: 100,
            headerClassName: "super-app-theme--header",
            renderCell: (cellValues) => {
                return (
                    <Tooltip title="Restore">
                        <RestoreIcon
                            style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                setOpen(true)
                                setContactId(cellValues.row.ContactId)
                            }}
                        />
                    </Tooltip>
                );
            },
            align: "center",
            headerAlign: "center",
        },
        {
            field: "CompanyName",
            headerName: "Company Name",
            width: 200,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) => fields?.row?.account?.CompanyName,
        },
        {
            field: "ContactOwner",
            headerName: "Branch",
            width: 150,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) =>
                fields?.row?.accounts_branch !== null
                    ? fields?.row?.accounts_branch?.Area
                    : fields?.row?.BranchId,
        },
        {
            field: "FirstName",
            headerName: "Name",
            width: 200,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "EmailId",
            headerName: "Email ID",
            width: 200,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "MobileNumber",
            headerName: "Mobile Number",
            width: 150,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "CDesignationName",
            headerName: "Designation",
            width: 200,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) => {
                return fields?.row?.customerdesignations?.CDesignationName;
            },
        },
        {
            field: "Department",
            headerName: "Department",
            width: 200,
            headerClassName: "super-app-theme--header",
            valueGetter: (fields) =>
                fields?.row?.customerdepartments?.CDepartmentName,
        },
    ];

    useEffect(() => {
        ContactInactiveList();
    }, [ContactOwner, Refresh]);

    return (
        <>
            {" "}
            <Grid
                item
                md={2.7}
                style={{
                    marginBottom: "5px",
                }}
            >
                <Box
                    id="searcBox"
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        padding: "3px",
                        background: "#FFFFFF",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#2323234D",
                        borderRadius: "10px",
                        textAlign: "center",
                    }}
                >
                    <input
                        placeholder="Search..."
                        type="text"
                        className="searcInput"
                        id="searchInput"
                        style={{
                            outline: "none",
                            backgroundColor: "#FFFFFF",
                            border: "none",
                            width: "100%",
                        }}
                        autoComplete="off"
                        onChange={(e) => filterList(e.target.value)}
                    />
                    <GridSearchIcon style={{ color: "#2323234D" }} />
                </Box>
            </Grid>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <Box
                    sx={{
                        height: 450,
                        width: "100%",
                        "& .super-app-theme--header": {
                            color: "#0071BC",
                        },
                    }}
                >
                    <DataGridPremium
                        sx={{
                            ".MuiTablePagination-selectLabel": {
                                margin: "0px",
                            },
                            ".MuiTablePagination-displayedRows": {
                                margin: "0px",
                            },
                        }}
                        rowHeight={30}
                        columnHeaderHeight={30}
                        pagination={true}
                        pageSize={10}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        {...data}
                        apiRef={apiRef}
                        columns={columns}
                        rows={ContactSearch}
                        getRowId={(row) => row.ContactId}
                        loading={loading}
                        disableRowSelectionOnClick
                        initialState={initialState}
                        slots={{ toolbar: GridToolbar }}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    />
                </Box>
                {/* {ContactEditOpen && (
                    <EditContactPopup
                        data={ContactId}
                        ContactEditOpen={ContactEditOpen}
                        setContactEditOpen={setContactEditOpen}
                        ContactList={ContactList}
                    />
                )} */}
            </Paper>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#232323"}
            />
            <Dialog
                fullWidth
                maxWidth={"xs"}
                open={open}
                onClose={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        fontWeight: "400",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <p
                        style={{
                            fontSize: "18px",
                            fontWeight: "400",
                            margin: "0px",
                        }}
                    >
                        Do You Want To Restore
                    </p>{" "}
                    <p
                        style={{
                            fontSize: "px",
                            margin: "0px",
                            color: "#0071BC",
                            fontWeight: "600",
                        }}
                    >
                    </p>
                </DialogTitle>

                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            maxHeight: "22px",
                            minHeight: "22px",
                            textTransform: "none",
                            padding: "12px",
                        }}
                        onClick={() => {
                            // RestoreCompany();
                            ContactRestore()
                            setOpen(false)
                        }}
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            maxHeight: "22px",
                            minHeight: "22px",
                            textTransform: "none",
                            padding: "12px",
                        }}
                        onClick={() => setOpen(false)}
                    >
                        No{" "}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default ContactInactive