import { Box, Grid, IconButton, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { CloseIcon, ViewIcon } from "../../Assets/Icon";

const PredefinedShow = ({
  PredefinedShowOpen,
  setPredefinedShowOpen,
  PredefinedContent,
  TemplateComment,
}) => {
  return (
    <div>
      <Modal
        open={PredefinedShowOpen}
        onClose={() => setPredefinedShowOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "white",
            boxShadow: 24,
            pt: 2,
            pb: 2,
            pl: 4,
            pr: 4,
            borderRadius: "10px",
            outline: "transparent",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: 500, color: "#0071BC" }}>
              Predefined View
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => {
                setPredefinedShowOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "5px",
            }}
          >
            <p style={{ margin: "0px", fontWeight: 500 }}>Title</p>
            <p style={{ margin: "0px", fontSize: "14px" }}>
              {TemplateComment?.Title}
            </p>
          </Grid>

          <p
            style={{
              margin: "0px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Content
          </p>

          <Grid
            container
            sx={{
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "5px",
              margin: "0px 0px 10px 0px",
              padding: "10px",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: PredefinedContent,
              }}
            />
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default PredefinedShow;
