import { Button, Grid } from "@mui/material";
import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
export default function Master() {
  const location = useLocation();
  // console.log("location", location.state);
  const Navigate = useNavigate();

  return (
    <div>
      {/* <Grid> */}
      <p style={{ fontSize: "18px", color: "#0071BC", fontWeight: 600 }}>
        Masters
      </p>
      <Grid
        container
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          // background: "yellow",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Products
        </p>
        <Grid
          item
          lg={8}
          md={11}
          xs={12}
          style={{ display: "flex", gap: "20px", marginTop: "10px" }}
        >
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              Navigate("ProductCategory");
            }}
          >
            Product Category
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              Navigate("ProductSubCategory");
            }}
          >
            Product SubCategory
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              Navigate("Brand");
            }}
          >
            Brand
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              Navigate("Unit");
            }}
          >
            Unit
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              Navigate("Product");
            }}
          >
            Product
          </Button>
        </Grid>
      </Grid>

      {/*Line 3 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Organization
        </p>
        <Grid
          container
          xs={12}
          style={{ display: "flex", gap: "20px", marginTop: "10px" }}
        >
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("Department")}
          >
            Departments
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("Designation")}
          >
            Designation
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("Role")}
          >
            Role
          </Button>
        </Grid>
      </Grid>

      {/*Line 4 */}
      {/*Line 3 */}

      {/*Line 5 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Ticket
        </p>
        <Grid
          container
          lg={8}
          md={11}
          xs={12}
          style={{
            display: "flex",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("TicketCategory")}
          >
            Ticket Category
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("TicketSource")}
          >
            Ticket Source
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("SolutionConnect")}
          >
            Solution Connect
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("PredefinedReply")}
          >
            Predefined Reply
          </Button>

          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("Ticket")}
          >
            Ticket
          </Button>
        </Grid>
      </Grid>

      {/*Line 6 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Security Policies
        </p>
        <Grid
          container
          xs={12}
          style={{ display: "flex", gap: "20px", marginTop: "10px" }}
        >
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("AddEditStaticIp")}
          >
            Add / Edit Static IP
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("DayHourPolicy")}
          >
            Day / Hour Policy
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("UserPolicy")}
          >
            User Policy
          </Button>
        </Grid>
      </Grid>

      {/*Line 7 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Upload
        </p>
        <Grid
          container
          xs={12}
          style={{ display: "flex", gap: "20px", margin: "10px 0px" }}
        >
          <Button sx={styles.btn} variant="contained">
            Customer Upload
          </Button>
        </Grid>
      </Grid>
      {/*Line 2 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Menu Groups
        </p>
        <Grid
          container
          xs={12}
          style={{ display: "flex", gap: "20px", marginTop: "10px" }}
        >
          <Button sx={styles.btn} variant="contained">
            Menus
          </Button>
          <Button sx={styles.btn} variant="contained">
            Menu Groups
          </Button>
          <Button sx={styles.btn} variant="contained">
            Side Bar
          </Button>
        </Grid>
      </Grid>
      {/*Line 8 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>
          Customer
        </p>
        <Grid
          container
          lg={8}
          md={11}
          xs={12}
          style={{ display: "flex", gap: "20px", margin: "10px 0px" }}
        >
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("CustomerDepartment")}
          >
            Department
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("CustomerDesignation")}
          >
            Designation
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("CustomerCategory")}
          >
            Customer Category
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("CustomerCode")}
          >
            Customer Code
          </Button>
        </Grid>
      </Grid>
      {/* </Grid> */}
      {/*Line 9 */}
      <Grid
        container
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: 600, margin: "0px" }}>User</p>
        <Grid
          container
          lg={8}
          md={10}
          xs={12}
          style={{ display: "flex", gap: "20px", margin: "10px 0px" }}
        >
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => Navigate("Users")}
          >
            Users
          </Button>
          <Button
            sx={styles.btn}
            variant="contained"
            onClick={() => {
              Navigate("Branch");
            }}
          >
            Branch
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
const styles = {
  btn: {
    maxHeight: "22px",
    minHeight: "22px",
    width: "180px",
    minWidht: "171px",
    textTransform: "none",
    background: "#FFFFFF",
    borderRadius: "5px",
    padding: "15px 5px",
    color: "black",
    fontSize: "12px",
    boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
    // boxShadow:
    //   "rgb(204, 219, 232) 3px 3px 6px 0px inset rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
    fontWeight: "400",

    ":hover": {
      // background:
      //   "linear-gradient(90deg, #75aace 5.08%, rgba(10, 124, 198, 0.3) 55.27%, rgba(10, 124, 198, 0.9)  100%, rgba(10, 124, 198, 0.5) 100%)",
      background: "rgba(110,161,222,1)",
      color: "white",
      // background: "#75aace",
      fontWeight: 500,
    },
  },
};
