import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import {
  AppBar,
  Drawer,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";

import {
  TreeOne,
  Myworkspace,
  Setting,
  Recurring,
  Teamticket,
  Master,
  Tags,
  DashBoardIcon,
  CustomerIcon,
  DashBoardActive,
  CustomerActiveIcon,
  MyWorkspaceActive,
  TeamTicketActive,
  RecurringActiveIcon,
  TagsActive,
  MasterActive,
  SettingActive,
  CreatedActive,
  Created,
  TaggedActive,
  Tagged,
  ReportMain,
  Daily,
  Client,
  Product,
  NotesI,
  NoteActive,
  TagActive,
  Tag,
} from "../Assets/Icon";

import TreeView from "@material-ui/lab/TreeView";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { blue, grey } from "@mui/material/colors";
import Content from "./Content";
import { useDispatch } from "react-redux";
import { TreeItem } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { Communication } from "../Assets/Icon";

const drawerWidth = 205;
function MainLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  var DH = new Date().getHours();
  var TT =
    DH >= 0 && DH < 12
      ? "Good Morning"
      : DH >= 12 && DH <= 15
        ? "Good Afternoon"
        : "Good Evening";

  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const open = Boolean(anchorElUser);

  var STab = localStorage.getItem("SideTab");
  const [activeLink, setActiveLink] = React.useState(
    STab !== null ? STab : "dashboard"
  );

  const logout = () => {
    dispatch({ type: "SET_USER_OUT", payload: false });
    localStorage.clear();
    Navigate("/auth");
  };

  //TreeView
  const [expanded, setExpanded] = React.useState([]);
  const [expanded1, setExpanded1] = React.useState([]);

  const handleChange = (event, nodes) => {
    localStorage.setItem("SideTab", nodes);
    setActiveLink(nodes);
    setExpanded(nodes);
  };
  const handleChange1 = (event, nodes) => {
    setExpanded1(nodes);
  };

  const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 150,
      background: "none",
      color: "#FFF",

      // "&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      //   {
      //     background: "none",
      //     fontWeight: 500,
      //     color: "black",
      //   },
    },
    content: {
      flexDirection: "row-reverse",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(2.5),
    },
    labelText: {
      fontWeight: 600,
      flexGrow: 1,
    },
  }));

  const useTreeItemStyles1 = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 150,
      background: "none",
      color: "#FFF",
      // "&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      //   {
      //     background: "none",
      //     fontWeight: 500,
      //     color: "black",
      //   },

      ".MuiTypography-body1": { fontSize: "16px" },
    },

    content: {
      flexDirection: "row-reverse",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),

    },
    labelIcon: {
      marginRight: theme.spacing(1.5),

    },
    labelText: {
      fontWeight: 500,
      flexGrow: 1,
    },
  }));

  function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, ...other } = props;

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="action" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        classes={{
          content: classes.content,
        }}
        {...other}
      />
    );
  }

  function StyledTreeItem1(props) {
    const classes = useTreeItemStyles1();
    const { labelText, labelIcon: LabelIcon, ...other } = props;

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon
              color="#FFF"
            // sx={{ fontSize: 35, color: grey[50], p: "1" }}
            />
            <Typography
              variant="body2"
              sx={{ color: "white", paddingLeft: "10px" }}
              className={classes.labelText}
            >
              {labelText}
            </Typography>
          </div>
        }
        classes={{
          content: classes.content,
        }}
        {...other}
      />
    );
  }
  //TreeView

  const classes = useTreeItemStyles();
  const classes1 = useTreeItemStyles1();

  const drawer = (
    <div>
      <Stack
        sx={{
          width: `${drawerWidth - 1}px`,
          alignItems: "center",
          background: "#f5f5f5",
          paddingBottom: "3px",
        }}
      ></Stack>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#f5f5f5",
          width: `${drawerWidth - 1}px`,
          // position: "fixed",
        }}
      >
        <TreeOne style={{ width: "55%", padding: 2 }} />
      </div>
      <Box
        style={{
          height: "calc(105vh - 100px)",
          maxHeight: "calc(105vh - 100px)",
          // top: "10%",
          // position: "relative",
        }}
      >
        <List>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("Dashboard");
            }}
          >
            <ListItemButton
              onClick={() => {
                localStorage.setItem("SideTab", "dashboard");
                setActiveLink("dashboard");
              }}
              style={
                activeLink === "dashboard" ? styles.touchbtn : styles.untouchbtn
              }
              align={"center"}
            >
              <ListItemIcon
                style={{
                  marginLeft: 2,
                }}
              >
                {activeLink === "dashboard" || activeLink === "app" ? (
                  <DashBoardActive width={"30px"} height={"25px"} />
                ) : (
                  <DashBoardIcon width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              Dashboard
            </ListItemButton>
          </ListItem>

          {(global?.$userData?.logindata?.TicketCompaniesAccess == "1" ||
            global?.$userData?.logindata?.TicketCompaniesAccess == "2") && (
              <ListItem
                disablePadding
                onClick={() => {
                  navigate("Company");
                }}
              >
                <ListItemButton
                  style={
                    activeLink === "company" ? styles.touchbtn : styles.untouchbtn
                  }
                  onClick={() => {
                    localStorage.setItem("SideTab", "company");
                    setActiveLink("company");
                  }}
                  align={"center"}
                >
                  <ListItemIcon style={{ marginLeft: 2 }}>
                    {activeLink === "company" ? (
                      <CustomerActiveIcon width={"30px"} height={"25px"} />
                    ) : (
                      <CustomerIcon width={"30px"} height={"25px"} />
                    )}
                  </ListItemIcon>
                  Companies
                </ListItemButton>
              </ListItem>
            )}

          <ListItem
            disablePadding
            onClick={() => {
              navigate("Created");
            }}
          >
            <ListItemButton
              style={
                activeLink === "created" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "created");
                setActiveLink("created");
              }}
              align={"center"}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "created" ? (
                  <CreatedActive width={"30px"} height={"25px"} />
                ) : (
                  <Created width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              Created
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => {
              navigate("MyWorkspace");
            }}
          >
            <ListItemButton
              style={
                activeLink === "myworkspace"
                  ? styles.touchbtn
                  : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "myworkspace");
                setActiveLink("myworkspace");
              }}
              align={"center"}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "myworkspace" ? (
                  <MyWorkspaceActive width={"30px"} height={"25px"} />
                ) : (
                  <Myworkspace width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              My Workspace
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("TaggedTickets");
            }}
          >
            <ListItemButton
              style={
                activeLink === "Tagged" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "Tagged");
                setActiveLink("Tagged");
              }}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "Tagged" ? <TaggedActive width={"30px"} height={"25px"} /> : <Tagged width={"30px"} height={"25px"} />}
              </ListItemIcon>
              Tagged Tickets
            </ListItemButton>
          </ListItem>

          {(global?.$userData?.logindata?.TicketingDepartmentAdmin == "Yes" ||
            global?.$userData?.logindata?.AccessAdminTicket == "Yes") && (
              <ListItem
                disablePadding
                onClick={() => {
                  navigate("TeamTickets");
                }}
              >
                <ListItemButton
                  style={
                    activeLink === "teamtickets"
                      ? styles.touchbtn
                      : styles.untouchbtn
                  }
                  onClick={() => {
                    localStorage.setItem("SideTab", "teamtickets");
                    setActiveLink("teamtickets");
                  }}
                >
                  <ListItemIcon style={{ marginLeft: 2 }}>
                    {activeLink === "teamtickets" ? (
                      <TeamTicketActive width={"30px"} height={"25px"} />
                    ) : (
                      <Teamticket width={"30px"} height={"25px"} />
                    )}
                  </ListItemIcon>
                  Team Tickets
                </ListItemButton>
              </ListItem>
            )}

          {global?.$userData?.logindata?.AccessAdminTicket === "Yes" && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("All");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "all" ? styles.touchbtn : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "all");
                  setActiveLink("all");
                }}
              >
                <ListItemIcon style={{ marginLeft: 2 }}>
                  {activeLink === "all" ? (
                    <RecurringActiveIcon width={"30px"} height={"25px"} />
                  ) : (
                    <Recurring width={"30px"} height={"25px"} />
                  )}
                </ListItemIcon>
                All
              </ListItemButton>
            </ListItem>
          )}

          {global?.$userData?.logindata?.AccessAdminTicket == "Yes" && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("Master");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "master" ? styles.touchbtn : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "master");
                  setActiveLink("master");
                }}
              >
                <ListItemIcon style={{ marginLeft: 2 }}>
                  {activeLink === "master" ? <MasterActive width={"30px"} height={"25px"} /> : <Master width={"30px"} height={"25px"} />}
                </ListItemIcon>
                Masters
              </ListItemButton>
            </ListItem>
          )}



          <ListItem
            onClick={() => setActiveLink("Reports")}
            sx={{
              "&.MuiListItem-root": {
                paddingLeft: "12px",
              },
            }}
          >
            <TreeView
              className={classes.root}
              defaultCollapseIcon={
                <ExpandMoreIcon sx={{ marginLeft: "50px" }} />
              }
              defaultExpandIcon={
                <ChevronRightIcon sx={{ marginLeft: "50px" }} />
              }
              expanded={expanded}
              onNodeToggle={handleChange}
            >
              <StyledTreeItem
                nodeId="1"
                labelText="Reports"
                labelIcon={ReportMain}
              >
                <StyledTreeItem
                  onClick={() => {
                    localStorage.setItem("SideTab", "DailyReports");
                    setActiveLink("DailyReports");
                    Navigate("DailyReports");
                  }}
                  nodeId="2"
                  labelText="Daily"
                  labelIcon={Daily}
                />
                <StyledTreeItem
                  onClick={() => {
                    localStorage.setItem("SideTab", "ClientReports");
                    setActiveLink("ClientReports");
                    Navigate("ClientReports");
                  }}
                  nodeId="3"
                  labelText="Client"
                  labelIcon={Client}
                />
                <StyledTreeItem
                  onClick={() => {
                    localStorage.setItem("SideTab", "ProductReports");
                    setActiveLink("ProductReports");
                    Navigate("ProductReports");
                  }}
                  nodeId="4"
                  labelText="Product"
                  labelIcon={Product}
                />
              </StyledTreeItem>
            </TreeView>
          </ListItem>

          {/* <ListItem
            onClick={() => setActiveLink("Communication")}
            sx={{
              "&.MuiListItem-root": {
                paddingLeft: "12px",
              },
            }}
          >
            <TreeView
              className={classes1.root1}
              defaultCollapseIcon={
                <ExpandMoreIcon sx={{ marginLeft: "20px", color: "white" }} />
              }
              defaultExpandIcon={
                <ChevronRightIcon sx={{ marginLeft: "20px", color: "white" }} />
              }
              expanded={expanded1}
              onNodeToggle={handleChange1}
            >
              <StyledTreeItem1
                nodeId="1"
                labelText="Communication"
                labelIcon={Communication}
              >
                <StyledTreeItem1
                  onClick={() => Navigate("Communication/SMSLog")}
                  nodeId="2"
                  labelText="SMS Log"
                  labelIcon={SmsOutlinedIcon}
                />
                <StyledTreeItem1
                  nodeId="3"
                  labelText="Email Log"
                  labelIcon={EmailOutlinedIcon}
                  onClick={() => Navigate("Communication/EmailLog")}
                />
                <StyledTreeItem1
                  nodeId="4"
                  labelText="WhatsApp log "
                  labelIcon={WhatsAppIcon}
                  onClick={() => Navigate("Communication/WhatsApp")}
                />
                <StyledTreeItem1
                  nodeId="8"
                  labelText="Conversation"
                  labelIcon={ForumOutlinedIcon}
                  onClick={() => Navigate("Communication/Conversation")}
                />
              </StyledTreeItem1>
            </TreeView>
          </ListItem> */}

          <ListItem
            disablePadding
            onClick={() => {
              navigate("Notes");
            }}
          >
            <ListItemButton
              style={
                activeLink === "Notes" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "Notes");
                setActiveLink("Notes");
              }}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "Notes" ? (
                  <NoteActive width={"30px"} height={"25px"} />
                ) : (
                  <NotesI width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              Notes
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("Tags");
            }}
          >
            <ListItemButton
              style={
                activeLink === "tags" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "tags");
                setActiveLink("tags");
              }}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "tags" ? <TagActive width={"30px"} height={"25px"} /> : <Tag width={"30px"} height={"25px"} />}
              </ListItemIcon>
              Tags
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("Setting");
            }}
          >
            <ListItemButton
              style={
                activeLink === "setting" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "setting");
                setActiveLink("setting");
              }}
            >
              <ListItemIcon style={{ marginLeft: 2 }}>
                {activeLink === "setting" ? <SettingActive width={"30px"} height={"25px"} /> : <Setting width={"30px"} height={"25px"} />}
              </ListItemIcon>
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "#F5F5F5",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>

          <Box item sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Box
                  id="searcBox"
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "3px",
                    background: "#FFFFFF",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#2323234D",
                    borderRadius: "10px",
                    float: "right",
                    margin: 1,
                    textAlign: "center",
                  }}
                >
                  <input
                    placeholder="Search..."
                    type="text"
                    className="searcInput"
                    id="searchInput"
                    style={styles.searchInput}
                    autoComplete="off"
                  />
                  <SearchIcon style={{ color: "#2323234D" }} />
                </Box>
              </Grid>

              <Grid
                item
                xl={8}
                lg={8}
                md={6}
                sm={12}
                xs={12}
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <NotificationsNoneIcon
                  sx={{
                    color: "#000",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                  fontSize="large"
                />
                <div
                  style={{
                    textAlign: "end",
                    marginRight: 15,
                    alignSelf: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#000", alignSelf: "center" }}
                  >
                    {global?.$userData?.logindata?.UserName}
                  </Typography>
                  <Typography
                    variant="subtitle2  "
                    style={{ color: "#000", alignSelf: "center" }}
                  >
                    {TT}
                  </Typography>
                </div>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={global?.$userData?.logindata?.UserName}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>

                <Menu
                  id="account-menu"
                  open={open}
                  anchorEl={anchorElUser}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      Navigate("Profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  {/* <MenuItem onClick={handleCloseUserMenu}>
                    My account
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleCloseUserMenu}>
                    Add another account
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    Settings
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      logout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>

      {/*Mobile*/}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <TreeOne style={{ width: 0, padding: 0 }} />

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#0071BC",
            },
          }}
        >
          {drawer}
        </Drawer>

        {/*Web*/}
        <Drawer
          variant="permanent"
          className="Main"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              border: "unset",
              overflow: "auto",
              background: "#0071BC",
              overflowY: "auto",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/*Main Content*/}
      <Box
        component="main"
        className="Main"
        sx={{
          flexGrow: 1,
          p: 3,
          pb: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "calc(113vh - 100px)",
          maxHeight: "calc(113vh - 100px)",
          overflow: "auto",

          // backgroundImage:
          //   "linear-gradient(-180deg, #FFF 0%, rgba(10, 124, 198, 0.2) 100%)",
          // background: "#273c62",
        }}
      >
        <Toolbar />
        <Content>
          <Outlet />
        </Content>
      </Box>
    </Box>
  );
}

export default MainLayout;

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  touchbtn: {
    color: "black",
    background: "#FFFFFF",
    fontWeight: 600,
    letterSpacing: "0.5px",
    padding: "10px",
  },
  untouchbtn: {
    color: "#FFFFFF",
    padding: "10px",
  },
  checkboxLabel: {
    border: "1px solid black",
    fontWeight: "100",
    fontSize: "20px",
  },
};
