import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  // FormControl,
  FormControlLabel,
  // FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";


const UserPolicyScreen = ({ setTab }) => {
  const Navigate = useNavigate();
  return (
    <div>
      <div>
        <span
          onClick={() => Navigate(-1)}
          style={{
            color: "#0071BC",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Back">
            <ArrowBackIosNewIcon
              sx={{
                width: "20px",
                height: "15px",
                marginBottom: "3px",
              }}
            />
          </Tooltip>
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: 600,
            margin: "0px",
            color: "#0071BC",
            cursor: "pointer",
          }}
          onClick={() => Navigate(-1)}
        >
          Masters /
        </span>{" "}
        <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
          {" "}
          User Policy
        </span>
      </div>
      <div style={{ height: 500, marginTop: "20px" }}>
        <DataGrid
          sx={{
            boxShadow: 2,

            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={rows}
          columns={columns}
          pageSize={5}
        //   checkboxSelection
        />
      </div>
    </div>
  );
};

export default UserPolicyScreen;

const columns = [
  {
    field: "id",
    headerClassName: "super-app-theme--header",
    headerName: "ID",
    width: 50,
  },
  {
    field: "userName",
    headerClassName: "super-app-theme--header",
    headerName: "User Name",
    width: 150,
  },
  {
    field: "faAuth",
    headerClassName: "super-app-theme--header",
    headerName: "2FA Auth",
    width: 182,
    renderCell: (cellValues) => {
      return radio();
    },
  },
  {
    field: "faOTP",
    headerClassName: "super-app-theme--header",
    headerName: "2FA OTP",
    width: 182,
    renderCell: (cellValues) => {
      return radio();
    },
  },
  {
    field: "ipAccess",
    headerName: "IP Access",
    headerClassName: "super-app-theme--header",
    width: 182,
    renderCell: (cellValues) => {
      return radio();
    },
  },
  {
    field: "workingDays",
    headerName: "Working Days",
    headerClassName: "super-app-theme--header",
    width: 182,
    renderCell: () => {
      return radio();
    },
  },
  {
    field: "workingHours",
    headerName: "Working Hours",
    headerClassName: "super-app-theme--header",
    width: 182,
    renderCell: (cellValues) => {
      return radio();
    },
  },
];

const rows = [
  {
    id: 1,
    userName: "Snow",
    faAuth: radio(),
    faOTP: radio(),
    ipAccess: radio(),
    workingDays: "08-03-23 14:00:00 PM",
    workingHours: "Power Supply Is Not Proper",
  },
];
// const header=["ID", "User Name", "2FA AUTH" , "2FA OTP", "IP Access" , "Working Days", "Working Hours"]
function radio() {
  return (
    <Grid
      style={{
        display: "flex",
        padding: "0px 0px 0px 2px",
      }}
    >
      <RadioGroup
        sx={{ display: "flex", justifyContent: "center" }}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          sx={{
            display: "flex",
            gap: "5px",
            ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
          }}
          value="female"
          control={<Radio style={{ width: "5px", height: "5px" }} />}
          label="Enable"
          size="small"
          labelPlacement="end"
        />
        <FormControlLabel
          sx={{
            display: "flex",
            gap: "5px",
            ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
          }}
          value="male"
          control={<Radio style={{ width: "10px", height: "5px" }} />}
          label="Disable"
          labelPlacement="end"
        />
      </RadioGroup>
    </Grid>
  );
}
