import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const DesignationPopup = ({
  Refresh,
  setRefresh,
  Designationopen,
  setDesignationopen,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setDesignationopen(false);
  const [age, setAge] = React.useState("");
  const [designationName, setDesignationName] = useState("");

  const designation = async () => {
    if (designationName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        DesignationName: designationName,
        UserId: userId,
      };
      const response = await global.$axios.post("designation/add", data)
        .then(async (response) => {
          if (response.data.status == 200) {
            handleClose();
            setRefresh(!Refresh);
            setDesignationName("");
            toast.success("Designation Added");
          } else {
            toast.error("Somthing went wrong");
          }
        })
        .catch((e) => {
          console.error("designationpopo", e)
        })

    }
  };
  const [err, seterr] = useState(0);

  const validation = async (val) => {
    const response = await global.$axios.get(
      `/designation/validatedesignation/${AppCode}/${val}`
    ).then(async (response) => {
      seterr(response.data.Message);
    })
      .catch((e) => {
        console.error("designationvaludation", e)
      })

  };

  return (
    <div>
      <Modal
        open={Designationopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                color: "#0071BC",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {" "}
              Add Designation
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            value={designationName}
            onChange={(e) => setDesignationName(e.target.value)}
            sx={{ minWidth: 330 }}
            placeholder="Add Designation Here"
            onKeyUp={(e) => {
              e.target.value !== "" && validation(e.target.value);
            }}
            error={err === 1 ? true : false}
            helperText={
              err === 1 ? (
                <span style={{ color: "red" }}>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>iuftghj</span>
              )
            }
            id="outlined-size-small"
            size="small"
            required
          />
          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                designation();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default DesignationPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
