import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast, ToastContainer } from "react-toastify";
import dayjs from "dayjs";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro";
import { useMediaQuery } from "@material-ui/core";

const DepartmentPopup = ({
  Refresh,
  setRefresh,
  DepartementOpen,
  setDepartementOpen,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const handleClose = () => setDepartementOpen(false);

  const [departmentName, setDepartmentName] = useState("");

  var close = "";
  var start = "";
  const [value, setvalue] = useState(dayjs);
  if (
    value.length >= 1 &&
    value[0] != null &&
    value[0].$d != "Invalid Date" &&
    value[1] != null &&
    value[1].$d != "Invalid Date"
  ) {
    start = new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(value[0]?.$d);
    close = new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(value[1]?.$d);
  }

  let workingto = close.split(" ");
  let finalclose = "";
  if (workingto.length === 2 && workingto[1] == "PM") {
    const settime = workingto[0].split(":");
    if (settime[0] != 12) {
      finalclose =
        parseInt(settime[0]) +
        parseInt(12) +
        ":" +
        settime[1] +
        ":" +
        settime[2];
    } else {
      finalclose = parseInt(settime[0]) + ":" + settime[1] + ":" + settime[2];
    }
  } else {
    finalclose = workingto[0];
  }
  let WorkingFrom = start.split(" ");
  let finalstart = "";
  if (WorkingFrom.length === 2 && WorkingFrom[1] == "PM") {
    const fromtime = WorkingFrom[0].split(":");
    if (fromtime[0] != 12) {
      finalstart =
        parseInt(fromtime[0]) +
        parseInt(12) +
        ":" +
        fromtime[1] +
        ":" +
        fromtime[2];
    } else {
      finalstart =
        parseInt(fromtime[0]) + ":" + fromtime[1] + ":" + fromtime[2];
    }
  } else {
    finalstart = WorkingFrom[0];
  }

  const department = async () => {
    if (departmentName == "" || finalclose == "" || finalstart == "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        DepartmentName: departmentName,
        WorkingFrom: finalstart,
        WorkingTo: finalclose,
        UserId: userId,
      };
      const response = await global.$axios
        .post("department/add", data)
        .then(async (response) => {
          if (response.data.status == 200) {
            toast.success("Departement Added Successfully");
            setDepartmentName("");
            handleClose();
            setRefresh(!Refresh);
          } else {
            toast.error("SomeThing Went Wrong");
          }
        })
        .catch((e) => {
          console.error("Department Popup Issue", e);
        });
    }
  };

  const [err, seterr] = useState(0);
  const [err1, seterr1] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const validation = async (val) => {
    const response = await global.$axios
      .get(`/department/validatedepartmentname/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("departementpopvaludation", e);
      });
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
        open={DepartementOpen}
        onClose={() => setDepartementOpen(false)}
      >
        <Box sx={{ padding: "10px 20px 20px  20px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
            xs={12}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              Add Department{" "}
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12}>
              <p style={style5.title}>Department *</p>
              <TextField
                fullWidth
                value={departmentName}
                onChange={(w) => setDepartmentName(w.target.value)}
                onKeyUp={(e) => validation(e.target.value)}
                error={err === 1 ? true : false}
                helperText={err === 1 ? <span>Already Exist</span> : ""}
                placeholder="Add Department Here"
                id="outlined-size-small"
                size="small"
                required
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <p style={{ margin: "10px 0px 10px 0px", fontSize: "14px" }}>
              Working Hours *
            </p>
            <Grid
              xs={12}
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SingleInputTimeRangeField
                  fullWidth
                  minTime={dayjs().set("hour", 0)}
                  maxTime={dayjs().set("hour", 23)}
                  onChange={(newvalue) => setvalue(newvalue)}
                  slotProps={{
                    textField: {
                      helperText: err1 ? (
                        <span>Please Provide Time</span>
                      ) : (
                        <span style={{ color: "transparent" }}>Not</span>
                      ),
                      size: "small",
                    },
                  }}
                  label="From - To"
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <center style={{ marginTop: "10px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 || err1 == true ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                department();
                // window.location.reload(false);
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default DepartmentPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  },
};
const mainmenu = {
  width: "180px",
  height: "100%",
};
const style5 = {
  title: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};
