import * as React from "react";
import { DialogTitle, Paper } from "@material-ui/core";
import { useDemoData } from "@mui/x-data-grid-generator";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Tooltip,
  Box,
} from "@mui/material";

import { GridSearchIcon } from "@mui/x-data-grid";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SwipeIcon from "@mui/icons-material/Swipe";
import UnassignedTicketTransfer from "./UnassignedTicketTransfer";
import ReassignedTicket from "./ReassignedTicket";
export default function UnassignedTable() {
  const Navigate = useNavigate();
  const [refresh, Setrefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Mui Premium
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 10,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 60,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "TicketView" || "",
      headerName: "Actions",
      width: 120,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="UnassignedTicketTransfer">
              <CompareArrowsIcon
                style={{ width: "23px", height: "20px" }}
                onClick={() => {
                  SetTransferopen(true);
                  SetEdit(cellValues.row);
                }}
              />
            </Tooltip>

            <Tooltip title="ReassignedTicket">
              <SwipeIcon
                style={{ width: "23px", height: "20px" }}
                onClick={() => {
                  SetReassignedopen(true);
                  SetEdits(cellValues.row);
                }}
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "DepartmentName",
      headerName: "Department",
      width: 180,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.dept?.DepartmentName,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ContactName",
      headerName: "Customer Name",
      width: 180,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "ProductName",
      headerName: "Product Name	",
      width: 250,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 180,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "PriorityLevel",
      headerName: "Priority",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.PriorityLevel,
      align: "center",
      headerAlign: "center",
    },
  ];

  const navigate = useNavigate();
  const [ActiveRow, setActiveRow] = useState([]);
  const [UnAssignSearch, setUnAssignedSearch] = useState([]);
  //popup
  const [Transferopen, SetTransferopen] = useState(false);
  const [Reassignedopen, SetReassignedopen] = useState(false);

  const [Edit, SetEdit] = useState("");
  const [Edits, SetEdits] = useState("");

  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //API

  const unassigned = async () => {
    const response = await global.$axios.get(`/ticket/unassigned/${AppCode}`);
    setActiveRow(response.data.Message);
    setUnAssignedSearch(response.data.Message);
  };

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = ActiveRow.filter((item) => {
        const TicketId = String(item.TicketId);
        const DepartmentName = String(item?.dept?.DepartmentName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const PriorityLevel = String(item?.PriorityLevel);
        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          DepartmentName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          PriorityLevel.toLowerCase().indexOf(text_data) > -1
        );
      });

      setUnAssignedSearch(ticketData);
    } else {
      setUnAssignedSearch(ActiveRow);
    }
  }

  useEffect(() => {
    unassigned();
  }, []);
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

        }}

        xs={12}
      >
        <>
          <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
            Unassigned Tickets
          </p>
        </>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          md={3}
          sm={3}
          xs={12}
        >
          <Box
            id="Unassigned search"
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-start",
              padding: "3px",
              background: "#FFFFFF",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#2323234D",
              borderRadius: "10px",
              float: "right",
              margin: "0px 0px 10px 0px",
              textAlign: "center",
            }}
          >
            <input
              placeholder="Search..."
              type="text"
              className="searcInput"
              id="searchInput"
              style={styles.searchInput}
              autoComplete="off"
              onChange={(e) => filterList(e.target.value)}
            />
            <SearchIcon style={{ color: "#2323234D" }} />
          </Box>
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            height: 450,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
            }}
            rowHeight={30}
            columnHeaderHeight={30}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={UnAssignSearch}
            getRowId={(row) => row.TicketId}
            loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            pagination={true}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
        </Box>
      </Paper>

      <div>
        <Dialog
          // fullWidth
          maxWidth={"xs"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                margin: "0px",
              }}
            >
              Sure You Want To Delete This Ticket No.
              <span style={{ color: "darkred" }}>{/* {TicketId} */}</span>
            </p>{" "}
          </DialogTitle>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0px 0px 10px 0px",
            }}
          >
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                // userdelete(deletedata.BrandId);
                handleClose();
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={handleClose}
            >
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {Transferopen && (
        <UnassignedTicketTransfer
          data={Edit}
          Transferopen={Transferopen}
          SetTransferopen={SetTransferopen}
          Setrefresh={Setrefresh}
          refresh={refresh}
        />
      )}
      {Reassignedopen && (
        <ReassignedTicket
          data={Edits}
          Reassignedopen={Reassignedopen}
          SetReassignedopen={SetReassignedopen}
          Setrefresh={Setrefresh}
          refresh={refresh}
        />
      )}
    </>
  );
}
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
