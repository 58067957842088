import React from "react";
import { useEffect } from "react";
import { useDemoData } from "@mui/x-data-grid-generator";
import moment from "moment";
import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import clsx from "clsx";
import { Button, Dialog, DialogActions, Grid, Tooltip } from "@mui/material";
import { Box, DialogTitle, Paper } from "@material-ui/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ViewIcon } from "../../Assets/Icon";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import TicketTransfer from "./TicketTransfer";
import { GridSearchIcon } from "@mui/x-data-grid";

const TAll = ({ Id, DepartmentValue }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Navigate = useNavigate();
  const [refresh, Setrefresh] = useState(false);

  //popup
  const [Transferopen, SetTransferopen] = useState(false);
  const [Edit, SetEdit] = useState("");
  const [All, setAll] = useState([]);
  // const [TicketId, setTicketId] = useState("");
  const [Search, Setsearch] = useState([]);
  const LocalSearchvalue = localStorage.getItem("TAllTblSrch");
  const [SearchValue, setSearchValue] = useState(LocalSearchvalue);
  //Dialog
  const [deletedata, setdeletedata] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const NewList = async () => {
    const data = {
      AppCode: AppCode,
      DepartmentId: [DepartmentValue.DepartmentId],
      UserId: Id,
    };
    const response = await global.$axios
      .post(`ticket/team/alltickets`, data)
      .then(async (response) => {
        if (response.data.Status == 200) {
          setAll(response.data.Message);
          let ticketData = [];
          if (LocalSearchvalue) {
            ticketData = response.data.Message.filter((item) => {
              const TicketId = String(item?.TicketId);
              const CompanyName = String(item?.contact?.account?.CompanyName);
              const ContactName = String(item?.contact?.ContactName);
              const ProductName = String(item?.product?.ProductName);
              const IssueName = String(item?.issue?.IssueName);
              const UpdatedBy = String(item?.updated?.UserFirstName);
              const ETC = String(
                moment(item?.ECAt, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YYYY hh:mm A"
                )
              );
              const ClosedTime = String(
                moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YYYY hh:mm A"
                )
              );
              // const Description = String(item?.TicketDescription);
              const AssignedBy = String(item?.created?.UserFirstName);
              const Status = String(item?.Status);

              const text_data = LocalSearchvalue.toLowerCase();
              return (
                TicketId.indexOf(text_data) > -1 ||
                CompanyName.toLowerCase().indexOf(text_data) > -1 ||
                ContactName.toLowerCase().indexOf(text_data) > -1 ||
                ProductName.toLowerCase().indexOf(text_data) > -1 ||
                IssueName.toLowerCase().indexOf(text_data) > -1 ||
                UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
                ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
                AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
                ETC.toLowerCase().indexOf(text_data) > -1 ||
                Status.toLowerCase().indexOf(text_data) > -1
              );
            });
            Setsearch(ticketData);
          } else {
            Setsearch(response.data.Message);
          }
        }
      })
      .catch((e) => {
        console.error("Team All List Issue", e);
      });
  };

  //Mui Premium
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "asc" }],
      },
    },
  });

  function UpdatedFullName(fields) {
    return `${fields?.row?.updated?.UserFirstName || ""} ${
      fields?.row?.updated?.UserLastName || ""
    }`;
  }

  const columns = [
    {
      field: "TicketId",
      headerName: "Ticket ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
      cellClassName: (params) => {
        let startDate = dayjs();
        var date = `${startDate.$d.getFullYear()}-${
          startDate.$d.getMonth() + 1 < 10
            ? "0" + (startDate.$d.getMonth() + 1)
            : startDate.$d.getMonth() + 1
        }-${
          startDate.$d.getDate() < 10
            ? "0" + startDate.$d.getDate()
            : startDate.$d.getDate()
        }`;
        var date3 = moment(params.row.ECAt, "YYYY-MM-DD hh:mm A").format(
          "YYYY-MM-DD"
        );
        var date4 = moment(
          params.row.TicketClosedTime,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD");
        return clsx("super-app", {
          Sla: date3 < date && date4 > date3,
        });
      },
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions ",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Ticket Transfer">
              <CompareArrowsIcon
                style={{ width: "23px", height: "20px" }}
                onClick={() => {
                  SetTransferopen(true);
                  SetEdit(cellValues.row);
                }}
              />
            </Tooltip>

            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("TeamView", {
                    state: { data: cellValues.row, Table: Search },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "Department",
      headerName: "Department",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.dept?.DepartmentName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ECAt",
      headerName: " Time To Complete",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YY hh.mm A"
        ) || "",
    },
    {
      field: "Assigned By",
      headerName: "Assigned By",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.created
          ? `${fields?.row?.created?.UserFirstName || ""} ${
              fields?.row?.created?.UserLastName || ""
            }`
          : fields?.row?.contact?.ContactName,
    },

    {
      field: "Updated At",
      headerName: "Created At",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        moment(fields?.row?.CreatedAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YY hh.mm A"
        ) || "",
    },
  ];
  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = All.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        // const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      Setsearch(ticketData);
    } else {
      Setsearch(All);
    }
  }

  useEffect(() => {
    if (Id.length != 0) {
      NewList();
    } else {
      setAll([]);
    }
  }, [Id, refresh]);

  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "5px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          All
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            value={SearchValue}
            autoComplete="off"
            onChange={(e) => {
              filterList(e.target.value);
              setSearchValue(e.target.value);
              setTimeout(() => {
                localStorage.setItem("TAllTblSrch", e.target.value);
              }, 2000);
            }}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
              "& .super-app.Sla": {
                backgroundColor: "rgb(252,204,203)",
              },
              "& .super-app-theme--New": {
                backgroundColor: "rgb(229,243,253)",
                "&:hover": {
                  backgroundColor: "rgb(179,229,251)",
                },
              },
            }}
            rowHeight={33}
            {...data}
            apiRef={apiRef}
            columns={columns}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rows={Search ? Search : []}
            getRowId={(row) => row.TicketId}
            loading={loading}
            pagination={true}
            initialState={initialState}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            slots={{ toolbar: GridToolbar }}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.Status}`
            }
          />
        </Box>
      </Paper>
      <div>
        <Dialog
          // fullWidth
          maxWidth={"xs"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                margin: "0px",
              }}
            >
              Sure You Want To Delete This Ticket No.
              {/* <span style={{ color: "darkred" }}>{TicketId}</span> */}
            </p>{" "}
          </DialogTitle>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0px 0px 10px 0px",
            }}
          >
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                // userdelete(deletedata.BrandId);
                handleClose();
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={handleClose}
            >
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {Transferopen && (
        <TicketTransfer
          data={Edit}
          Transferopen={Transferopen}
          SetTransferopen={SetTransferopen}
          Setrefresh={Setrefresh}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default TAll;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
