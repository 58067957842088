import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const PredefinedPopup = () => {
  const AppCode = global.$userData.logindata.AppCode;
  const user = global.$userData.logindata.UserId;
  const Navigate = useNavigate();
  // console.log(AppCode, "app1");

  const [reply, setReply] = useState("");
  const [comments, setComments] = useState("");

  //Clipboard Copy
  const [CompanyCopied, setCompanyCopied] = useState(false);
  const [ContactCopied, setContactCopied] = useState(false);
  const [DepartmentCopied, setDepartmentCopied] = useState(false);
  const [ProductCopied, setProductCopied] = useState(false);
  const [IssueCopied, setIssueCopied] = useState(false);
  const [PhoneCopied, setPhoneCopied] = useState(false);
  const [EmailCopied, setEmailCopied] = useState(false);
  const [TicketIdCopied, setTicketIdCopied] = useState(false);
  const [AssignedToCopied, setAssignedToCopied] = useState(false);
  const [CreatedAtCopied, setCreatedAtCopied] = useState(false);
  const [UpdatedAtCopied, setUpdatedAtCopied] = useState(false);
  const [DueDateCopied, setDueDateCopied] = useState(false);
  const [CompletedOnCopied, setCompletedOnCopied] = useState(false);
  const [TicketSourceCopied, setTicketSourceCopied] = useState(false);

  const CompanyCopy = ["{CompanyName}"];
  const ContactCopy = ["{ContactName}"];
  const DepartmentCopy = ["{Department}"];
  const ProductCopy = ["{ProductName}"];
  const IssueCopy = ["{IssueName}"];
  const PhoneCopy = ["{Phone}"];
  const EmailCopy = ["{Email}"];
  const TicketIdCopy = ["{TicketId}"];
  const AssignedToCopy = ["{AssignedTo}"];
  const CreatedAtCopy = ["{CreatedAt}"];
  const DueDateCopy = ["{DueDate}"];
  const CompletedOnCopy = ["{CompletedOn}"];
  const TicketSourceCopy = ["{TicketSource}"];
  const UpdatedAtCopy = ["{UpdatedAt}"];

  /* Jodit Editor*/
  const editor = useRef(null);

  const replyAdd = async () => {
    if (reply == "" || comments == "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        Title: reply,
        Comments: comments,
        UserId: user,
      };

      const response = await global.$axios
        .post("predefined_reply/add", data)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Predefined Reply Added");
            setTimeout(() => {
              Navigate(-1);
            }, 1500);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("Add Predefined Issue");
        });
    }
  };

  const [err, seterr] = useState(0);

  const validate = async (valu) => {
    const response = await global.$axios.get(
      `predefined_reply/validatepredefined/${AppCode}/${valu}`
    );
    seterr(response.data.Message);
  };

  return (
    <>
      <Grid container style={{}}>
        {" "}
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{ color: "#0071BC", cursor: "pointer" }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{ width: "20px", height: "15px", marginBottom: "3px" }}
              />
            </Tooltip>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => Navigate(-1)}
          >
            Predefined Reply /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            Add Replies{" "}
          </span>
        </div>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px",
            alignItems: "center",
            fontSize: "14px",
            lineHeight: "27px",
          }}
        >
          <Grid item lg={8.3} md={7.5} xs={12}>
            <Grid item>
              <p
                style={{
                  fontSize: "14px",
                  margin: "10px 0px 0px 0px",
                }}
              >
                Template Title
              </p>
              <TextField
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                onKeyUp={(e) => {
                  {
                    e.target.value && validate(e.target.value);
                  }
                }}
                error={err == 1 ? true : false}
                helperText={
                  err === 1 ? (
                    <span>Already Exist</span>
                  ) : (
                    <span style={{ color: "transparent" }}>Already Exist</span>
                  )
                }
                placeholder="New Title Here"
                id="outlined-size-small"
                size="small"
              />
            </Grid>
            <Grid item style={{ marginBottom: "10px" }}>
              <p
                style={{
                  padding: "10px 15px",
                  background: "rgba(0, 113, 188, 0.1)",
                  margin: "0px",
                }}
              >
                Description
              </p>
              <JoditEditor
                ref={editor}
                value={comments}
                onBlur={(e) => setComments(e)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            lg={3.2}
            md={4.3}
            xs={12}
            style={{
              border: "1px solid rgba(0,0,0,0.2)",
              padding: "5px",
              borderRadius: "5px",
              background: "#fff",
              margin: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Company Name :</span>
              <span>
                {CompanyCopy}
                <CopyToClipboard
                  text={CompanyCopy}
                  onCopy={() => {
                    setCompanyCopied(true);
                    setTimeout(() => {
                      setCompanyCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {CompanyCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Contact Name :</span>
              <span>
                {ContactCopy}
                <CopyToClipboard
                  text={ContactCopy}
                  onCopy={() => {
                    setContactCopied(true);
                    setTimeout(() => {
                      setContactCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {ContactCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Department :</span>
              <span>
                {DepartmentCopy}
                <CopyToClipboard
                  text={DepartmentCopy}
                  onCopy={() => {
                    setDepartmentCopied(true);
                    setTimeout(() => {
                      setDepartmentCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {DepartmentCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Product Name :</span>
              <span style={{ textAlign: "end" }}>
                {ProductCopy}
                <CopyToClipboard
                  text={ProductCopy}
                  onCopy={() => {
                    setProductCopied(true);
                    setTimeout(() => {
                      setProductCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {ProductCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Issue :</span>{" "}
              <span>
                {IssueCopy}
                <CopyToClipboard
                  text={IssueCopy}
                  onCopy={() => {
                    setIssueCopied(true);
                    setTimeout(() => {
                      setIssueCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {IssueCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Phone :</span>{" "}
              <span>
                {PhoneCopy}
                <CopyToClipboard
                  text={PhoneCopy}
                  onCopy={() => {
                    setPhoneCopied(true);
                    setTimeout(() => {
                      setPhoneCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {PhoneCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>
            {/* </Grid> */}
            {/* <Grid item md={6} xs={12} style={{ background: "yellow" }}> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>Email :</span>{" "}
              <span>
                {EmailCopy}
                <CopyToClipboard
                  text={EmailCopy}
                  onCopy={() => {
                    setEmailCopied(true);
                    setTimeout(() => {
                      setEmailCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {EmailCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>TicketId :</span>{" "}
              <span>
                {TicketIdCopy}
                <CopyToClipboard
                  text={TicketIdCopy}
                  onCopy={() => {
                    setTicketIdCopied(true);
                    setTimeout(() => {
                      setTicketIdCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {TicketIdCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>AssignedTo :</span>{" "}
              <span>
                {AssignedToCopy}
                <CopyToClipboard
                  text={AssignedToCopy}
                  onCopy={() => {
                    setAssignedToCopied(true);
                    setTimeout(() => {
                      setAssignedToCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {AssignedToCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>CreatedAt :</span>{" "}
              <span>
                {CreatedAtCopy}
                <CopyToClipboard
                  text={CreatedAtCopy}
                  onCopy={() => {
                    setCreatedAtCopied(true);
                    setTimeout(() => {
                      setCreatedAtCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {CreatedAtCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>UpdatedAt :</span>{" "}
              <span>
                {UpdatedAtCopy}
                <CopyToClipboard
                  text={UpdatedAtCopy}
                  onCopy={() => {
                    setUpdatedAtCopied(true);
                    setTimeout(() => {
                      setUpdatedAtCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {UpdatedAtCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>DueDate :</span>{" "}
              <span>
                {DueDateCopy}
                <CopyToClipboard
                  text={DueDateCopy}
                  onCopy={() => {
                    setDueDateCopied(true);
                    setTimeout(() => {
                      setDueDateCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {DueDateCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>CompletedOn :</span>{" "}
              <span>
                {CompletedOnCopy}
                <CopyToClipboard
                  text={CompletedOnCopy}
                  onCopy={() => {
                    setCompletedOnCopied(true);
                    setTimeout(() => {
                      setCompletedOnCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {CompletedOnCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
              }}
            >
              <span>TicketSource :</span>{" "}
              <span>
                {TicketSourceCopy}
                <CopyToClipboard
                  text={TicketSourceCopy}
                  onCopy={() => {
                    setTicketSourceCopied(true);
                    setTimeout(() => {
                      setTicketSourceCopied(false);
                    }, 1000);
                  }}
                >
                  <span style={{ paddingLeft: "5px" }}>
                    {TicketSourceCopied ? (
                      <DoneIcon color="success" fontSize="small" />
                    ) : (
                      <ContentCopyIcon color="primary" fontSize="small" />
                    )}
                  </span>
                </CopyToClipboard>
              </span>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              replyAdd();
            }}
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            size="small"
            variant="contained"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
};

export default PredefinedPopup;
