import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { CloseIcon, Note } from "../../Assets/Icon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment/moment";

export default function TicketNotes({ NotesOpen, setNotesOpen, TicketId }) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [AddNotes, setAddNotes] = useState(false);
  const [ViewNote, setViewNote] = useState([]);
  const [Changes, setChanges] = useState(false);

  //APIs
  //Add Notes
  const AddNote = async () => {
    if (content == "") {
      toast.error("Please Provide Notes");
      return;
    } else {
      const response = await global.$axios
        .post(`/ticket/updateNotes`, {
          AppCode: AppCode,
          TicketId: TicketId,
          For: 5,
          Description: content,
          Attachment: "",
          UserId: userId,
        })
        .then(async (response) => {
          if (response.data.Status === 200) {
            ViewNotes();
            toast.success("Notes Added");
            setAddNotes(false);
            setContent("");
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          toast.error("Add Notes Issue");
          console.error("Add Notes Issue", e);
        });
    }
  };

  //View Notes
  const ViewNotes = async () => {
    const response = await global.$axios
      .post(`ticket/internalNotes`, {
        AppCode: AppCode,
        TicketId: TicketId,
        For: 5,
      })
      .then(async (response) => {
        setViewNote(response.data.Message);
      })
      .catch((e) => {
        console.error("View Notes Issue", e);
      });
  };

  useEffect(() => {
    ViewNotes();
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"sm"}
        open={NotesOpen}
        onClose={() => setNotesOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <Box sx={{ padding: "10px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogTitle id="responsive-dialog-title">Notes</DialogTitle>
            <IconButton
              onClick={() => setNotesOpen(false)}
              style={{ padding: "10px" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "5px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <Grid item xs={12}>
              {" "}
              {AddNotes ? (
                <>
                  <div style={{}}>
                    <span
                      onClick={() => {
                        setAddNotes(false);
                        setContent("");
                        setChanges(false);
                      }}
                      style={{
                        color: "#0071BC",
                        cursor: "pointer",
                        fontSize: "13px",
                      }}
                    >
                      <Tooltip title="Back">
                        <ArrowBackIosNewIcon
                          sx={{
                            width: "13px",
                            height: "13px",
                            marginBottom: "1px",
                          }}
                        />
                      </Tooltip>
                      Back
                    </span>
                  </div>
                  {/* <input
                    placeholder="Heading..."
                    style={{
                      border: "none",
                      outline: "none",
                      margin: "10px 0px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  /> */}
                  <JoditEditor
                    ref={editor}
                    value={content}
                    onBlur={(e) => setContent(e)}
                  />
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "20px 0px 0px 0px",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!Changes ? true : false}
                      sx={{
                        maxHeight: "22px",
                        minHeight: "22px",
                        padding: "12px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => AddNote()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    container
                    item
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      maxHeight: "xs" ? "60vh" : "30vh",
                      overflowY: "auto",
                    }}
                  >
                    {ViewNote.length != 0 ? (
                      ViewNote.map((item) => {
                        return (
                          <Grid
                            md={5.8}
                            xs={12}
                            item
                            onClick={() => {
                              setContent(item?.Description);
                              setAddNotes(true);
                            }}
                            sx={{
                              border: "1px solid rgba(0,0,0,0.1)",
                              padding: "10px",
                              borderRadius: "10px",
                              marginBottom: "10px",
                              background: "rgb(26,17,16)",
                              color: "#FFF",
                              height: "15vh",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                height: "90%",
                                overflow: "hidden",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.Description,
                              }}
                            />
                            <p
                              style={{
                                color: "red",
                                mardgin: "0px",
                                textAlign: "end",
                                fontSize: "11px",
                              }}
                            >
                              {moment(
                                item?.CreatedAt,
                                "YYYY-MM-DD hh:mm A"
                              ).format("DD-MM-YYYY hh:mm A")}
                            </p>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: "20px",
                        }}
                      >
                        <Note
                          style={{
                            width: "100px",
                            height: "100px",
                            color: "#c2c2c2",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "16",
                            fontWeight: 500,
                            color: "rgba(0,0,0,0.5)",
                            margin: "0px",
                            padding: "10px 10px",
                          }}
                        >
                          No Notes
                        </p>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      onClick={() => {
                        setAddNotes(true);
                        setChanges(true);
                      }}
                    >
                      <Tooltip title="Add Notes">
                        <AddCircleIcon
                          color="primary"
                          fontSize="large"
                          sx={{ width: "45px", height: "45px" }}
                        />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}
