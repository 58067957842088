import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Addmorecompanybranches from "./Addmorecompanybranches";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";

function Addmorecompanys() {
  const location = useLocation();

  //Input UseStates
  const [customerCode, setCustomerCode] = useState("");
  const [companyName, setComapnyName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [mailID, setMailID] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [webSite, setWebSite] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [comment, setComment] = useState("");
  const [MCount, setMCount] = useState(false);

  //DropDown's
  const [industryName, setIndustryName] = useState("");
  const [IndustryDrop, setIndustryDrop] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [CountryDrop, setCountryDrop] = useState([]);
  const [stateName, setStateName] = useState("");
  const [StateDrop, setStateDrop] = useState([]);
  const [cityName, setCityName] = useState("");
  const [CityDrop, setCityDrop] = useState([]);
  const [companyOwnerName, setCompanyOwnerName] = useState("");
  const [CompanyOwnerDrop, setCompanyOwnerDrop] = useState([]);

  //API
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const IndustryList = async () => {
    const response = await global.$axios.get(`/company/industry/${AppCode}`);
    // console.log("IndustryList", response.data);
    setIndustryDrop(response.data.Message);
  };
  const CountryList = async () => {
    const response = await global.$axios.get(`userinfo/branch/country`);
    setCountryDrop(response.data.message);
    setCountryName(response.data.message[99]);
    StateList(response.data.message[99].CountryId);
  };

  const StateList = async (countryId) => {
    if (!countryId) {
      setStateDrop([]);
      setStateName(null);
      setCityDrop([]);
      setCityName(null);
      setPinCode("");
    } else {
      const response = await global.$axios.get(
        `/userinfo/branch/states/${countryId}`
      );
      setStateDrop(response.data.message);
    }
  };

  const CityList = async (stateId) => {
    if (!stateId) {
      setCityDrop([]);
      setCityName(null);
    } else {
      const response = await global.$axios.get(
        `/userinfo/branch/cities/${stateId}`
      );
      setCityDrop(response.data.message);
    }
  };

  const OwnerList = async () => {
    const response = await global.$axios.get(
      `company/accountuserlist/${AppCode}`
    );
    setCompanyOwnerDrop(response.data.message);
  };

  // customerCode Validate
  const [codeErr, setCodeErr] = useState(0);

  const codevalidation = async (val) => {
    const response = await global.$axios.get(
      `company/validateCcode/${AppCode}/${val}`
    );
    setCodeErr(response.data.Message);
  };

  // companyName Validate

  const [nameErr, setNameErr] = useState(0);

  const Companyvalidation = async (val) => {
    const response = await global.$axios.get(
      `/company/validateCname/${AppCode}/${val}`
    );
    setNameErr(response.data.Message);
  };

  //Mobile Validate
  const MobileVali = (e) => {
    if (contactNumber.length !== 10) {
      setMCount(true);
    } else {
      setMCount(false);
    }
  };

  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };

  // add
  const CompanyAdd = async () => {
    if (mailID != "" && NoValidEmail) {
      toast.error("Please Provide Valid Mail");
      return;
    } else if (contactNumber != "" && contactNumber.length != 10) {
      toast.error("Number Must Be 10 Digits");
      return;
    } else if (codeErr == 1) {
      toast.error("Please Provide Unique Code");
      return;
    } else if (nameErr == 1) {
      toast.error("Company Name Should Be Unique");
      return;
    } else if (
      customerCode &&
      companyName &&
      mailID &&
      address &&
      area &&
      countryName?.CountryId &&
      companyOwnerName?.UserId &&
      cityName?.CityId &&
      stateName?.StateId
    ) {
      const response = await global.$axios.post(`/company/add`, {
        AppCode: AppCode,
        CompanyName: companyName,
        MobileNumber: contactNumber,
        EmailId: mailID,
        Website: webSite,
        AccountStartedDate: `${startDate.$d.getFullYear()}-${startDate.$d.getMonth() + 1 < 10
            ? "0" + (startDate.$d.getMonth() + 1)
            : startDate.$d.getMonth() + 1
          }-${startDate.$d.getDate() < 10
            ? "0" + startDate.$d.getDate()
            : startDate.$d.getDate()
          }`,
        Industry: industryName.CompanyTypeId,
        NoOfEmployee: employeeCount,
        GstNumber: gstNo,
        Country: countryName.CountryId,
        State: stateName.StateId,
        City: cityName.CityId,
        Area: area,
        Pincode: pinCode,
        Address: address,
        Description: comment,
        AccountOwner: companyOwnerName.UserId,
        CustomerCode: customerCode,
        UserId: userId,
      });

      if (response.data.status === 200) {
        toast.success("Company Added Successfully");
        setCustomerCode("");
        setComapnyName(null);
        setAddress("");
        setArea("");
        setCityName("");
        setComment("");
        setGstNo("");
        setContactNumber("");
        setStateName("");
        setCountryName("");
        setIndustryName("");
        setPinCode("");
        setCompanyOwnerName("");
        setStartDate("");
        setWebSite("");
        setEmployeeCount("");
        setMailID("");
        setTimeout(() => {
          Navigate(-1);
        }, 1500);
      }
    } else {
      toast.error("You Missed Required Fields");
    }
  };

  useEffect(() => {
    IndustryList();
    CountryList();
    OwnerList();
  }, []);

  const [priority, setPriority] = useState(
    location.state ? location.state.tab : "Company"
  );
  const Navigate = useNavigate();

  return (
    <>
      <Grid container spacing={0} style={{}}>
        <div>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => {
              Navigate(-1);
            }}
          >
            Companies /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Add {priority}
          </span>
        </div>
        <Grid container spacing={0} xs={12}>
          <Grid item style={{ marginTop: "0px" }}>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={priority}
              onClick={(event) => {
                setPriority(event.target.value);
              }}
            >
              <FormControlLabel
                value="Company"
                control={<Radio />}
                label="Head Office"
                labelPlacement="end"
              />
              <FormControlLabel
                value="Branch"
                control={<Radio />}
                label="Branch"
                labelPlacement="end"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        {priority === "Company" && (
          <>
            <Grid
              container
              xs={12}
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Customer Code *</p>
                <TextField
                  onKeyUp={(e) => {
                    if (customerCode != "") {
                      codevalidation(e.target.value);
                    } else {
                      codevalidation(0);
                    }
                  }}
                  error={codeErr === 1 ? true : false}
                  value={customerCode}
                  onChange={(e) => setCustomerCode(e.target.value)}
                  fullWidth
                  size="small"
                  id="outlined-required"
                  placeholder="Enter customer Code"
                  helperText={
                    codeErr === 1 ? (
                      <span style={{ color: "darkred" }}>Already Exist</span>
                    ) : (
                      <span style={{ color: "transparent" }}>Good</span>
                    )
                  }
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Company Name *</p>

                <TextField
                  onKeyUp={(e) => Companyvalidation(e.target.value)}
                  error={nameErr === 1 ? true : false}
                  value={companyName}
                  onChange={(e) => setComapnyName(e.target.value)}
                  fullWidth
                  placeholder="Enter Company Name"
                  size="small"
                  required
                  id="outlined-required"
                  helperText={
                    nameErr === 1 ? (
                      <span style={{ color: "darkred" }}>Already Exist</span>
                    ) : (
                      <span style={{ color: "transparent" }}>"Good"</span>
                    )
                  }
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Contact Number</p>

                <TextField
                  value={contactNumber}
                  inputProps={{ maxLength: "10" }}
                  onChange={(e) => setContactNumber(e.target.value)}
                  onKeyUp={(e) => {
                    e.target.value !== "" && MobileVali(e.target.value);
                  }}
                  helperText={
                    MCount === true ? (
                      <span style={{ color: "darkred" }}>
                        10 Digits Required
                      </span>
                    ) : (
                      <span style={{ color: "transparent" }}>
                        Already Exist
                      </span>
                    )
                  }
                  fullWidth
                  placeholder="Enter  Contact Number"
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "0px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Mail ID *</p>

                <TextField
                  value={mailID}
                  onChange={(e) => setMailID(e.target.value)}
                  onKeyUp={(e) => {
                    e.target.value != "" && MailValidate(e.target.value);
                  }}
                  helperText={
                    NoValidEmail ? (
                      <span style={{ color: "darkred" }}>Not a Valid Mail</span>
                    ) : (
                      <span style={{ color: "transparent" }}>Good</span>
                    )
                  }
                  fullWidth
                  placeholder="Enter Mail ID"
                  size="small"
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>No of Employee</p>
                <TextField
                  value={employeeCount}
                  onChange={(e) => setEmployeeCount(e.target.value)}
                  fullWidth
                  placeholder="No of Employee"
                  size="small"
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Company Start Date</p>

                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  label={"DD-MM-YYYY"}
                >
                  <DatePicker
                    views={["day", "month", "year"]}
                    value={dayjs(startDate)}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="DD-MM-YYYY"
                    size="small"
                    sx={{
                      width: "100%",
                      ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        { padding: 0 },
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Industry </p>

                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  value={industryName}
                  options={IndustryDrop}
                  getOptionLabel={(option) => option.CompanyTypeName || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.CompanyTypeId}>
                      {option.CompanyTypeName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                      sx={{
                        ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root":
                        {
                          padding: "0px",
                        },
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setIndustryName(newValue);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                {" "}
                <p style={style3.txttitle}>Website</p>
                <TextField
                  fullWidth
                  placeholder="Enter Website"
                  size="small"
                  value={webSite}
                  onChange={(e) => setWebSite(e.target.value)}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                {" "}
                <p style={style3.txttitle}>GST Number</p>
                <TextField
                  fullWidth
                  placeholder="Enter GST NUmber"
                  size="small"
                  value={gstNo}
                  onChange={(e) => setGstNo(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Street & Address *</p>
                <TextField
                  fullWidth
                  placeholder="Enter Street & Address"
                  size="small"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Area *</p>
                <TextField
                  fullWidth
                  placeholder="Enter Area"
                  size="small"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                {" "}
                <p style={style3.txttitle}>Country *</p>
                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={countryName}
                  options={CountryDrop}
                  getOptionLabel={(option) => option.CountryName || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.CountryId}>
                      {option.CountryName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setCountryName(newValue);

                    if (!newValue) {
                      StateList(null);
                    }
                    StateList(newValue.CountryId);
                    setStateName(null);
                    setCityName(null);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                {" "}
                <p style={style3.txttitle}>State *</p>
                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={stateName}
                  options={StateDrop}
                  getOptionLabel={(option) => option.StateName || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.StateId}>
                      {option.StateName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setStateName(newValue);
                    if (!newValue) {
                      CityList(null);
                    }
                    CityList(newValue.StateId);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>City *</p>

                <Autocomplete
                  size="small"
                  id="auto-complete"
                  value={cityName}
                  options={CityDrop}
                  getOptionLabel={(option) => option.CityName || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.CityId}>
                      {option.CityName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setCityName(newValue);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Pincode</p>
                <TextField
                  fullWidth
                  placeholder="Enter Pincode"
                  size="small"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style3.txttitle}>Account Owner *</p>
                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  value={companyOwnerName}
                  options={CompanyOwnerDrop}
                  getOptionLabel={(option) => option.ContactOwner || ""}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.UserId}>
                      {option.ContactOwner}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      variant="outlined"
                      sx={{
                        ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root":
                        {
                          padding: "0px",
                        },
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setCompanyOwnerName(newValue);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12}></Grid>
              <Grid item md={3.5} xs={12}></Grid>
            </Grid>

            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Grid item lg={7.77} xs={12}>
                <p style={style3.txttitle}>Comment</p>
                <Box sx={{}}>
                  <textarea
                    placeholder="Enter the Comment"
                    style={{
                      width: "100%",
                      border: "1px solid gray",
                      background: "#f5f5f5",
                      borderRadius: "10px",
                      padding: "10px",
                      fontSize: "18px",
                      fontWeight: "200",
                      height: "80px",
                    }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px 0px 10px 0px",
                borderTop: "1px solid rgb(0,0,0,0.2)",
                padding: "10px",
              }}
            >
              <Button
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                size="small"
                variant="contained"
                onClick={() => CompanyAdd()}
              >
                Save
              </Button>
            </Grid>
          </>
        )}
        {priority === "Branch" && (
          <>
            <Addmorecompanybranches />
          </>
        )}
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}

export default Addmorecompanys;
const style3 = {
  font1: {
    fontWeight: "700",
    color: "rgba(17, 64, 232, 1)",
    lineHeight: "20px",
    fontSize: "35px",
  },
  font2: {
    fontWeight: "700",
    color: "rgba(17, 64, 232, 1)",
    lineHeight: "20px",
    fontSize: "24px",
  },
  txttitle: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  height: 300,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
