import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import { ToastContainer, toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Dialog, useMediaQuery } from "@material-ui/core";

const UserPopup = ({ refresh, setRefresh }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const UserId = global.$userData.logindata.UserId;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [FristName, setFristName] = useState("");
  const [LastName, setLastName] = useState("");
  const [EmployeeId, setEmployeeId] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [Password, setPassword] = useState("");
  const [Department, setDepartment] = useState("");
  const [SecondaryDepartment, setSecondaryDepartment] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [designation, setDesignation] = useState("");
  const [departmentDrop, setDepartmentDrop] = useState([]);
  const [secondaryDeptDrop, setSecondaryDeptDrop] = useState([]);
  const [BranchDrop, SetBranchDropDrop] = useState([]);
  const [DesignationDrop, SetDesignationDrop] = useState([]);
  const [RoleDrop, setRoleDropDrop] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //Validate
  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };

  //Mobile Validate
  const [mobileerr, setmobileerr] = useState(false);
  const mobile = () => {
    if (MobileNumber.length !== 10) {
      setmobileerr(true);
    } else {
      setmobileerr();
    }
  };

  // Add User
  const user = async () => {
    const SecondaryId = SecondaryDepartment.map((e) => e.DepartmentId);
    if (
      FristName == "" ||
      EmailId == "" ||
      Password == "" ||
      !(Department !== null && Object.values(Department).length !== 0) ||
      !(designation !== null && Object.values(designation).length !== 0) ||
      !(roleId !== null && Object.values(roleId).length !== 0)
    ) {
      toast.error("Please Fill Required Fields");
      return;
    } else if (MobileNumber.length !== 10) {
      toast.error("Mobile Number Must be 10 Digit");
      return;
    } else if (EmailId != "" && NoValidEmail) {
      toast.error("Please Provide Valid Mail");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        UserFirstName: FristName,
        UserLastName: LastName,
        EmployeeId: EmployeeId,
        UserMobileNumber: MobileNumber,
        UserEmailId: EmailId,
        Password: Password,
        Department: Department?.DepartmentId,
        SecondaryDepartment: SecondaryId,
        BranchId: branchId?.BranchId,
        Designation: designation?.DesignationId,
        RoleId: roleId?.RoleId,
        CreatedBy: UserId,
      };
      const response = await global.$axios
        .post("userInfo/add", data)
        .then((response) => {
          toast.success("User Added");
          setRefresh(!refresh);
          setTimeout(() => {
            setOpen(false);
          }, 500);
          setFristName("");
          setLastName("");
          setMobileNumber("");
          setEmailId("");
          setSecondaryDepartment([]);
          setDepartment([]);
          setPassword([]);
          setDesignation("");
          setRoleId("");
          setBranchId("");
        })
        .catch((e) => {
          console.error("useradd", e);
        });
    }
  };

  const department = async () => {
    const response = await global.$axios
      .get(`userinfo/dept/${AppCode}`)
      .then(async (response) => {
        setDepartmentDrop(response.data.Message);
      })
      .catch((e) => {
        console.error("userdepartementpop", e);
      });
  };
  const Secondarydepartment = async (id) => {
    let data2 = {
      DepartmentId: id,
    };
    const response = await global.$axios
      .put(`userinfo/secdept/${AppCode}`, data2)
      .then(async (response) => {
        setSecondaryDeptDrop(response.data.message);
      })
      .catch((e) => {
        console.error("usersecondarydepartment", e);
      });
  };
  const Branchdrop = async () => {
    const response = await global.$axios
      .get(`userinfo/Branchlist/${AppCode}`)
      .then(async (response) => {
        SetBranchDropDrop(response?.data?.Message);
      })
      .catch((e) => {
        console.error("userbranddrop", e);
      });
  };
  const Desiginationdrop = async () => {
    const response = await global.$axios
      .get(`userinfo/Desingnationlist/${AppCode}`)
      .then(async (response) => {
        SetDesignationDrop(response?.data?.Message);
      })
      .catch((e) => {
        console.error("userdesignationpopup", e);
      });
  };
  const Roledrop = async () => {
    const response = await global.$axios
      .get(`userinfo/roledrop/${AppCode}`)
      .then(async (response) => {
        setRoleDropDrop(response?.data?.Message);
      })
      .catch((e) => {
        console.error("userroledrop", e);
      });
  };

  // emailid Validate
  const [emailErr, setEmailErr] = useState(0);

  const emailvalidation = async (val) => {

    const response = await global.$axios
      .get(`userinfo/validatemailid/${AppCode}/${val}`)
      .then(async (response) => {
        setEmailErr(response.data.Message);
      })
      .catch((e) => {
        console.error("useremailvalidation", e);
      });
  };

  // ContactNumber validation

  const [numberErr, setNumberErr] = useState(0);

  const contactValidation = async (val) => {
    const response = await global.$axios
      .get(`userinfo/validatemn/${AppCode}/${val}`)
      .then(async (response) => {
        setNumberErr(response.data.Message);
      })
      .catch((e) => {
        console.error("usercontactvalidation", e);
      });
  };

  useEffect(() => {
    department();
    Branchdrop();
    Desiginationdrop();
    Roledrop();
  }, []);

  return (
    <div>
      <Button
        style={{
          maxHeight: "22px",
          minHeight: "22px",
          textTransform: "none",
          padding: "12px",
        }}
        size="small"
        variant="contained"
        onClick={handleOpen}
      >
        + Add Users
      </Button>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0px 10px 0px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Add users
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Grid
              container
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}> First Name *</p>
                <TextField
                  placeholder="Enter First Name"
                  id="First Name"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setFristName(e.target.value)}
                  value={FristName}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Last Name </p>
                <TextField
                  placeholder="Enter Last Name"
                  id="Last Name"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setLastName(e.target.value)}
                  value={LastName}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Employee Id </p>
                <TextField
                  placeholder="Enter Employee Id"
                  id="Employee Id"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  value={EmployeeId}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Mobile Number* </p>
                <TextField
                  placeholder="Enter Mobile Number"
                  id="Mobile Number"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: "10" }}
                  style={{ width: "100%" }}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  value={MobileNumber}
                  onKeyUp={(e) => {
                    e.target.value !== "" && contactValidation(e.target.value);
                    mobile(e.target.value);
                  }}
                  helperText={
                    mobileerr === true ? (
                      <span style={{ color: "darkred" }}>
                        10 Digit Required
                      </span>
                    ) : numberErr === 1 ? (
                      <span style={{ color: "darkred" }}>Already Exist</span>
                    ) : (
                      <span style={{ color: "transparent" }}>"help"</span>
                    )
                  }
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Email Id *</p>
                <TextField
                  type="EmailId"
                  placeholder="Enter Email Id"
                  id="Email Id"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setEmailId(e.target.value)}
                  error={emailErr === 1 ? true : false}
                  value={EmailId}
                  onKeyUp={(e) => {
                    e.target.value !== "" && emailvalidation(e.target.value);
                    MailValidate(e.target.value);
                  }}
                  helperText={
                    emailErr === 1 ? (
                      <span style={{ color: "darkred" }}>Already Exist</span>
                    ) : NoValidEmail ? (
                      <span style={{ color: "darkred" }}>Not A Valid Mail</span>
                    ) : (
                      <span style={{ color: "transparent" }}>Good</span>
                    )
                  }
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Password *</p>
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={Password}
                    placeholder="Enter The Password"
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Department *</p>
                <Autocomplete
                  id="Departement"
                  size="small"
                  options={departmentDrop}
                  value={Department}
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDepartment(newValue);
                    Secondarydepartment(newValue.DepartmentId);
                    setSecondaryDepartment([]);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}> Secondary Department </p>
                <Autocomplete
                  multiple
                  size="small"
                  id="Secondary departement"
                  options={secondaryDeptDrop}
                  value={SecondaryDepartment}
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSecondaryDepartment(newValue);
                  }}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Branch </p>
                <Autocomplete
                  id="Branch"
                  size="small"
                  options={BranchDrop}
                  value={branchId}
                  getOptionLabel={(option) => option.BranchName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setBranchId(newValue);
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Designation *</p>
                <Autocomplete
                  id="Designation"
                  size="small"
                  options={DesignationDrop}
                  value={designation}
                  getOptionLabel={(option) => option.DesignationName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDesignation(newValue);
                  }}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Role *</p>

                <Autocomplete
                  id="Role"
                  size="small"
                  options={RoleDrop}
                  value={roleId}
                  getOptionLabel={(option) => option.RoleName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setRoleId(newValue);
                  }}
                />
              </Grid>
              <Grid item md={3.5} xs={12}></Grid>
            </Grid>
          </Grid>
          <center style={{ marginTop: "20px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={
                NoValidEmail || mobileerr || numberErr || emailErr
                  ? true
                  : false
              }
              onClick={() => {
                user();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default UserPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
const style5 = {
  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "5px 0px 5px 0px",
  },
};
