import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";

const EditTicketSourcePopup = ({
  data,
  EditPopupOpen,
  setEditPopupOpen,
  active,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //pop up
  const handleOpen = () => setEditPopupOpen(true);
  const handleClose = () => setEditPopupOpen(false);

  const [age, setAge] = React.useState("");
  const [sourceName, setSourceName] = useState(data.TicketSourceName);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // validation edit

  const [err, seterr] = useState(0);

  const validation = async (val) => {
    const response = await global.$axios
      .get(`source/validatesource/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("ticketsourceedit validation", e);
      });
  };

  const ticketSource = async () => {
    // source/edit/id
    if (sourceName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data1 = {
        AppCode: AppCode,
        TicketSourceName: sourceName,
        UserId: userId,
      };
      const response = await global.$axios
        .put(`source/edit/${data.TicketSourceId}`, data1)
        .then(async (response) => {
          if (response.data.status === 200) {
            handleClose();
            setSourceName("");
            toast.success("TicketSource Edited");
            active();

            // console.log("resview", response.data);
          }
        })
        .catch((e) => {
          console.error("editticketsource", e);
        });
    }
  };
  return (
    <div>
      <Modal
        open={EditPopupOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 20px 0px",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              {" "}
              Edit Ticket Source
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <TextField
              // required
              value={sourceName}
              onChange={(e) => setSourceName(e.target.value)}
              defaultValue={data.TicketSourceName}
              sx={{ minWidth: 330 }}
              placeholder="Add Source Here"
              id="outlined-size-small"
              size="small"
              onKeyUp={(e) => {
                e.target.value !== "" && validation(e.target.value);
              }}
              error={
                err === 1 && data.TicketSourceName != sourceName ? true : false
              }
              helperText={
                err === 1 && data.TicketSourceName != sourceName ? (
                  <span style={{ color: "red" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>""</span>
                )
              }
            />
          </Grid>

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                ticketSource();
              }}
            >
              Update
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default EditTicketSourcePopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
