import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast, ToastContainer } from "react-toastify";
import { Dialog, useMediaQuery } from "@material-ui/core";

const TicketPopup = ({ Refresh, setRefresh, TicketOpen, setTicketOpen }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const handleClose = () => setTicketOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [department, setDepartment] = React.useState("");
  const [departDrop, setDepartDrop] = useState([]);
  const [ticket, setTicket] = useState(null);
  const [DropTicket, setDropTIcket] = useState([]);
  const [ticketName, setTicketName] = useState("");
  const [value, setvalue] = useState("");

  const etctime =
    value !== ""
      ? value.$d.getHours() +
      ":" +
      value.$d.getMinutes() +
      ":" +
      value.$d.getSeconds()
      : "";
  let etc = etctime.split(" ");
  let finalclose = "";
  if (etc.length === 2 && etc[1] == "PM") {
    const settime = etc[0].split(":");
    if (settime[0] != 12) {
      finalclose =
        parseInt(settime[0]) +
        parseInt(12) +
        ":" +
        settime[1] +
        ":" +
        settime[2];
    } else {
      finalclose = parseInt(settime[0]) + ":" + settime[1] + ":" + settime[2];
    }
  } else {
    finalclose = etc[0];
  }

  const ticketAdd = async () => {
    if (
      department === "" ||
      ticket === "" ||
      ticketName === "" ||
      etctime === ""
    ) {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        DepartmentId: department.DepartmentId,
        CategoryId: ticket.IssueCategoryId,
        IssueName: ticketName,
        ETC: finalclose,
        UserId: userId,
      };
      const response = await global.$axios
        .post("issue/add", data)
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Ticket Added");
            handleClose();
            setRefresh(!Refresh);
            setTicketName("");
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("ticketpopup", e);
        });
    }
  };

  const DepartmentDrop = async () => {
    const dropdownResponse = await global.$axios
      .get(`department/getdept/${AppCode}`)
      .then(async (dropdownResponse) => {
        setDepartDrop(dropdownResponse.data.Message);
      })
      .catch((e) => {
        console.error("ticketdepartementdrop", e);
      });
  };

  const TicketDrop = async (id) => {
    const dropdownResponse = await global.$axios
      .get(`issue/departmentwise/${AppCode}/${id}`)
      .then(async (dropdownResponse) => {
        setDropTIcket(dropdownResponse.data.Message);
      })
      .catch((e) => {
        console.error("ticketdrop", e);
      });
  };

  useEffect(() => {
    DepartmentDrop();
  }, []);
  const [err, seterr] = useState(0);
  const validation = async (val) => {
    const response = await global.$axios
      .get(`/issue/validateticket/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("ticketvaludiation", e);
      });
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
        open={TicketOpen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              Add Ticket{" "}
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "8px 0px 5px 0px",
            }}
          >
            <Grid item md={5.9} xs={12} style={{ marginBottom: "10px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px  ",
                  paddingBottom: "5px",
                }}
              >
                Department *
              </p>

              <Autocomplete
                fullWidth
                size="small"
                id="Designation"
                options={departDrop}
                getOptionLabel={(option) => option.DepartmentName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category "
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) => {
                  setDepartment(newValue);
                  TicketDrop(newValue.DepartmentId);
                  setTicket(null);
                }}
              />
            </Grid>

            <Grid item md={5.9} xs={12}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px  ",
                  paddingBottom: "5px",
                }}
              >
                Ticket Category *
              </p>
              <Autocomplete
                fullWidth
                size="small"
                id="Designation"
                value={ticket}
                options={DropTicket}
                getOptionLabel={(option) => option.IssueCategroyName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category "
                    variant="outlined"
                  />
                )}
                onChange={(event, newValue) => {
                  setTicket(newValue);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0px 0px 20px 0px",
            }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0px 0px 0px 0px",
                }}
              >
                Ticket*
              </p>
              <TextField
                value={ticketName}
                fullWidth
                onChange={(e) => setTicketName(e.target.value)}
                required
                onKeyUp={(e) => {
                  e.target.value !== "" && validation(e.target.value);
                }}
                error={err === 1 ? true : false}
                placeholder="Add Ticket Here"
                id="outlined-size-small"
                size="small"
                helperText={
                  err === 1 ? (
                    <span style={{ color: "red" }}>Already Exist</span>
                  ) : (
                    <span style={{ color: "transparent" }}>iuftghj</span>
                  )
                }
              />
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "-30px",
            }}
          >
            <p style={{ margin: "10px 0px 0px 0px", fontSize: "14px" }}>ETA*</p>
            <Grid
              xs={12}
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Grid item md={5.9} xs={12} style={{}}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  label={"hh : mm "}
                >
                  <MobileTimePicker
                    ampm={false}
                    // ampmInClock={false}
                    slotProps={{ textField: { size: "small" } }}
                    value={value}
                    onChange={(newValue) => {
                      setvalue(newValue);
                    }}
                    sx={{
                      p: 0,
                      ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px",
                      },
                    }}
                    views={["hours", "minutes"]}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>

          <center style={{ marginTop: "20px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                ticketAdd();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Dialog>
      <ToastContainer
        position="topright"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default TicketPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  },
};
