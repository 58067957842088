import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Edituserpopup from "../PopupScreens/EditPopupscreeen/Edituserpopup";

// import EditUserPopup from '../PopupScreens/EditPopupscreeen/EditUserPopup';
export default function UseractiveTable({ refresh, setRefresh }) {
  const [UserEditPopupOpen, setUserEditPopupOpen] = useState(false);
  const [EditUserId, setEditUserId] = useState(null);
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;

  //table
  const active = async () => {
    const response = await global.$axios
      .get(`userInfo/activelist/${AppCode}`)
      .then(async (response) => {
        setUserSearch([...response.data.Message]);
        setUserRow([...response.data.Message]);
      })
      .catch((e) => {
        console.error("useractivetable", e);
      });
  };

  //restore
  const restore = async (Forid, value, Id) => {
    const data1 = {
      Forid: Forid,
      Value: value,
    };
    const response = await global.$axios
      .put(`userinfo/changestatus/${AppCode}/${Id}`, data1)
      .then(async (response) => {
        active();
      })
      .catch((e) => {
        console.error("restore", e);
      });
  };

  //AdminAccess
  const AdminAccess = async (Forid, Value, Id) => {
    const data1 = {
      Forid: Forid,
      Value: Value,
    };
    const response = await global.$axios
      .put(`userinfo/accessadmin/${AppCode}/${Id}`, data1)
      .then(async (response) => {
      })
      .catch((e) => {
        console.error("useradminaccess", e);
      });
  };

  //Search
  const [UserRow, setUserRow] = useState([]);
  const [UserSearch, setUserSearch] = useState([]);

  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = UserRow.filter((item) => {
        const UserId = String(item?.UserId);
        const UserName = String(item?.UserName);
        const departments = String(item?.departments?.DepartmentName);
        const AccessWebAppTicket = String(item?.AccessWebAppTicket);
        const AccessMobileAppTicket = String(item?.AccessMobileAppTicket);
        const AccessAdminTicket = String(item?.AccessAdminTicket);
        const UserEmailId = String(item?.UserEmailId);
        const BranchName = String(item?.branch_master?.BranchName);

        const text_data = term.toLowerCase();
        return (
          UserId.indexOf(text_data) > -1 ||
          UserName.toLowerCase().indexOf(text_data) > -1 ||
          departments.toLowerCase().indexOf(text_data) > -1 ||
          AccessWebAppTicket.toLowerCase().indexOf(text_data) > -1 ||
          AccessMobileAppTicket.toLowerCase().indexOf(text_data) > -1 ||
          AccessAdminTicket.toLowerCase().indexOf(text_data) > -1 ||
          UserEmailId.toLowerCase().indexOf(text_data) > -1 ||
          BranchName.toLowerCase().indexOf(text_data) > -1
        );
      });

      setUserSearch(ticketData);
    } else {
      setUserSearch(UserRow);
    }
  }

  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "UserId",
      sort: "desc",
    },
  ]);

  //companyaccess
  const companyapi = async (Forid, Value, id) => {
    const response = await global.$axios
      .put(`userinfo/companiesaccess/${AppCode}/${id}`, {
        Forid: Forid,
        Value: Value,
      })
      .then(async (response) => {
      })
      .catch((e) => {
        console.error("companyapi", e);
      });
  };

  const columns = [
    {
      field: "UserId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 90,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        // console.log(cellValues.id, "showw");
        return (
          <EditIcon
            onClick={() => {
              setEditUserId(cellValues.id);
              setUserEditPopupOpen(true);
            }}
            style={{
              width: "23px",
              height: "23px",
              cursor: "pointer",
              padding: "2px",
            }}
          />
        );
      },
    },
    {
      field: "UserName",
      headerClassName: "super-app-theme--header",
      headerName: "User Name",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "departments",
      headerName: "Department",
      headerClassName: "super-app-theme--header",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) => fields?.row?.departments?.DepartmentName,
    },
    {
      field: "AccessWebApp",
      headerClassName: "super-app-theme--header",
      headerName: "Web-App Access",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return WebAccessradio(cellValues);
      },
    },
    {
      field: "AccessMobileApp",
      headerClassName: "super-app-theme--header",
      headerName: "Mobile-App Access",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return MobileAccessradio(cellValues);
      },
    },
    {
      field: "AccessAdmin",
      headerName: "Admin Access",
      headerClassName: "super-app-theme--header",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        // console.log(cellValues, "showw");
        return AdminAccessradio(cellValues);
      },
    },
    {
      field: "None",
      headerName: "Company Access",
      headerClassName: "super-app-theme--header",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        // console.log(cellValues, "showw");
        return CompanyAccessradio(cellValues);
      },
    },
    {
      field: "UserEmailId",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 210,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "BranchName",
      headerName: "Branch",
      headerClassName: "super-app-theme--header",
      width: 130,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) => fields.row?.branch_master?.BranchName || "",
    },
  ];
  function WebAccessradio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues?.row?.AccessWebAppTicket}
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="Yes"
            size="small"
            labelPlacement="end"
            onClick={() => restore(1, "Yes", cellValues?.row?.UserId)}
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="No"
            labelPlacement="end"
            onClick={() => restore(1, "No", cellValues?.row?.UserId)}
          />
        </RadioGroup>
      </Grid>
    );
  }
  function CompanyAccessradio(cellValues) {
    // console.log(cellValues.row.TicketCompaniesAccess, "cellvalues");
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues.row.TicketCompaniesAccess}
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="1"
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="All"
            size="small"
            labelPlacement="end"
            onClick={() => companyapi(1, 1, cellValues?.row?.UserId)}
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="2"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="Self"
            labelPlacement="end"
            onClick={() => companyapi(2, 2, cellValues?.row?.UserId)}
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="3"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="None"
            labelPlacement="end"
            onClick={() => companyapi(3, 3, cellValues?.row?.UserId)}
          />
        </RadioGroup>
      </Grid>
    );
  }

  function MobileAccessradio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues?.row?.AccessMobileAppTicket}
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="Yes"
            size="small"
            labelPlacement="end"
            onClick={() => restore(2, "Yes", cellValues?.row?.UserId)}
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="No"
            labelPlacement="end"
            onClick={() => restore(2, "No", cellValues?.row?.UserId)}
          />
        </RadioGroup>
      </Grid>
    );
  }
  function AdminAccessradio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues?.row?.AccessAdminTicket}
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            onClick={() => AdminAccess(1, "Yes", cellValues?.row?.UserId)}
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="Yes"
            size="small"
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            onClick={() => AdminAccess(1, "No", cellValues?.row?.UserId)}
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    );
  }

  useEffect(() => {
    active();
  }, [refresh]);

  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            margin: "10px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>

      <div style={{ height: "480", width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            width: "100%",
            height: "70vh",
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...UserRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={UserSearch}
          getRowId={(row) => row.UserId}
          rowHeight={38}
          columns={columns}
          pageSize={10}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        //   checkboxSelection
        />
        {UserEditPopupOpen && (
          <Edituserpopup
            active={active}
            data={EditUserId}
            UserEditPopupOpen={UserEditPopupOpen}
            setUserEditPopupOpen={setUserEditPopupOpen}
          />
        )}
      </div>
    </>
  );
}
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
