import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

export default function ProductPopup({ settab }) {
  const AppCode = global.$userData.logindata.AppCode;
  const user = global.$userData.logindata.UserId;
  //dropdown
  const [catagory, setcatagory] = useState([]);
  const [subcatagory, setsubcatagory] = useState([]);
  const [unit, setunit] = useState([]);
  // console.log(unit, "unit");
  const [brand, setbrand] = useState([]);
  // console.log("brand", brand);

  //setdatas
  const [Product, setProduct] = useState("");
  const [catagoryvalue, setcatagoryvalue] = useState("");
  // console.log(catagoryvalue, "catagoryvalue");
  const [subcatagoryvalue, setsubcatagoryvalue] = useState("");
  const [brandvalue, setbrandvalue] = useState("");
  const [lable, setlable] = useState("");
  const [code, setcode] = useState("");
  const [specfication, setspecfication] = useState("");
  const [price, setprice] = useState("");
  const [unitvalue, setunitvalue] = useState(null);
  // console.log(unitvalue, "unitvalue");
  const [tax, settax] = useState("");
  const [hsn, sethsn] = useState("");
  const [discount, setdiscount] = useState("");
  const [productcolour, setproductcolour] = useState("");
  const [descripition, setdescripition] = useState("");
  const [RecordId, setRecordId] = useState("");

  var d = 1;
  function getTimestampInSeconds() {
    d++;
    setRecordId(Math.floor(Date.now() / 1000) + AppCode + d);
  }

  const productadd = async () => {
    if (
      Product === "" ||
      catagoryvalue === "" ||
      catagoryvalue === null ||
      subcatagoryvalue === "" ||
      subcatagoryvalue === null ||
      code === "" ||
      tax === ""
    ) {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data1 = {
        AppCode: AppCode,
        ProductName: Product,
        ProductCategoryId: catagoryvalue.CategoryId,
        ProductSubCategoryId: subcatagoryvalue.SubCategoryId,
        BrandName: brandvalue?.BrandId ? brandvalue?.BrandId : "",
        ProductLabel: lable,
        ProductCode: code,
        ProductColor: productcolour,
        ProductSpecification: specfication,
        Price: price,
        Unit: unitvalue?.UnitId ? unitvalue?.UnitId : "",
        TaxPercent: tax,
        HSN: hsn,
        Discount: discount,
        BrochureFile: "",
        Description: descripition,
        UserId: user,
        RecordId: RecordId,
      };
      const response = await global.$axios
        .post(`product/add`, data1)
        .then(async (response) => {
          if (response.data.status == 200) {
            toast.success("Product Added  Successfully");
            setTimeout(() => {
              settab("Product");
            }, 1000);
          } else {
            toast.error("something went wrong");
          }
        })
        .catch((e) => {
          console.error("productadd", e);
        });
    }
  };

  const catagorydrop = async () => {
    const response = await global.$axios
      .get(`subcategory/categorylist/${AppCode}`)
      .then(async (response) => {
        setcatagory(response.data.message);
      })
      .catch((e) => {
        console.error("productcatagorydrop", e);
      });
  };
  const subcatagorydrop = async (id) => {
    let data = {
      CategoryId: id,
    };
    const response = await global.$axios
      .put(`product/getsubcategory/${AppCode}`, data)
      .then(async (response) => {
        setsubcatagory(response.data.message);
      })
      .catch((e) => {
        console.error("productsubdrop", e);
      });
  };
  const unitdrop = async () => {
    const response = await global.$axios
      .get(
        // product/unitlist/:AppCode
        `product/unitlist/${AppCode}`
      )
      .then(async (response) => {
        setunit(response.data.message);
      })
      .catch((e) => {
        console.error("productunitdrop", e);
      });
  };
  const brandDrop = async () => {
    const response = await global.$axios
      .get(
        // product/brandlist/:AppCode
        `product/brandlist/${AppCode}`
      )
      .then(async (response) => {
        setbrand(response.data.message);
      })
      .catch((e) => {
        console.error("productbranddrop", e);
      });
  };
  useEffect(() => {
    catagorydrop();
    unitdrop();
    brandDrop();
    getTimestampInSeconds();
  }, []);
  const [err, seterr] = useState(0);
  const [err2, seterr2] = useState(0);

  const validation = async (val) => {
    // /product/validateproductname/:AppCode/:ProductName
    const response = await global.$axios
      .get(`/product/validateproductname/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("producvaidation", e);
      });
  };
  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => settab("Product")}
          >
            Product /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Add Product
          </span>
        </div>
      </Grid>

      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid container md={5.5} xs={12}>
          <p style={style5.title}> Product Name *</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Product Name"
              id="outlined-required"
              variant="outlined"
              size="small"
              onKeyUp={(e) => validation(e.target.value)}
              error={err === 1 ? true : false}
              style={{ width: "100%" }}
              onChange={(e) => setProduct(e.target.value)}
              helperText={
                err === 1 ? (
                  <span style={{ color: "red" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>iuftghj</span>
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Category *</p>
          <Grid item xs={12}>
            <Autocomplete
              // id="clear-on-escape"
              options={catagory}
              size="small"
              value={catagoryvalue}
              getOptionLabel={(option) => option.ProductCategoryName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  defaultValue={catagoryvalue}
                  placeholder="Select Category "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                // console.log(newValue, "newValue");
                setcatagoryvalue(newValue);
                subcatagorydrop(newValue.CategoryId);
                setsubcatagoryvalue("");
              }}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 500,
              margin: "0px 0px 10px 0px",
            }}
          >
            Sub Category *
          </p>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              id="sub category"
              options={subcatagory}
              value={subcatagoryvalue}
              getOptionLabel={(option) => option.SubcategoryName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category "
                  error={err2 === 1 ? true : false}
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setsubcatagoryvalue(newValue);
              }}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 500,
              margin: "0px 0px 10px 0px",
            }}
          >
            Brand Name
          </p>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              // id="clear-on-escape"
              options={brand}
              value={brandvalue}
              getOptionLabel={(option) => option.BrandName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category "
                  error={err2 === 1 ? true : false}
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setbrandvalue(newValue);
              }}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>label</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter label Name"
              id="outlined-required"
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              onChange={(e) => setlable(e.target.value)}
              value={lable}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Code *</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Code"
              id="outlined-required"
              variant="outlined"
              error={err2 === 1 ? true : false}
              size="small"
              style={{ width: "100%" }}
              onChange={(e) => setcode(e.target.value)}
              value={code}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Specification</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Specification"
              id="outlined-required"
              variant="outlined"
              size="small"
              onChange={(e) => setspecfication(e.target.value)}
              style={{ width: "100%" }}
              value={specfication}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Price</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Price Name"
              id="outlined-required"
              variant="outlined"
              size="small"
              onChange={(e) => setprice(e.target.value)}
              style={{ width: "100%" }}
              value={price}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>UNIT</p>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              // id="clear-on-escape"
              value={unitvalue}
              options={unit}
              getOptionLabel={(option) => option.UnitName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setunitvalue(newValue);
              }}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Tax % *</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Tax "
              id="outlined-required"
              variant="outlined"
              size="small"
              error={err2 === 1 ? true : false}
              onChange={(e) => settax(e.target.value)}
              style={{ width: "100%" }}
              value={tax}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>HSN</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Hsn"
              id="outlined-required"
              variant="outlined"
              size="small"
              onChange={(e) => sethsn(e.target.value)}
              style={{ width: "100%" }}
              value={hsn}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Discount</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Discount"
              id="outlined-required"
              variant="outlined"
              size="small"
              onChange={(e) => setdiscount(e.target.value)}
              style={{ width: "100%" }}
              value={discount}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Product Color Name</p>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter Product Color Name"
              id="outlined-required"
              variant="outlined"
              size="small"
              onChange={(e) => setproductcolour(e.target.value)}
              style={{ width: "100%" }}
              value={productcolour}
            />
          </Grid>
        </Grid>

        <Grid container md={5.5} xs={12}>
          <p style={style5.title}>Image Upload</p>
          <Grid item xs={12}>
            <FormControl
              sx={{
                m: 0,
                width: "100%",
                border: "1px solid #C2C2C2",
                padding: "5px",
                borderRadius: "5px",
              }}
              size="small"
            >
              <input type="file" accept="text" id="file"></input>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container md={10} xs={12}>
          <p style={style5.title}>Description</p>
          <Grid item xs={12}>
            <textarea
              placeholder="Enter the Comment"
              onChange={(e) => setdescripition(e.target.value)}
              style={{
                width: "100%",
                border: "1px solid gray",
                background: "#f5f5f5",
                borderRadius: "5px",
                padding: "10px",
                fontWeight: "200",
                height: "80px",
                outline: "none",
              }}
            ></textarea>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
            margin: "10px 0px",
            borderTop: "1px solid rgb(0,0,0,0.1)",
          }}
        >
          <Button
            size="small"
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "10px 20px",
            }}
            disabled={err === 1 ? true : false}
            onClick={() => {
              productadd();
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}
const style5 = {
  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0px 10px 0px",
  },
};
