import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  // CloseIcon,
  CompletedIcon,
  HoldIcon,
  NewIcon,
  ProgressIcon,
  RightArrowIcon,
  SolvedIcon,
  // TicketTableIcon,
} from "../Assets/Icon";
import UnassignedTable from "./Team Components/UnassignedTable";
import { useNavigate } from "react-router-dom";
import TNewTable from "./Team Components/TNewTable";
import TAll from "./Team Components/TAll";
import TProgressTable from "./Team Components/TProgressTable";
import TCompletedTable from "./Team Components/TCompletedTable";
import TSolvedTable from "./Team Components/TSolvedTable";
import THoldTable from "./Team Components/THoldTable";
import { ToastContainer } from "react-toastify";

export default function TeamTickets() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const DepartmentId = global?.$userData?.logindata?.TicketDepartment;

  const [tab, setTab] = useState("assign");

  const [table, setTable] = useState("");
  const Navigate = useNavigate();

  //dropdown values
  const [DepartmentDrop, SetDepartmentDrop] = useState([]);
  const [UserDrop, SetUserDrop] = useState([]);
  const alluser = [{ UserName: "All", UserId: "All" }];
  //values
  var Tabletab = JSON.parse(localStorage.getItem("TAb"));
  // console.log(Tabletab, "Tabletab");
  var Depart = localStorage.getItem("Team Depart");

  const [DepartmentValue, SetDepartmentValue] = useState([]);

  var Use = localStorage.getItem("Team User");

  const Use1 = JSON.parse(Use);

  const [UserValue, SetUserValue] = useState(
    Use !== null
      ? JSON.parse(Use)
      : {
          UserName: "All",
          UserId: "All",
        }
  );

  const [ShareId, SetShareId] = useState([]);
  const [countTicket, SetcountTicket] = useState("");
  const [SlaCount, SetSlaCount] = useState("");

  //api
  const DepartementList = async () => {
    const response = await global.$axios
      .get(`/ticket/team/departmenthead/${AppCode}/${userId}`)
      .then(async (response) => {
        if (response.data.Status == 200) {
          SetDepartmentDrop(response.data.Message);
          SetDepartmentValue(
            JSON.parse(Depart) == null
              ? response.data.Message[0]
              : JSON.parse(Depart)
          );
          await UserApi(
            JSON.parse(Depart) == null
              ? response.data.Message[0]
              : JSON.parse(Depart)
          );
        } else {
          SetDepartmentDrop([]);
          SetDepartmentValue([]);
        }
      })
      .catch((e) => {
        console.error("Team Department Issue", e);
      });
  };
  const UserApi = async (DeptId) => {
    const response = await global.$axios
      .put(`/ticket/team/user/${AppCode}`, {
        DepartmentId: [DeptId?.DepartmentId],
      })
      .then(async (response) => {
        if (response.data.Status === 200) {
          SetUserDrop([...alluser, ...response.data.Message]);
          SetShareId(
            Use == null || Use1?.UserId == "All"
              ? response.data?.Message?.map((e) => e.UserId)
              : [Use1.UserId]
          );
          Tickecount(
            Use == null || Use1?.UserId == "All"
              ? {
                  user: response.data.Message.map((e) => e.UserId),
                  Depat: [DeptId?.DepartmentId],
                }
              : {
                  user: [Use1.UserId],
                  Depat: [DeptId?.DepartmentId],
                }
          );
          Slacount(
            Use == null || Use1?.UserId == "All"
              ? {
                  user: response.data.Message.map((e) => e.UserId),
                  Depat: [DeptId?.DepartmentId],
                }
              : {
                  user: [Use1.UserId],
                  Depat: [DeptId?.DepartmentId],
                }
          );
        }
      })
      .catch((e) => {
        console.error("User API Issue", e);
      });
  };
  const Tickecount = async (id, depart) => {
    const data = {
      AppCode: AppCode,
      DepartmentId: id.Depat,
      UserId: id.user,
    };
    const response = await global.$axios
      .post(`/ticket/team/count`, data)

      .then(async (response) => {
        if (response.data.Status === 200) {
          SetcountTicket(response.data.msg);
        }
      })
      .catch((e) => {
        console.error("Team Ticket Count", e);
      });
  };

  const Slacount = async (id) => {
    const data = {
      AppCode: AppCode,
      DepartmentId: id?.Depat,
      UserId: id?.user,
    };
    const response = await global.$axios
      .post(`/ticket/team/SLAcount`, data)
      .then(async (response) => {
        if (response.data.Status === 200) {
          SetSlaCount(response.data.msg);
        }
      })
      .catch((e) => {
        console.error("SLA Count Issue", e);
      });
  };
  useEffect(() => {
    setTable(
      localStorage.getItem("Team Tab") == null
        ? "All"
        : localStorage.getItem("Team Tab")
    );
    DepartementList();
  }, []);
  return (
    <div>
      <Grid
        container
        style={{
          margin: "0px 0px 0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("Team Tab", "All");
              tab == "assign" ? setTable("All") : setTab("assign");
            }}
          >
            Team Ticket
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            {tab === "assign" ? table : "Unassigned Tickets"}
          </span>
        </div>
        <div>
          <Button
            onClick={() => Navigate("/app/AddTicket")}
            style={{
              textTransform: "none",
              maxHeight: "22px",
              minHeight: "22px",
              padding: "12px",
              fontSize: "12px",
            }}
            variant="contained"
          >
            + Add New Ticket
          </Button>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Grid
          container
          item
          md={5}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item md={5.5} xs={12} style={{ marginBottom: "5px" }}>
            <Autocomplete
              id="combo-box-demo"
              options={DepartmentDrop}
              value={DepartmentValue}
              disableClearable
              isOptionEqualToValue={(option, value) =>
                option.DepartmentName == value.DepartmentName
              }
              getOptionLabel={(option) => option.DepartmentName || ""}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label=" Department" />
              )}
              onChange={(event, newvalue) => {
                localStorage.setItem("Team Depart", JSON.stringify(newvalue));
                SetDepartmentValue(newvalue);
                SetUserValue({ UserName: "All", UserId: "All" });
                UserApi(newvalue);
              }}
            />
          </Grid>
          {tab == "assign" && (
            <Grid item md={5.5} xs={12} style={{ marginBottom: "5px" }}>
              <Autocomplete
                id="combo-box-demo"
                options={UserDrop}
                value={UserValue}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.UserName == value.UserName
                }
                getOptionLabel={(option) => option.UserName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Users" />
                )}
                onChange={(event, newvalue) => {
                  localStorage.setItem("Team User", JSON.stringify(newvalue));
                  if (newvalue.UserId == "All") {
                    SetShareId(UserDrop.map((e) => e.UserId));
                    SetUserValue({ UserName: "All", UserId: "All" });

                    Tickecount({
                      user: UserDrop.map((e) => e.UserId),
                      Depat: [DepartmentValue?.DepartmentId],
                    });
                    Slacount({
                      user: UserDrop.map((e) => e.UserId),
                      Depat: [DepartmentValue?.DepartmentId],
                    });
                  } else {
                    SetUserValue(newvalue);
                    SetShareId([newvalue.UserId]);
                    Tickecount({
                      user: [newvalue.UserId],
                      Depat: [DepartmentValue?.DepartmentId],
                    });
                    Slacount({
                      user: [newvalue.UserId],
                      Depat: [DepartmentValue?.DepartmentId],
                    });
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          md={7}
          xs={12}
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "end",
            margin: "0px 0px 0px 0px",
          }}
        >
          <Button
            onClick={() => setTab("assign")}
            size="large"
            style={
              tab === "assign"
                ? {
                    textTransform: "none",
                    maxHeight: "25px",
                    minHeight: "25px",
                    padding: "5px 15px",
                    color: "#FFFFFF",
                    background: "rgb(0, 113, 188)",
                  }
                : {
                    textTransform: "none",
                    maxHeight: "25px",
                    minHeight: "25px",
                    padding: "5px 15px",
                    color: "rgb(0, 113, 188)",
                    background: "rgb(0,0,0,0.04)",
                  }
            }
          >
            Tickets
          </Button>

          <Button
            onClick={() => setTab("unassign")}
            size="large"
            style={
              tab === "unassign"
                ? {
                    textTransform: "none",
                    maxHeight: "25px",
                    minHeight: "25px",
                    padding: "5px 15px",
                    color: "#FFFFFF",
                    background: "rgb(0, 113, 188)",
                  }
                : {
                    textTransform: "none",
                    maxHeight: "25px",
                    minHeight: "25px",
                    padding: "5px 15px",
                    color: "rgb(0, 113, 188)",
                    background: "rgb(0,0,0,0.04)",
                  }
            }
          >
            Unassigned Tickets
          </Button>
        </Grid>
      </Grid>

      <div style={{ marginTop: "10px" }}>
        {(tab === "unassign" && (
          <div>
            <UnassignedTable />
          </div>
        )) ||
          (tab === "assign" && (
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px 0px 0px 0px",
              }}
            >
              <Grid
                item
                lg={1.9}
                md={3.9}
                sm={5.9}
                xs={12}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid rgb(0,0,0,0.1)",
                  borderRadius: "5px",
                  boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("Team Tab", "New");
                  setTable("New");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #C2C2C2",
                    padding: "0px 5px",
                  }}
                >
                  <p style={styles.tablehdr}>New</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <NewIcon />
                    <RightArrowIcon />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-around",
                    padding: "10px",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Total
                  </p>

                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {countTicket[0] && countTicket[0]?.New}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    SLA
                  </p>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {SlaCount[0] && SlaCount[0]?.New}
                  </p>
                </div>
              </Grid>

              <Grid
                item
                lg={1.9}
                md={3.9}
                sm={5.9}
                xs={12}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid rgb(0,0,0,0.1)",
                  borderRadius: "5px",
                  boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("Team Tab", "Progress");
                  setTable("Progress");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #C2C2C2",
                    padding: "0px 5px",
                  }}
                >
                  <p style={styles.tablehdr}>Progress</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <ProgressIcon />
                    <RightArrowIcon />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-around",
                    padding: "10px",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Total
                  </p>

                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {countTicket[0] && countTicket[0]?.Progress}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    SLA
                  </p>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {SlaCount[0] && SlaCount[0]?.Progress}
                  </p>
                </div>
              </Grid>
              <Grid
                item
                lg={1.9}
                md={3.9}
                sm={5.9}
                xs={12}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid rgb(0,0,0,0.1)",
                  borderRadius: "5px",
                  boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("Team Tab", "Hold");
                  setTable("Hold");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #C2C2C2",
                    padding: "0px 5px",
                  }}
                >
                  <p style={styles.tablehdr}>Hold</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <HoldIcon />
                    <RightArrowIcon />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-around",
                    padding: "10px",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Total
                  </p>

                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {countTicket[0] && countTicket[0]?.Holding}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    SLA
                  </p>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {SlaCount[0] && SlaCount[0]?.Holding}
                  </p>
                </div>
              </Grid>

              <Grid
                item
                lg={1.9}
                md={3.9}
                sm={5.9}
                xs={12}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid rgb(0,0,0,0.1)",
                  borderRadius: "5px",
                  boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("Team Tab", "Completed");
                  setTable("Completed");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #C2C2C2",
                    padding: "0px 5px",
                  }}
                >
                  <p style={styles.tablehdr}>Completed</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <CompletedIcon />
                    <RightArrowIcon />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-around",
                    padding: "10px",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Total
                  </p>

                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {countTicket[0] && countTicket[0]?.Completed}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    SLA
                  </p>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {SlaCount[0] && SlaCount[0]?.Completed}
                  </p>
                </div>
              </Grid>
              <Grid
                item
                lg={1.9}
                md={3.9}
                sm={5.9}
                xs={12}
                style={{
                  background: "#FFFFFF",
                  border: "1px solid rgb(0,0,0,0.1)",
                  borderRadius: "5px",
                  boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("Team Tab", "Solved");
                  setTable("Solved");
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #C2C2C2",
                    padding: "0px 5px",
                  }}
                >
                  <p style={styles.tablehdr}>Solved</p>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <SolvedIcon />
                    <RightArrowIcon />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "space-around",
                    padding: "10px",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Total
                  </p>

                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {countTicket[0] && countTicket[0]?.Solved}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    SLA
                  </p>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {SlaCount[0] && SlaCount[0]?.Solved}
                  </p>
                </div>
              </Grid>
              <Grid item md={3.9} sm={5.9} xs={12}></Grid>
            </Grid>
          ))}
        {tab == "assign" && (
          <>
            {table === "All" && (
              <TAll Id={ShareId} DepartmentValue={DepartmentValue} />
            )}
            {table === "New" && (
              <TNewTable Id={ShareId} DepartmentValue={DepartmentValue} />
            )}
            {table === "Progress" && (
              <TProgressTable Id={ShareId} DepartmentValue={DepartmentValue} />
            )}
            {table === "Completed" && (
              <TCompletedTable Id={ShareId} DepartmentValue={DepartmentValue} />
            )}
            {table === "Solved" && (
              <TSolvedTable Id={ShareId} DepartmentValue={DepartmentValue} />
            )}
            {table === "Hold" && (
              <THoldTable Id={ShareId} DepartmentValue={DepartmentValue} />
            )}
          </>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 500,
    fontSize: "14px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};
