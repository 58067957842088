import { Button, Grid, Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import Taggedopen from "./TaggedComonents/Taggedopen";
import Taggedsolved from "./TaggedComonents/Taggedsolved";

function TaggedTickets() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const tabDetails = localStorage.getItem("tagged");
  const alluser = [{ UserName: "All", UserId: "All" }];
  const allDepartement = [{ DepartmentName: "All", DepartmentId: "All" }];
  //Usestate
  const [Active, SetActive] = useState(tabDetails !== null ? false : true);
  const Navigate = useNavigate();
  var Depart = localStorage.getItem("TaggedDepartement");
  const departeequal1 = JSON.parse(Depart);
  const depart1 = JSON.parse(Depart);
  const [DepartmentDrop, SetDepartmentDrop] = useState([]);
  const [DepartmentValue, SetDepartmentValue] = useState([]);
  const [TagSolved, SetTagSolved] = useState([]);
  const [TagSolvedSearch, SetTagSolvedSearch] = useState([]);
  var Use = localStorage.getItem("Taggeduser");
  const Use1 = JSON.parse(Use);
  const [UserDrop, SetUserDrop] = useState([]);
  const [UserValue, SetUserValue] = useState(
    Use !== null
      ? JSON.parse(Use)
      : {
          UserName: "All",
          UserId: "All",
        }
  );
  const [TagopenTable, SetTagopenTable] = useState([]);
  const [TagopenSearch, SetTagopenSearch] = useState([]);

  //api
  const DepartementList = async () => {
    const response = await global.$axios
      .get(`/ticket/team/department/${AppCode}/${userId}`)
      .then(async (response) => {
        if (response.data.Status) {
          SetDepartmentDrop([...response.data.Message]);
          SetDepartmentValue(
            Depart !== null ? JSON.parse(Depart) : response.data.Message[0]
          );
          await UserApi(
            Depart !== null
              ? [JSON.parse(Depart)?.DepartmentId]
              : [response?.data?.Message[0]?.DepartmentId]
          );
        } else {
          SetDepartmentDrop([]);
        }
      })
      .catch((err) => {
        console.error("Departement", err);
      });
  };
  const UserApi = async (DeptId) => {
    let data = {
      DepartmentId: DeptId,
    };
    const response = await global.$axios
      .put(`/ticket/team/user/${AppCode}`, data)
      .then(async (response) => {
        if (response.data.Status == 200) {
          SetUserDrop([...alluser, ...response.data.Message]);

          SetUserValue(
            Use !== null
              ? JSON.parse(Use)
              : {
                  UserName: "All",
                  UserId: "All",
                }
          );
          Active
            ? tableapi(
                DeptId,
                Use !== null
                  ? JSON.parse(Use)?.UserId
                  : response.data?.Message?.map((e) => e.UserId),
                true
              )
            : tableapiSolved(
                DeptId,
                Use !== null
                  ? JSON.parse(Use)?.UserId
                  : response.data?.Message?.map((e) => e.UserId),
                true
              );
        } else {
          SetUserDrop([]);
        }
      })
      .catch((err) => {
        console.error("Departement User Api", err);
      });
  };

  const tableapi = async (DeptId, User, Id) => {
    try {
      const data = {
        Tagdepartment: DeptId,
      };
      const response =
        global?.$userData?.logindata?.AccessAdminTicket == "Yes" && Id == true
          ? await global.$axios.post(`/ticket/tagalllist/${AppCode}`, data)
          : await global.$axios.post(`/ticket/taglist/${AppCode}`, {
              Taguser: User,
            });
      if (response.data.Status === 200) {
        SetTagopenTable(response.data.Message);
        SetTagopenSearch(response.data.Message);
      } else {
        SetTagopenTable([]);
        SetTagopenSearch([]);
      }
    } catch (error) {
      console.error("tableapidataa", error);
    }
  };
  const tableapiSolved = async (DeptId, User, Id) => {
    const data = {
      Tagdepartment: DeptId,
    };
    const response =
      global?.$userData?.logindata?.AccessAdminTicket == "Yes" && Id == true
        ? await global.$axios.post(`/ticket/tagalllist/${AppCode}`, data)
        : await global.$axios
            .post(`ticket/Tagusersolvedlist/${AppCode}`, {
              Taguser: [User],
            })
            .then(async (response) => {
              SetTagSolved(response.data.Message);
              SetTagSolvedSearch(response.data.Message);
            });
  };
  useEffect(() => {
    global?.$userData?.logindata?.AccessAdminTicket == "Yes"
      ? DepartementList()
      : localStorage.getItem("tagged")
      ? tableapi([], [userId], false)
      : tableapiSolved([], [userId], false);
  }, []);
  return (
    <>
      <Grid
        container
        xs={12}
        style={{
          margin: "0px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          md={global?.$userData?.logindata?.AccessAdminTicket == "Yes" ? 6 : 12}
        >
          <span style={styles.activehdr}>Tagged Tickets</span>
        </Grid>

        {global?.$userData?.logindata?.AccessAdminTicket == "Yes" && (
          <Grid
            xs={12}
            md={6}
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
            sx={{
              justifyContent: { md: "flex-end", xs: "" },
            }}
          >
            <Button
              variant={Active ? "contained" : "text"}
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                SetActive(true);
                localStorage.setItem("tagged", "true");
                tableapi(
                  [DepartmentValue.DepartmentId],
                  UserDrop.map((e) => e.UserId),
                  true
                );
              }}
            >
              Open
            </Button>
            <Button
              variant={!Active ? "contained" : "text"}
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                SetActive(false);
                localStorage.setItem("tagged", "false");
                tableapiSolved(
                  [DepartmentValue.DepartmentId],
                  UserDrop.map((e) => e.UserId),
                  true
                );
              }}
            >
              Solved
            </Button>
          </Grid>
        )}
        <Grid
          container
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
          }}
          md={5}
          xs={12}
        >
          {global?.$userData?.logindata?.AccessAdminTicket == "Yes" ? (
            <>
              <Grid item md={5.5} xs={12} sx={{ marginTop: "5px" }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={DepartmentDrop}
                  value={DepartmentValue}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.DepartmentName == value.DepartmentName
                  }
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label=" Department" />
                  )}
                  onChange={(event, newvalue) => {
                    localStorage.setItem(
                      "TaggedDepartement",
                      JSON.stringify(newvalue)
                    );
                    localStorage.setItem(
                      "Taggeduser",
                      JSON.stringify({ UserName: "All", UserId: "All" })
                    );
                    SetDepartmentValue(newvalue);
                    SetUserValue({ UserName: "All", UserId: "All" });
                    UserApi([newvalue.DepartmentId]);
                    Active
                      ? tableapi([newvalue.DepartmentId], [], true)
                      : tableapiSolved([newvalue.DepartmentId], [], true);
                  }}
                />
              </Grid>
              <Grid item md={5.5} xs={12} sx={{ marginTop: "5px" }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={UserDrop}
                  value={UserValue}
                  disableClearable
                  isOptionEqualToValue={(option, value) =>
                    option.UserName == value.UserName
                  }
                  getOptionLabel={(option) => option.UserName || ""}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Users" />
                  )}
                  onChange={(event, newvalue) => {
                    localStorage.setItem("All User", JSON.stringify(newvalue));
                    if (newvalue.UserId == "All") {
                      localStorage.setItem(
                        "Taggeduser",
                        JSON.stringify({ UserName: "All", UserId: "All" })
                      );
                      SetUserValue({ UserName: "All", UserId: "All" });
                      Active
                        ? tableapi(
                            [DepartmentValue?.DepartmentId],
                            UserDrop.map((e) => e.UserId),
                            true
                          )
                        : tableapiSolved(
                            [DepartmentValue?.DepartmentId],
                            UserDrop.map((e) => e.UserId),
                            true
                          );
                    } else {
                      localStorage.setItem(
                        "Taggeduser",
                        JSON.stringify(newvalue)
                      );
                      SetUserValue(newvalue);
                      Active
                        ? tableapi(
                            DepartmentValue?.DepartmentId,
                            [newvalue.UserId],
                            false
                          )
                        : tableapiSolved(
                            DepartmentValue?.DepartmentId,
                            [newvalue.UserId],
                            false
                          );
                    }
                  }}
                />
              </Grid>
            </>
          ) : (
            <Grid
              xs={12}
              md={6}
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
              sx={{
                justifyContent: { md: "flex-start", xs: "" },
              }}
            >
              <Button
                variant={Active ? "contained" : "text"}
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  SetActive(true);
                  localStorage.setItem("tagged", "true");
                  tableapi([], [userId], false);
                }}
              >
                Open
              </Button>
              <Button
                variant={!Active ? "contained" : "text"}
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  SetActive(false);
                  localStorage.setItem("tagged", "false");
                  tableapiSolved([], [userId], false);
                }}
              >
                Solved
              </Button>
            </Grid>
          )}
        </Grid>
        {Active ? (
          <Taggedopen
            TagopenTable={TagopenTable}
            SetTagopenTable={SetTagopenTable}
            TagopenSearch={TagopenSearch}
            SetTagopenSearch={SetTagopenSearch}
          />
        ) : (
          <Taggedsolved
            TagSolved={TagSolved}
            SetTagSolved={SetTagSolved}
            TagSolvedSearch={TagSolvedSearch}
            SetTagSolvedSearch={SetTagSolvedSearch}
          />
        )}
      </Grid>
    </>
  );
}

export default TaggedTickets;
const styles = {
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
  },
};
