import React, { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
export default function TicketTableInactive() {
  const AppCode = global.$userData.logindata.AppCode;
  //table
  const [Activel, setActiveList] = useState([]);
  //popup
  const [Restoredata, setRestoredata] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //api
  const inactive = async () => {
    const response = await global.$axios.get(`/issue/inactivelist/${AppCode}`)
      .then(async (response) => {
        setTicketRow([...response.data.Message]);
        setTicketSearch([...response.data.Message]);
      })
      .catch((e) => {
        console.error("ticketinactive", e)
      })
  };
  const restore = async (id) => {
    const response = await global.$axios.put(`issue/restore/${AppCode}/${id}`)
      .then(async (response) => {
        if (response.data.status == 200) {
          toast.success("Ticket Restored");
          inactive();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("ticketrestore", e)
      })
  };
  useEffect(() => {
    inactive();
  }, []);

  //Search
  const [TicketRow, setTicketRow] = useState([]);
  const [TicketSearch, setTicketSearch] = useState([]);

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = TicketRow.filter((item) => {
        const IssueId = String(item.IssueId);
        const DepartmentName = String(item?.DepartmentName);
        const IssueName = String(item?.IssueName);
        const UpdatedBy = String(item?.UpdatedBy);
        const UpdatedAt = String(item?.UpdatedAt);
        const ETC = String(
          moment(item?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );

        const text_data = term.toLowerCase();
        return (
          IssueId.indexOf(text_data) > -1 ||
          DepartmentName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedAt.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1
        );
      });

      setTicketSearch(ticketData);
    } else {
      setTicketSearch(TicketRow);
    }
  }

  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "IssueId",
      sort: "desc",
    },
  ]);
  const columns = [
    {
      field: "IssueId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",

      renderCell: (cellValues) => {
        // console.log(cellValues, "cellValues");
        return (
          <>
            <Tooltip title="Restore">
              <Tooltip
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => {
                  setRestoredata(cellValues.row);
                  handleOpen();
                }}
              >
                <RestoreIcon />
              </Tooltip>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "DepartmentName",
      headerClassName: "super-app-theme--header",
      headerName: "Department",
      width: 190,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "IssueName",
      headerClassName: "super-app-theme--header",
      headerName: "Ticket",
      width: 190,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "IssueCategroyName",
      headerClassName: "super-app-theme--header",
      headerName: "Ticket category",
      width: 190,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "UpdatedBy",
      headerClassName: "super-app-theme--header",
      headerName: " Updated By",
      width: 190,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "UpdatedAt",
      headerClassName: "super-app-theme--header",
      headerName: "Updated At",
      width: 190,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) =>
        moment(fields?.row?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YYYY hh:mm A"
        ) || "",
    },

  ];

  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12} >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            margin: "10px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>

      <div style={{ height: 480, width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...TicketRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={TicketSearch}
          columns={columns}
          rowHeight={38}
          pageSize={10}
          getRowId={(row) => row.IssueId}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        // checkboxSelection
        />
        <div>
          <Dialog
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontWeight: "400",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  margin: "0px",
                }}
              >
                Do you want to Restore{" "}
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    margin: "0px",
                    color: "#0071BC",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {Restoredata.IssueName}
                </p>{" "}
              </p>
            </DialogTitle>

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  restore(Restoredata.IssueId);
                  handleClose();
                }}
                autoFocus
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={handleClose}
              >
                No{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </div>
    </>
  );
}

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};

