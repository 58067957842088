import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Tooltip,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";


const DayHoursPolicy = ({ setTab }) => {
  const [age, setAge] = React.useState("");
  const Navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // const [add, setAdd] = useState("");
  return (
    <div>
      <div>
        <span
          onClick={() => Navigate(-1)}
          style={{
            color: "#0071BC",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Back">
            <ArrowBackIosNewIcon
              sx={{
                width: "20px",
                height: "15px",
                marginBottom: "3px",
              }}
            />
          </Tooltip>
        </span>
        <span
          style={{
            fontSize: "16px",
            fontWeight: 600,
            margin: "0px",
            color: "#0071BC",
            cursor: "pointer",
          }}
          onClick={() => Navigate(-1)}
        >
          Masters /
        </span>{" "}
        <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
          {" "}
          Working Days & Hours
        </span>
      </div>
      <Grid
        container
        style={{
          background: "#FFFFFF",
          margin: "10px 0px 5px 0px",
          boxShadow: "5px 3px 5px #c2c2c2",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          xs={11}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            borderBottom: "1px solid #c2c2c2",
            margin: "0px 0px 10px 0px",
          }}
        >
          <Grid
            conatiner
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px", fontWeight: 600 }}>
                  SNo
                </p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px", fontWeight: 600 }}>
                  Day
                </p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <p style={{ margin: "0px", fontSize: "14px", fontWeight: 600 }}>
                  From Time
                </p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <p style={{ margin: "0px", fontSize: "14px", fontWeight: 600 }}>
                  To Time
                </p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <p style={{ margin: "0px", fontSize: "14px", fontWeight: 600 }}>
                  Day Type
                </p>
              </Grid>
              <Grid item md={3.3}></Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>1 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Monday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div
                  style={{
                    margin: "0px 0px 10px 0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>2 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Tuesday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>3 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Wednesday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>4 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Thursday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>5 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Friday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>6 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Saturday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                marginTop: "15px",
              }}
            >
              <Grid item md={1.5}>
                <p style={{ margin: "0px", fontSize: "14px" }}>7 .</p>
              </Grid>
              <Grid item md={2.4}>
                <p style={{ margin: "0px", fontSize: "14px" }}>Sunday</p>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 10px 0px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    style={{ padding: "0px" }}
                    label={"hh : mm "}
                  >
                    <MobileTimePicker
                      sx={{
                        p: 0,
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          { padding: "5px" },
                      }}
                      views={["hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item md={2.4}>
                {" "}
                <div style={{ margin: "0px 0px 0px 0px" }}>
                  <Select
                    sx={{
                      ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        padding: "5px 0px 0px 10px",
                        width: "120.9px",
                        height: "30px",
                      },
                    }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={age}
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Working</MenuItem>
                    <MenuItem value={20}>Holiday</MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0px 0px 10px 0px",
          }}
        >
          <Button size="small" variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default DayHoursPolicy;
