import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import React from "react";
import { CloseIcon } from "../../Assets/Icon";
import { useState } from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

const ProductView = ({ ProductViewOpen, setProductViewOpen, AccountsId }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const [ProductDrop, setProductDrop] = useState([]);
  const [ProductView, setProductView] = useState([]);
  const [AddMore, setAddMore] = useState(false);

  const ProductList = async () => {
    const response = await global.$axios
      .get(`/product/dropdown/${AppCode}`)
      .then(async (response) => {
        setProductDrop(response.data.Message);
      })
      .catch((e) => {
        console.error("Company Product List Issue", e);
      });
  };

  const DestroyProduct = async () => {
    if (AccountsId) {
      const response = await global.$axios.post(
        `/company/delete/${AppCode}/${AccountsId}`
      );
    }
  };

  const AddProduct = async () => {
    var productArray = ProductView.map((x) => x.ProductId);

    if (ValidProduct == 1) {
      toast.error("You're Adding Existing Product");
      return;
    } else {
      const response = await global.$axios
        .post(`company/product/add`, {
          AppCode: AppCode,
          AccountsId: AccountsId,
          ProductId: productArray,
          UserId: userId,
        })

        .then(async (response) => {

          if (response.data.status === 200) {
            setProductDrop(response.data.Message);

            toast.success("Product Added To This Company");

            setTimeout(() => {
              setProductViewOpen(false);
            }, 1000);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          toast.error("Add Product Issue");
          console.error("Company Add Product Issue", e);
        });
    }
  };

  const ViewProduct = async () => {
    const response = await global.$axios
      .get(`/company/accounts/${AppCode}/${AccountsId}`)
      .then(async (response) => {
        setProductView(response.data.Message);
      })
      .catch((e) => {
        console.error("Company Product View Issue", e);
      });
  };

  //Validate
  const [ValidProduct, setValidProduct] = useState(0);
  const ValidateProduct = async () => {
    var ProductArray = ProductView.map((e) => e.ProductId);
    const response = await global.$axios
      .get(`/company/accounts/${AppCode}/${AccountsId}/${ProductArray}`)
      .then(async (response) => {
        setValidProduct(response.data.Message);
      })
      .catch((e) => {
        console.error("Company Product Valid Issue", e);
      });
  };

  useEffect(() => {
    ProductList();
    ViewProduct();
  }, []);

  return (
    <div>
      <Modal
        maxWidth={"xs"}
        open={ProductViewOpen}
        onClose={() => setProductViewOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            borderRadius: "10px",
            outline: "none",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#0071BC", fontWeight: 500 }}>
              Company's Products
            </span>
            <IconButton
              onClick={() => setProductViewOpen(false)}
              sx={{ padding: "0px" }}
            >
              <CloseIcon />
            </IconButton>

            <Grid container style={{ margin: "10px" }}>
              <Autocomplete
                size="small"
                fullWidth
                multiple
                defaultValue={ProductView}
                disableCloseOnSelect
                id="auto-complete"
                options={ProductDrop}
                value={ProductView}
                onChange={(event, newValue) => {
                  setProductView(newValue);
                  ValidateProduct();
                  setAddMore(true);
                  DestroyProduct();
                }}
                getOptionLabel={(option) => option.ProductName}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.ProductId}>
                    {option.ProductName}
                  </Box>
                )}
                // helperText={
                //   ValidProduct == 1 ? (
                //     <span style={{ color: "darkred" }}>Already Exist</span>
                //   ) : (
                //     <span style={{ color: "darkred" }}>Good</span>
                //   )
                // }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Product"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0px 0px 0px ",
              }}
            >
              <Button
                style={{
                  textTransform: "none",
                  maxHeight: "22px",
                  minHeight: "22px",
                  background: "#0071BC",
                  color: "#FFF",
                }}
                variant="contained"
                disabled={AddMore === false ? true : false}
                onClick={() => {
                  {
                    ProductView.length !== 0
                      ? AddProduct()
                      : setProductViewOpen(false);
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default ProductView;
