import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import JoditEditor from "jodit-react";
import { useRef } from "react";
import { CloseIcon } from "../../../../Assets/Icon";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, useMediaQuery } from "@material-ui/core";

const SolutionEditPopup = ({
  SolutionEditOpen,
  setSolutionEditOpen,
  SolutionActiveList,
  id,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const handleClose = () => setSolutionEditOpen(false);
  const [ticket, setTicket] = useState(null);
  const [ticketDrop, setTicketDrop] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const plainString = content.replace(/<[^>]+>/g, "");

  const ticketList = async () => {
    const dropdownResponse = await global.$axios
      .get(`/solutionconnect/dropdown/${AppCode}`)
      .then(async (dropdownResponse) => {
        setTicketDrop(dropdownResponse.data.Message);
      })
      .catch((e) => {
        console.error("solutioneditticketdrop", e);
      });
  };

  const Edit = async () => {
    const response = await global.$axios
      .put(`/solutionconnect/edit/${id}`, {
        AppCode: AppCode,
        IssueId: ticket.IssueId,
        IssueSolution: plainString,
        UserId: userId,
      })
      .then(async (response) => {
        if (response.data.status === 200) {
          toast.success("Changes Succeed");
          handleClose();
          SolutionActiveList();
        }
      })
      .catch((e) => {
        console.error("Solution Edit", e);
      });
  };

  const EditView = async () => {
    const response = await global.$axios
      .get(`/solutionconnect/editview/${AppCode}/${id}`)
      .then(async (response) => {
        setTicket(response.data.Message.issue);
        setContent(response.data.Message.IssueSolution);
      })
      .catch((e) => [console.error("solutionedit view", e)]);
  };

  useEffect(() => {
    ticketList();
    EditView();
  }, []);
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
        open={SolutionEditOpen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Edit Ticket Solution
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Autocomplete
            size="small"
            id="auto-complete"
            options={ticketDrop}
            value={ticket}
            onChange={(event, newValue) => {
              setTicket(newValue);
            }}
            isOptionEqualToValue={(option, value) =>
              option.IssueName === value.IssueName
            }
            getOptionLabel={(option) => option.IssueName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.IssueId}>
                {option.IssueName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Company Name"
                variant="outlined"
              />
            )}
          />
          <p
            style={{
              fontSize: "14px",
              margin: "15px 0px 0px 0px ",
            }}
          >
            Ticket Solution
          </p>
          <Grid item xs={12}>
            <p
              style={{
                padding: "10px 15px",
                background: "rgba(0, 113, 188, 0.1)",
                margin: "0px",
              }}
            >
              Description
            </p>
            <JoditEditor
              ref={editor}
              value={content}
              onBlur={(e) => setContent(e)}
            />
          </Grid>
          <center style={{ marginTop: "20px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              onClick={() => Edit()}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
};

export default SolutionEditPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
