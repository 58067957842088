import MainRoutes from "./Component/routes/MainRoutes";
import "react-toastify/dist/ReactToastify.css";
import "../src/Component/global";
import { useSelector } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";
import "./global.css";

LicenseInfo.setLicenseKey(
  "8302d402dde21265ceb14b45663b02ddTz02MjI4NSxFPTE3MTA3NDc3MjMwNDgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);
function App() {
  const sessiondata = useSelector((state) => state.user);
  global.$userData = sessiondata;

  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
