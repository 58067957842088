// import * as React from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import { useDemoData } from "@mui/x-data-grid-generator";
// import { darken, lighten, styled } from "@mui/material/styles";

// const StyledDataGrid = styled(DataGrid)(() => ({
//   "& .super-app-theme--Open": {
//     backgroundColor: "lightgreen",
//   },
// }));

// export default function StylingRowsGrid() {
//   const { data } = useDemoData({
//     dataSet: "Commodity",
//     rowLength: 100,
//   });

//   return (
//     <Box sx={{ height: 400, width: "100%" }}>
//       <StyledDataGrid
//         {...data}
//         getRowClassName={(params) => `super-app-theme--${params.row.status}`}
//       />
//     </Box>
//   );
// }
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";

export default function ControlledSelectionGrid() {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 10,
    maxColumns: 6,
  });

  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  return <div style={{ height: 400, width: "100%" }}>Settings</div>;
}
