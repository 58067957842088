import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";

const EditTicketCategoryPopup = ({
  data,
  EditPopupOpen,
  setEditPopupOpen,
  active,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setEditPopupOpen(true);
  const handleClose = () => setEditPopupOpen(false);
  const [age, setAge] = React.useState("");
  const [categoryName, setCategoryName] = useState(data.IssueCategroyName);
  const [DepartDrop, setDepartDrop] = useState([]);
  const [department, setDepartment] = useState([]);

  const [err, seterr] = useState(0);

  const validation = async (val) => {
    const response = await global.$axios
      .get(`issue_category/validatecategory/${AppCode}/${val} `)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("editticketcatagoryvalidation", e);
      });
  };

  const ticketCategory = async () => {
    if (department == null || categoryName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data1 = {
        AppCode: AppCode,
        DepartmentId: department.DepartmentId,
        IssueCategroyName: categoryName,
        UserId: userId,
      };
      // issue_category/edit/:id
      const response = await global.$axios
        .put(`issue_category/edit/${data.IssueCategoryId}`, data1)
        .then(async (response) => {
          if (response.data.status === 200) {
            setCategoryName("");
            handleClose();
            toast.success("Ticket category Edited");
            active();
            // console.log("response", response.data);
          }
        })
        .catch((e) => {
          console.error("editticketcatagoryadd", e);
        });
    }
  };
  const DepartmentDrop = async () => {
    const dropdownResponse = await global.$axios
      .get(`department/getdept/${AppCode}`)
      .then(async (dropdownResponse) => {
        setDepartDrop(dropdownResponse.data.Message);
      })
      .catch((e) => {
        console.error("ticketcatagorydepartementdrop", e);
      });
  };
  const edit = async () => {
    // issue_category/editview/:id/:AppCode
    const response = await global.$axios
      .get(`issue_category/editview/${AppCode}/${data.IssueCategoryId}`)
      .then(async (response) => {
        setDepartment(response.data.Message.departments);
      })
      .catch((e) => {
        console.error("editticketcatagoryadd", e);
      });
  };
  useEffect(() => {
    DepartmentDrop();
    edit();
  }, []);

  return (
    <div>
      <Modal
        open={EditPopupOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Edit Ticket Category
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container xs={12}>
            <p
              style={{
                fontSize: "14px",
                margin: "0px  ",
              }}
            >
              Department *
            </p>
            <Autocomplete
              fullWidth
              size="small"
              id="Designation"
              value={department}
              options={DepartDrop}
              isOptionEqualToValue={(option, value) =>
                option.DepartmentName === value.DepartmentName
              }
              getOptionLabel={(option) => option.DepartmentName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setDepartment(newValue);
              }}
            />
          </Grid>
          <Grid container xs={12}>
            <p
              style={{
                fontSize: "14px",
                margin: "0px  ",
                paddingTop: "10px",
              }}
            >
              Ticket Category *
            </p>
            <TextField
              fullWidth
              value={categoryName}
              defaultValue={data.IssueCategroyName}
              onChange={(e) => setCategoryName(e.target.value)}
              sx={{ minWidth: 300 }}
              placeholder="Add Ticket Category Here"
              id="outlined-size-small"
              size="small"
              onKeyUp={(e) => {
                e.target.value !== "" && validation(e.target.value);
              }}
              error={
                err === 1 && data.IssueCategroyName != categoryName
                  ? true
                  : false
              }
              helperText={
                err === 1 && data.IssueCategroyName != categoryName ? (
                  <span style={{ color: "red" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>""</span>
                )
              }
            />
          </Grid>
          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                ticketCategory();
              }}
            >
              Update
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default EditTicketCategoryPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
