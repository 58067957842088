import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Addmorecompanybranches() {
  //UseStates
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [CustomerCode, setCustomerCode] = useState("");
  const [Company, setCompany] = useState(null);
  const [CompanyDrop, setCompanyDrop] = useState([]);
  const [BranchName, setBranchName] = useState("");
  const [ContactNo, setContactNo] = useState("");
  const [Mail, setMail] = useState("");
  const [EmployeeNo, setEmployeeNo] = useState("");
  const [GstNo, setGstNo] = useState("");
  const [StreetAddress, setStreetAddress] = useState("");
  const [Area, setArea] = useState("");
  const [Country, setCountry] = useState(null);
  const [CountryDrop, setCountryDrop] = useState([]);
  const [State, setState] = useState(null);
  const [StateDrop, setStateDrop] = useState([]);
  const [City, setCity] = useState(null);
  const [CityDrop, setCityDrop] = useState([]);
  const [Pincode, setPinCode] = useState("");
  const [Comment, setComent] = useState("");
  const Navigate = useNavigate();

  //APIs
  const companyList = async () => {
    const response = await global.$axios.get(
      `/company/getcompanylist/${AppCode}`
    );
    setCompanyDrop(response.data.message);
  };

  const countryList = async () => {
    const response = await global.$axios.get("/userinfo/branch/country");
    setCountryDrop(response.data.message);
  };

  const StateList = async (countryId) => {
    if (!countryId) {
      setStateDrop([]);
      setState(null);
      setCityDrop([]);
      setCity(null);
      setPinCode("");
    } else {
      const response = await global.$axios.get(
        `/userinfo/branch/states/${countryId}`
      );
      setStateDrop(response.data.message);
    }
  };

  const CityList = async (stateId) => {
    if (!stateId) {
      setCityDrop([]);
      setCity(null);
    } else {
      const response = await global.$axios.get(
        `/userinfo/branch/cities/${stateId}`
      );
      setCityDrop(response.data.message);
    }
  };

  //Validate
  const [MobileLength, setMobileLength] = useState(false);
  const DigitValidate = (e) => {
    if (ContactNo.length !== 10) {
      setMobileLength(true);
    } else {
      setMobileLength(false);
    }
  };

  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };

  const BranchAdd = async () => {
    if (
      BranchName === "" ||
      !(Company != null && Object.values(Company).length !== 0)
    ) {
      toast.error("You Missed Required Fields");
      return;
    } else if (ContactNo != "" && ContactNo.length != 10) {
      toast.error("Number Must Be 10 Digits");
      return;
    } else if (Mail != "" && NoValidEmail) {
      toast.error("Please Provide Valid Mail");
    } else {
      const response = await global.$axios.post(`/company/branches/add`, {
        AppCode: AppCode,
        AccountsId: Company?.AccountsId,
        BranchName: BranchName,
        BranchContactNumber: ContactNo,
        BranchEmail: Mail,
        NumberOfEmployee: EmployeeNo,
        GSTNumber: GstNo,
        Country: Country?.CountryId,
        State: State?.StateId,
        City: City?.CityId,
        Area: Area,
        Pincode: Pincode,
        StreetAddress: StreetAddress,
        UserId: userId,
        Description: Comment,
      });

      if (response.data.status === 200) {
        toast.success("Branch Added Successfully");
        setTimeout(() => {
          Navigate(-1);
        }, 1500);

        setCustomerCode("");
        setCompany(null);
        setBranchName("");
        setContactNo("");
        setMail("");
        setEmployeeNo("");
        setGstNo("");
        setStreetAddress("");
        setArea("");
        setCountry("");
        setState("");
        setCity("");
        setPinCode("");
        setComent("");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    companyList();
    countryList();
  }, []);
  return (
    <>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>Company Name *</p>
          <Autocomplete
            size="small"
            id="auto-complete"
            value={Company}
            options={CompanyDrop}
            getOptionLabel={(option) => option.CompanyName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.AccountsId}>
                {option.CompanyName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setCompany(newValue);
            }}
          />
        </Grid>
        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>Branch Name *</p>

          <TextField
            fullWidth
            value={BranchName}
            onChange={(e) => setBranchName(e.target.value)}
            placeholder="Enter Branch Name"
            size="small"
          />
        </Grid>
        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>Contact Number</p>

          <TextField
            fullWidth
            value={ContactNo}
            inputProps={{ maxLength: "10" }}
            onChange={(e) => setContactNo(e.target.value)}
            onKeyUp={(e) => {
              e.target.value !== "" && DigitValidate(e.target.value);
            }}
            helperText={
              MobileLength ? (
                <span style={{ color: "darkred" }}>10 Digit Required</span>
              ) : (
                <span style={{ color: "transparent" }}>Good</span>
              )
            }
            placeholder="Enter Customer Number"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>Mail ID</p>

          <TextField
            fullWidth
            value={Mail}
            onChange={(e) => setMail(e.target.value)}
            onKeyUp={(e) => {
              e.target.value != "" && MailValidate(e.target.value);
            }}
            helperText={
              NoValidEmail ? (
                <span style={{ color: "darkred" }}>Not a Valid Mail</span>
              ) : (
                <span style={{ color: "transparent" }}>Good</span>
              )
            }
            placeholder="Enter Mail ID"
            type="email"
            size="small"
          />
        </Grid>

        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>No of Employee</p>
          <TextField
            fullWidth
            value={EmployeeNo}
            onChange={(e) => setEmployeeNo(e.target.value)}
            type="number"
            placeholder="No of Employee"
            size="small"
          />
        </Grid>
        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>GST Number</p>
          <TextField
            fullWidth
            value={GstNo}
            onChange={(e) => setGstNo(e.target.value)}
            placeholder="Enter GST Number"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Grid item md={3.5} xs={12}>
          {" "}
          <p style={style3.txttitle}>Street Address</p>
          <TextField
            fullWidth
            value={StreetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            placeholder="Enter Door number & street"
            size="small"
          />
        </Grid>

        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>Area</p>
          <TextField
            fullWidth
            value={Area}
            onChange={(e) => setArea(e.target.value)}
            placeholder="Enter Area"
            size="small"
          />
        </Grid>

        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>Country</p>

          <Autocomplete
            size="small"
            id="auto-complete"
            value={Country}
            options={CountryDrop}
            getOptionLabel={(option) => option.CountryName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.CountryId}>
                {option.CountryName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setCountry(newValue);
              if (!newValue) {
                StateList(null);
              }
              StateList(newValue.CountryId);
              setState(null);
              setCity(null);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>State</p>

          <Autocomplete
            size="small"
            id="auto-complete"
            value={State}
            options={StateDrop}
            getOptionLabel={(option) => option.StateName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.StateId}>
                {option.StateName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setState(newValue);
              if (!newValue) {
                CityList(null);
              }
              CityList(newValue.StateId);
            }}
          />
        </Grid>

        <Grid item md={3.5} xs={12}>
          <p style={style3.txttitle}>City</p>

          <Autocomplete
            size="small"
            id="auto-complete"
            value={City}
            options={CityDrop}
            getOptionLabel={(option) => option.CityName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.CityId}>
                {option.CityName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              setCity(newValue);
            }}
          />
        </Grid>
        <Grid item md={3.5} xs={12}>
          {" "}
          <p style={style3.txttitle}>Pincode</p>
          <TextField
            fullWidth
            value={Pincode}
            onChange={(e) => setPinCode(e.target.value)}
            size="small"
            placeholder="Enter Pincode"
          />
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item md={3.5} xs={12} style={{}}></Grid>
        <Grid item md={3.5} xs={12} style={{}}></Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
        }}
      >
        <Grid item lg={7.77} xs={12}>
          <p style={style3.txttitle}>Comment</p>
          <Box sx={{}}>
            <textarea
              value={Comment}
              onChange={(e) => setComent(e.target.value)}
              placeholder="Enter the Comment"
              style={{
                width: "100%",
                border: "1px solid gray",
                background: "#f5f5f5",
                borderRadius: "10px",
                padding: "10px",
                fontSize: "18px",
                fontWeight: "200",
                height: "80px",
                outline: "none",
              }}
            ></textarea>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0px 10px 0px",
          borderTop: "1px solid rgb(0,0,0,0.2)",
          padding: "10px",
        }}
      >
        <Button
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            padding: "12px",
          }}
          size="small"
          variant="contained"
          onClick={() => BranchAdd()}
        >
          Save
        </Button>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </Grid>
    </>
  );
}

export default Addmorecompanybranches;
const style3 = {
  font1: {
    fontWeight: "700",
    color: "rgba(17, 64, 232, 1)",
    lineHeight: "20px",
    fontSize: "35px",
  },
  font2: {
    fontWeight: "700",
    color: "rgba(17, 64, 232, 1)",
    lineHeight: "20px",
    fontSize: "24px",
  },
  txttitle: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};
