import React, { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function BrandInactiveTable() {
  const AppCode = global.$userData.logindata.AppCode;
  //table drop
  const [activelist, setActiveList] = useState([]);
  //popup
  const [Restoredata, setRestoredata] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //api
  const inactive = async () => {
    const response = await global.$axios.get(
      `product/unit/inactivelist/${AppCode}`
    )
      .then(async (response) => {
        setUnitRow([...response.data.Message]);
        setUnitSearch([...response.data.Message]);
      })
      .catch((e) => {
        console.error("unitinactive", e)
      })

  };
  const restore = async (id) => {
    const response = await global.$axios.put(
      `product/unit/restore/${AppCode}/${id}`
    )
      .then(async (response) => {
        if (response.data.status === 200) {
          toast.success("Moved To Active");
          inactive();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("unitrestore", e)
      })

  };
  useEffect(() => {
    inactive();
  }, []);



  //search

  const [UnitRow, setUnitRow] = useState([]);
  const [UnitSearch, setUnitSearch] = useState([]);

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = UnitRow.filter((item) => {
        const UnitId = String(item.UnitId);
        const UnitName = String(item?.UnitName);
        const UpdatedBy = String(item?.UpdatedBy);
        const UpdatedDateTime = String(item?.UpdatedDateTime);
        const ETC = String(
          moment(item?.UpdatedDateTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );

        const text_data = term.toLowerCase();
        return (
          UnitId.indexOf(text_data) > -1 ||
          UnitName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedDateTime.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1
        );
      });

      setUnitSearch(ticketData);
    } else {
      setUnitSearch(UnitRow);
    }
  }
  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "UnitId",
      sort: "desc",
    },
  ]);
  const columns = [
    {
      field: "UnitId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "edit",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      width: 200,
      align: "center",
      headerAlign: "center",

      renderCell: (cellValues) => {
        // console.log(cellValues, "cellValues");
        return (
          <>
            <Tooltip
              style={{ padding: "0px", cursor: "pointer" }}
              onClick={() => {
                setRestoredata(cellValues.row);
                handleOpen();
              }}
            >
              <RestoreIcon />
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "UnitName",
      headerClassName: "super-app-theme--header",
      headerName: "UnitName",
      width: 290,
      align: "center",
      headerAlign: "center",
      // valueGetter: (fields) => fields?.row?.UnitName,
    },
    // {
    //   field: "CreatedBy",
    //   headerClassName: "super-app-theme--header",
    //   headerName: " Created By",
    //   width: 220,
    // },
    // {
    //   field: "CreatedAt",
    //   headerClassName: "super-app-theme--header",
    //   headerName: " Created At",
    //   width: 220,
    //   valueGetter: (fields) =>
    //     moment(fields?.row?.CreatedAt).format("DD-MM-YY hh.mm A") || "",
    // },
    {
      field: "UpdatedBy",
      headerClassName: "super-app-theme--header",
      headerName: " Updated By",
      width: 290,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "UpdatedDateTime",
      headerName: "Updated At",
      width: 290,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.UpdatedDateTime !== ""
          ? moment(fields?.row?.UpdatedDateTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
          : "-";
      },
    },
  ];

  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            textAlign: "center",
            margin: "10px 0px 10px 0px"
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>

      <div style={{ width: "100%", height: "480px" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...UnitRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={UnitSearch}
          columns={columns}
          rowHeight={38}
          pageSize={10}
          getRowId={(row) => row.UnitId}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        // checkboxSelection
        />
        <div>
          <Dialog
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontWeight: "400",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  margin: "0px",
                }}
              >
                Do you want to Restore{" "}
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    margin: "0px",
                    color: "#0071BC",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {Restoredata.UnitName}
                </p>{" "}
              </p>
            </DialogTitle>

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  restore(Restoredata.UnitId);
                  handleClose();
                }}
                autoFocus
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={handleClose}
              >
                No{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </div>
    </>
  );
}
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },

};
