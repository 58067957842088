import { Button, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Productinactive from "./InActiveTables/Productinactive";
import ProductPopup from "./PopupScreens/ProductPopup";
import Productactivetable from "./ActiveTables/ProductActivetable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";


const Product = () => {
  const Navigate = useNavigate();
  const [sub, setSub] = useState("Active");
  const [tab, settab] = useState("Product");

  return (
    <div>
      {tab === "Product" && (
        <>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span
                onClick={() => Navigate(-1)}
                style={{
                  color: "#0071BC",
                  cursor: "pointer",
                }}
              >
                <Tooltip title="Back">
                  <ArrowBackIosNewIcon
                    sx={{
                      width: "20px",
                      height: "15px",
                      marginBottom: "3px",
                    }}
                  />
                </Tooltip>
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  margin: "0px",
                  color: "#0071BC",
                  cursor: "pointer",
                }}
                onClick={() => Navigate(-1)}
              >
                Masters /
              </span>{" "}
              <span
                style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}
              >
                {" "}
                Product
              </span>
            </div>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              onClick={() => settab("Addproduct")}
            >
              + Add Product
            </Button>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item md={9} sm={9} xs={12} style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}>
              <Button
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                variant={sub === "Active" ? "contained" : "text"}
                onClick={() => setSub("Active")}
              >
                Active
              </Button>
              <Button
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                variant={sub === "Inactive" ? "contained" : "text"}
                onClick={() => setSub("Inactive")}
              >
                Inactive
              </Button>
            </Grid>


            {(sub === "Active" && (

              <Productactivetable />

            )) ||
              (sub === "Inactive" && (
                <Productinactive />
              ))}


          </Grid>

        </>
      )}
      <Grid container lg={12} style={{ marginTop: "10px" }}>
        {tab === "Addproduct" && <ProductPopup settab={settab} />}
      </Grid>
    </div>
  );
};

export default Product;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

  },
};
