import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import DateRangeSharpIcon from "@mui/icons-material/DateRangeSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import CAllTable from "./ReportComponents/Client Reports/CAllTable";
import CNewTable from "./ReportComponents/Client Reports/CNewTable";
import CProgressTable from "./ReportComponents/Client Reports/CProgressTable";
import CHoldTable from "./ReportComponents/Client Reports/CHoldTable";
import CCompletedTable from "./ReportComponents/Client Reports/CCompletedTable";
import CSolvedTable from "./ReportComponents/Client Reports/CSolvedTable";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

function ClientReport() {
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  var CContact = localStorage.getItem("Client Contact");
  const [ContactShow, setContactShow] = useState(null);
  const [ContactDrop, setContactDrop] = useState([]);
  const [TCount, setTCount] = useState("");
  const [SLACount, setSLACount] = useState("");
  var CDate = localStorage.getItem("ClientTime");
  var spli = CDate && CDate.split(",");
  //Date Button
  var today = dayjs();
  var prevWeek = today.subtract(7, "day");
  var prevMonth = today.subtract(1, "month");

  const [Date, setDate] = useState([dayjs(), dayjs()]);

  const [Date1, setDate1] = useState([
    spli !== null ? dayjs(spli[0]) : dayjs(),
    spli !== null ? dayjs(spli[1]) : dayjs(),
  ]);

  var DForm =
    Date[0] &&
    `${Date[0].$d.getFullYear()}-${Date[0].$d.getMonth() + 1 < 10
      ? "0" + (Date[0].$d.getMonth() + 1)
      : Date[0].$d.getMonth() + 1
    }-${Date[0].$d.getDate() < 10
      ? "0" + Date[0].$d.getDate()
      : Date[0].$d.getDate()
    }`;
  var DForm1 =
    Date[1] &&
    `${Date[1].$d.getFullYear()}-${Date[1].$d.getMonth() + 1 < 10
      ? "0" + (Date[1].$d.getMonth() + 1)
      : Date[1].$d.getMonth() + 1
    }-${Date[1].$d.getDate() < 10
      ? "0" + Date[1].$d.getDate()
      : Date[1].$d.getDate()
    }`;

  const [ClientTab, setClientTab] = useState("All");
  //APIs

  const contactList = async () => {
    const response = await global.$axios
      .get(`/ticket/contact/${AppCode}`)
      .then(async (response) => {
        setContactDrop(response.data.Message);
        setContactShow(
          CContact !== null ? JSON.parse(CContact) : response.data.Message[0]
        );
        TotalCount(
          CContact !== null
            ? JSON.parse(CContact)?.ContactId
            : response.data.Message[0]?.ContactId,
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
        SlaCount(
          CContact !== null
            ? JSON.parse(CContact)?.ContactId
            : response.data.Message[0]?.ContactId,
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
      })
      .catch((e) => {
        console.error("Client Contact List", e);
      });
  };

  const TotalCount = async (ContactID, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/daycountclient/${AppCode}`, {
        ContactId: [ContactID],
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {

        setTCount(response.data.Message[0]);
      })
      .catch((e) => {
        console.error("Client Total Count Issue", e);
      });
  };

  const SlaCount = async (ContactID, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/clientdaySLA`, {
        ContactId: [ContactID],
        AppCode: AppCode,
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {
        setSLACount(response.data.Message[0]);
      })
      .catch((e) => {
        console.error("Client SLA Count Issue");
      });
  };

  useEffect(() => {
    setClientTab(
      localStorage.getItem("Client Tab") === null
        ? "All"
        : localStorage.getItem("Client Tab")
    );
    contactList();
  }, []);
  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("Client Tab", "All");
              setClientTab("All");
            }}
          >
            Analytics
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            Client Reports
          </span>
        </div>
      </Grid>

      <Grid
        container
        item
        lg={8}
        md={10}
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item md={5} xs={12} sx={{ marginBottom: "5px" }}>
          <Autocomplete
            fullWidth
            id="auto-complete"
            size="small"
            disableClearable
            options={ContactDrop}
            value={ContactShow}
            isOptionEqualToValue={(option, value) =>
              option.FirstName == value.FirstName
            }
            onChange={(event, newValue) => {
              localStorage.setItem("Client Contact", JSON.stringify(newValue));
              setContactShow(newValue);
              TotalCount(
                newValue?.ContactId,
                spli != null ? spli[0] : DForm,
                spli != null ? spli[1] : DForm1
              );
              SlaCount(
                newValue?.ContactId,
                spli != null ? spli[0] : DForm,
                spli != null ? spli[1] : DForm1
              );
            }}
            getOptionLabel={(option) => option.FirstName}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.ContactId}>
                {option.FirstName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} label="User" variant="outlined" required />
            )}
          />
        </Grid>
        <Grid
          container
          item
          md={6.7}
          xs={12}
          sx={{
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item md={9} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={Date1}
                disableFuture
                format="DD-MM-YYYY"
                slotProps={{ textField: { size: "small" } }}
                onChange={(newValue) => {
                  localStorage.setItem(
                    "ClientTime",
                    `${newValue[0].$d},${newValue[1] != null ? newValue[1].$d : newValue[0].$d
                    }`
                  );
                  setDate1(newValue);
                  setDate(newValue);
                  TotalCount(
                    ContactShow?.ContactId,
                    newValue[0] && newValue[0].$d,
                    newValue[1] && newValue[1].$d
                  );
                  SlaCount(
                    ContactShow?.ContactId,
                    newValue[0] && newValue[0].$d,
                    newValue[1] && newValue[1].$d
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            md={3}
            xs={12}
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ClientTime",
                  `${prevWeek.startOf("week").$d},${prevWeek.endOf("week") != null
                    ? prevWeek.endOf("week").$d
                    : prevWeek.startOf("week").$d
                  }`
                );
                TotalCount(
                  ContactShow?.ContactId,
                  prevWeek.startOf("week").$d,
                  prevWeek.endOf("week").$d
                );
                SlaCount(
                  ContactShow?.ContactId,
                  prevWeek.startOf("week").$d,
                  prevWeek.endOf("week").$d
                );
                setDate1([prevWeek.startOf("week"), prevWeek.endOf("week")]);
                setDate([prevWeek.startOf("week"), prevWeek.endOf("week")]);
              }}
            >
              <Tooltip title="Last Week">
                <DateRangeSharpIcon color="primary" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ClientTime",
                  `${prevMonth.startOf("month").$d},${prevMonth.endOf("month") != null
                    ? prevMonth.endOf("month").$d
                    : prevMonth.startOf("month").$d
                  }`
                );

                TotalCount(
                  ContactShow?.ContactId,
                  prevMonth.startOf("month").$d,
                  prevMonth.endOf("month").$d
                );
                SlaCount(
                  ContactShow?.ContactId,
                  prevMonth.startOf("month").$d,
                  prevMonth.endOf("month").$d
                );
                setDate1([
                  prevMonth.startOf("month"),
                  prevMonth.endOf("month"),
                ]);
                setDate([prevMonth.startOf("month"), prevMonth.endOf("month")]);
              }}
            >
              <Tooltip title="Last Month">
                <CalendarMonthSharpIcon color="success" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ClientTime",
                  `${today.subtract(7, "day").$d},${today != null ? today.$d : today.subtract(7, "day").$d
                  }`
                );

                TotalCount(
                  ContactShow?.ContactId,
                  today.subtract(7, "day").$d,
                  today.$d
                );
                SlaCount(
                  ContactShow?.ContactId,
                  today.subtract(7, "day").$d,
                  today.$d
                );
                setDate1([today.subtract(7, "day"), today]);
                setDate([today.subtract(7, "day"), today]);
              }}
            >
              <Tooltip title="Last 7 Days">
                <TodayTwoToneIcon sx={{ mt: "2px" }} color="secondary" />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px 0px 0px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "New");
            setClientTab("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              New
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.New}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.New}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Progress");
            setClientTab("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Progress
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Progress}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Progress}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Hold");
            setClientTab("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Hold
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Holding}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Holding}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Completed");
            setClientTab("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Completed
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Completed}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Completed}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Solved");
            setClientTab("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Solved
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Solved}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Solved}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid item md={3.9} sm={5.9} xs={12}></Grid>
      </Grid>
      {ClientTab == "All" && (
        <CAllTable
          ContactID={ContactShow?.ContactId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "New" && (
        <CNewTable
          ContactID={ContactShow?.ContactId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Progress" && (
        <CProgressTable
          ContactID={ContactShow?.ContactId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Hold" && (
        <CHoldTable
          ContactID={ContactShow?.ContactId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Completed" && (
        <CCompletedTable
          ContactID={ContactShow?.ContactId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Solved" && (
        <CSolvedTable
          ContactID={ContactShow?.ContactId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
    </>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 500,
    fontSize: "14px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};

export default ClientReport;
