import { Box, Button, Grid, IconButton, Modal, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CloseIcon } from "../../../../Assets/Icon";

const CustomerCodeEdit = ({
  EditOpen,
  setEditOpen,
  Refresh,
  setRefresh,
  id,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //Usestate
  const [CCode, setCCode] = useState("");
  const [CodeName, setCodeName] = useState("");
  const handleClose = () => setEditOpen(false);
  const [Err, setErr] = useState(0);

  //API
  const EditView = async () => {
    const response = await global.$axios
      .get(`/customercode/view/${AppCode}/${id.CustomerId}`)
      .then(async (response) => {
        setCCode(response?.data?.Message?.CustomerCode);
        setCodeName(response?.data?.Message?.CustomerCode);
      })
      .catch((e) => {
        console.error("customercodeedit", e);
      });
  };

  const EditCode = async () => {
    if (CCode === "") {
      toast.error("Please provide Code");
      return;
    } else {
      const response = await global.$axios
        .post(`/customercode/edit/${id.CustomerId}`, {
          AppCode: AppCode,
          CustomerCode: CCode,
          UserId: userId,
        })
        .then(async (response) => {
          if (response.data.status === 200) {
            toast.success("Code Edited");
            handleClose();
            setRefresh(!Refresh);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("customercodeeditview", e);
        });
    }
  };

  const Validate = async (val) => {
    const response = await global.$axios
      .get(`customercode/validatecustomercode/${AppCode}/${val}`)
      .then(async (response) => {
        setErr(response.data.Message);
      })
      .catch((e) => {
        console.error("customercodevalidation", e);
      });
  };

  useEffect(() => {
    EditView();
  }, []);
  return (
    <div>
      <Modal
        open={EditOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            background: "#FFF",
            boxShadow: 24,
            p: 2,
            borderRadius: "10px",
          }}
        >
          <Grid
            Container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              Edit Customer Code{" "}
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12}>
              <TextField
                value={CCode}
                onChange={(e) => setCCode(e.target.value)}
                onKeyUp={(e) => {
                  e.target.value !== "" && Validate(e.target.value);
                }}
                error={Err === 1 && CCode != CodeName ? true : false}
                fullWidth
                placeholder="Code Here"
                id="outlined-size-small"
                size="small"
                helperText={
                  Err === 1 && CCode != CodeName ? (
                    <span style={{ color: "red" }}>Already Exist</span>
                  ) : (
                    <span style={{ color: "transparent" }}>sdfgh</span>
                  )
                }
              />
            </Grid>
          </Grid>

          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={Err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                EditCode();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default CustomerCodeEdit;
