import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { toast, ToastContainer } from "react-toastify";

const EditDesignationPopup = ({
  data,
  EditPopupOpen,
  setEditPopupOpen,
  active,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setEditPopupOpen(true);
  const handleClose = () => setEditPopupOpen(false);


  const [age, setAge] = React.useState("");
  const [designationName, setDesignationName] = useState(data.DesignationName);
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // validation Edit

  const [err, seterr] = useState(0);
  const validation = async (val) => {
    const response = await global.$axios
      .get(`designation/validatedesignation/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("editdesigination", e);
      });
  };

  const designation = async () => {
    if (designationName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data1 = {
        AppCode: AppCode,
        DesignationName: designationName,
        RecordId: 4,
        UserId: userId,
      };

      const response = await global.$axios
        .put(
          // designation/edit/:id
          `designation/edit/${data.DesignationId}`,
          data1
        )
        .then(async (response) => {
          if (response.data.status === 200) {
            setDesignationName("");
            handleClose();
            toast.success("Designation Edited");
            active();
          }
        })
        .catch((e) => {
          console.error("editdesigination", e);
        });
    }
  };

  return (
    <div>
      <Modal
        open={EditPopupOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                color: "#0071BC",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              {" "}
              Edit Designation
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={designationName}
              onChange={(e) => setDesignationName(e.target.value)}
              sx={{ minWidth: 330 }}
              defaultValue={data.DesignationName}
              id="outlined-size-small"
              size="small"
              onKeyUp={(e) => {
                e.target.value !== "" && validation(e.target.value);
              }}
              error={
                err === 1 && data.DesignationName != designationName
                  ? true
                  : false
              }
              helperText={
                err === 1 && data.DesignationName != designationName ? (
                  <span style={{ color: "red" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>""</span>
                )
              }
            />
          </Grid>
          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                designation();
              }}
            >
              Update
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default EditDesignationPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
