import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GroupIcon from "@mui/icons-material/Group";
import { CloseIcon, TicketIdIcon } from "../../Assets/Icon";
import { ToastContainer, toast } from "react-toastify";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SwipeIcon from "@mui/icons-material/Swipe";

function ReassignedTicket({
  data,
  Reassignedopen,
  SetReassignedopen,
  refresh,
  Setrefresh,
}) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const handleClose = () => SetReassignedopen(false);
  //dropdown
  const [Departementdrop, SetDepartmentDrop] = useState([]);
  const [UserDrop, SetUserdrop] = useState([]);
  //dropvalue
  const [Department, setdepartment] = useState([]);
  const [user, setuser] = useState([]);
  //api
  //   const Departementapi = async () => {
  //     // /ticket/department/:AppCode
  //     const response = await global.$axios.get(`/ticket/department/${AppCode}`);
  //     console.log(response, "response");
  //     SetDepartmentDrop(response.data.Message);
  //   };
  //   const UserApi = async (DeptId) => {
  //     // console.log(DeptId, "DeptId");
  //     // ticket/assignto/:AppCode/:DepartmentId
  //     const response = await global.$axios.get(
  //       `/ticket/assignto/${AppCode}/${DeptId.DepartmentId}`
  //     );
  //     console.log(response.data.Message, "responseuser");
  //     SetUserdrop(response.data.Message);
  //   };
  //   const TicketTransfer = async () => {
  //     // ticket/team/transfer
  //     const data1 = {
  //       AppCode: AppCode,
  //       DepartmentId: Department.DepartmentId,
  //       TicketOwner: user.UserId,
  //       TicketId: data.TicketId,
  //     };
  //     console.log(data1, "dataaaaa");
  //     const response = await global.$axios.post(`ticket/team/transfer`, data1);
  //     console.log(response, "responsefinal");
  //     if (response.data.Status == 200) {
  //       handleClose();
  //       toast.success("Ticket Transferred Sucessfully");
  //       Setrefresh(!refresh);
  //     } else {
  //       toast.error("SomeThing Went Wrong");
  //     }
  //   };

  useEffect(
    () => {
      // Departementapi();
    }
    //   [data]
  );

  //   function joinName() {
  //     return `${data.assigned.UserFirstName || ""} ${
  //       data.assigned.UserLastName || ""
  //     }`;
  //   }

  return (
    <div>
      <Dialog
        open={Reassignedopen}
        onClose={handleClose}
        // minWidth={"xs"}
        sx={{
          ".css-1t1j96h-MuiPaper-root-MuiDialog-paper ": {
            padding: "10px 15px",
            borderRadius: "12px",
            minWidth: "400px",
          },
        }}
      // .css-1t1j96h-MuiPaper-root-MuiDialog-paper
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              margin: "10px",
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            Unassigned Ticket
          </div>
          <div>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ marginTop: "1px" }}>
          <Grid container md={12} xs={12}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                display: "flex",
                justifyItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <p
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    fontSize: "14px",
                  }}
                >
                  Department :{" "}
                  <span
                    style={{
                      color: "#0071BC",
                      fontWeight: 600,
                      fontSize: "22px",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {data?.dept?.DepartmentName}
                  </span>
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 10px 0px",
            }}
          >
            <SwipeIcon fontSize="large" />
          </Grid>
          <Grid
            container
            md={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
            }}
          >
            <div style={{ marginTop: "10px" }}>
              <Autocomplete
                id="combo-box-demo"
                options={UserDrop}
                value={user}
                isOptionEqualToValue={(option, value) =>
                  option.UserName == value.UserName
                }
                getOptionLabel={(option) => option.UserName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label=" User" />
                )}
                onChange={(event, newvalue) => {
                  // console.log(newvale, "newvale");
                  setuser(newvalue);
                }}
              />
            </div>
            <center style={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                onClick={() => ReassignedTicket()}
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
              >
                Assign
              </Button>
            </center>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ReassignedTicket;

const styles = {
  txt: {
    fontSize: "12px",
    margin: "0px",
  },

  hdr: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 500,
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
