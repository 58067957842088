import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import AuthRoutes from "../routes/AuthRoutes";
import AppRoutes from "../routes/AppRoutes";
const MainRoutes = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/auth" />} />
          <Route path="auth/*" element={<AuthRoutes />} />
          <Route path="app/*" element={<AppRoutes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default MainRoutes;
