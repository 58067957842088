import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";
import {
  RightArrowIcon,
  NewIcon,
  ProgressIcon,
  HoldIcon,
  CompletedIcon,
  SolvedIcon,
  ViewIcon,
} from "../Assets/Icon";

import { useNavigate } from "react-router-dom";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useDemoData } from "@mui/x-data-grid-generator";
import moment from "moment";
import { Paper, Tooltip } from "@material-ui/core";
import CProgressTable from "./CreatedComponents/CProgressTable";
import CHoldTable from "./CreatedComponents/CHoldTable";
import CCompletedTable from "./CreatedComponents/CCompletedTable";
import CSolvedTable from "./CreatedComponents/CSolvedTable";

import CNewTable from "./CreatedComponents/CNewTable";
import CCancelledTable from "./CreatedComponents/CCancelledTable";

export default function Created() {
  const Navigate = useNavigate();

  const [CreatedRow, setCreatedRow] = useState([]);
  const [CreatedSearch, setCreatedSearch] = useState([]);
  const [head, setHead] = useState("All");
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const [ticketCount, setTicketCount] = useState([]);
  const [slacount, setSlaCount] = useState([]);

  const Created = async () => {
    const response = await global.$axios
      .post(`/ticket/cAllTickets/${AppCode}/${userId}`)
      .then(async (response) => {
        if (response.data.Status === 200) {
          setCreatedRow(response.data.Message);
          setCreatedSearch(response.data.Message);
        }
      })
      .catch((e) => {
        console.error("Created All List Issue", e);
      });
  };

  const CreatedCount = async () => {
    const response = await global.$axios
      .get(`/ticket/cCount/${AppCode}/${userId}`)
      .then(async (response) => {
        console.log("dsd", response.data.Status);

        if (response.data.Status === 200) {
          setTicketCount(response.data.msg[0]);
        }
      })
      .catch((e) => {
        console.error("Created Count Issue", e);
      });
  };

  const slacounts = async () => {
    const response = await global.$axios
      .get(`ticket/createdSLA/${AppCode}/${userId}`)
      .then(async (response) => {
        console.log("dsd", response.data.Status);
        if (response.data.Status === 200) {
          setSlaCount(response.data.msg[0]);
        }
      })
      .catch((e) => {
        console.error("Created SLA Count Issue", e);
      });
  };

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = CreatedRow.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        // const Description = String(item?.TicketDescription);
        const AssignedTo = String(item?.assigned?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedTo.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setCreatedSearch(ticketData);
    } else {
      setCreatedSearch(CreatedRow);
    }
  }

  //Mui Premium

  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  //Concat
  function CreatedFullName(fields) {
    return `${fields?.row?.assigned?.UserFirstName || ""} ${
      fields?.row?.assigned?.UserLastName || ""
    }`;
  }

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title={"Clone Ticket"} style={{ paddingRight: "5px" }}>
              <FileCopyIcon
                size="small"
                onClick={() =>
                  Navigate("/app/CloneTicket", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>

            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("/app/CreatedView", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "Status" || "",
      headerName: "Status",
      width: 100,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "ECAt",
      headerName: "Time To Complete",
      width: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.ECAt !== ""
          ? moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
    },
    {
      field: "TicketClosedTime" || "",
      headerName: "Closed Time",
      width: 180,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.TicketClosedTime !== ""
          ? moment(fields?.row?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created" || "",
      headerName: "Assigned To",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: CreatedFullName,
    },
  ];
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    maxColumns: 6,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "desc" }],
      },
    },
  });

  useEffect(() => {
    setHead(
      localStorage.getItem("Created Tab") === null
        ? "All"
        : localStorage.getItem("Created Tab")
    );
    Created();
    CreatedCount();
    slacounts();
  }, []);

  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item style={{ margin: "5px 0px 0px 0px" }}>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("Created Tab", "All");
              setHead("All");
            }}
          >
            Created
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            {head}
          </span>
        </Grid>
        <Grid item style={{ margin: "5px 0px 0px 0px" }}>
          <Button
            onClick={() => Navigate("/app/AddTicket")}
            style={{
              textTransform: "none",
              maxHeight: "22px",
              minHeight: "22px",
              padding: "12px",
              fontSize: "12px",
            }}
            variant="contained"
          >
            + Add More Ticket
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px 10px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Created Tab", "New");
            setHead("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>New</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <NewIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>{ticketCount.New}</p>

            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.New}
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Created Tab", "Progress");
            setHead("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Progress</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <ProgressIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Progress}
            </p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Progress}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Created Tab", "Hold");
            setHead("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Hold</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <HoldIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Holding}
            </p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Holding}
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Created Tab", "Completed");
            setHead("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Completed</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <CompletedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Completed}
            </p>
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Completed}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Created Tab", "Solved");
            setHead("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Solved</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <SolvedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Solved}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                color: "darkred",
              }}
            >
              {slacount.Solved}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Created Tab", "Cancelled");
            setHead("Cancelled");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Cancelled</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <SolvedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "12px", fontWeight: 500 }}>
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {ticketCount.Cancelled}
            </p>
          </div>
        </Grid>
      </Grid>

      {head === "All" && (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
              {head}
              <span
                style={{
                  paddingLeft: "5px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                List
              </span>
            </p>
            <Box
              id="searcBox"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "3px",
                background: "#FFFFFF",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "#2323234D",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <input
                placeholder="Search..."
                type="text"
                className="searcInput"
                id="searchInput"
                style={styles.searchInput}
                autoComplete="off"
                onChange={(e) => filterList(e.target.value)}
              />
              <GridSearchIcon style={{ color: "#2323234D" }} />
            </Box>
          </Grid>
          <Paper>
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .super-app-theme--header": {
                  color: "#0071BC",
                },
              }}
            >
              <DataGridPremium
                sx={{
                  ".MuiTablePagination-selectLabel": {
                    margin: "0px",
                  },
                  ".MuiTablePagination-displayedRows": {
                    margin: "0px",
                  },
                }}
                rowHeight={33}
                pagination={true}
                pageSize={10}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                {...data}
                apiRef={apiRef}
                columns={columns}
                rows={CreatedSearch ? CreatedSearch : []}
                getRowId={(row) => row?.TicketId}
                loading={loading}
                disableRowSelectionOnClick
                initialState={initialState}
                slots={{ toolbar: GridToolbar }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              />
            </Box>
          </Paper>
        </>
      )}

      {head === "New" && (
        <CNewTable CreatedCount={CreatedCount} slacounts={slacounts} />
      )}
      {head === "Progress" && <CProgressTable />}
      {head === "Hold" && <CHoldTable />}
      {head === "Completed" && <CCompletedTable />}
      {head === "Solved" && <CSolvedTable />}
      {head === "Cancelled" && <CCancelledTable />}
    </div>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};
