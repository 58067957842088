import { Button, Card, Grid, InputAdornment, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";


function EditNotes() {
    const AppCode = global.$userData.logindata.AppCode;
    const userId = global.$userData.logindata.UserId;
    const navigate = useNavigate()
    const location = useLocation()
    const locationdata = location.state.NoteSide
    //usestate
    const [descripition, Setdescripition] = useState("")
    const [Title, Settitle] = useState("")


    //api

    const NotesEdit = async () => {

        const data = {
            NotesId: locationdata.AppNotesId,
            AppCode: AppCode,
            For: 5,
            UserId: userId,
            Description: descripition,
            Title: Title,
        }
        const response = await global.$axios.put(`/ticket/edit`, data)
            .then((response) => {
                if (response.data.Status === 200) {
                    toast.success("Notes Edited Successfully");
                    setTimeout(() => {
                        navigate(-1)
                    }, 1500)
                } else {
                    toast.error("Something Went Wrong")
                }
            })
            .catch((e) => {
                console.error("notespost data", e)
            })

    }
    useEffect(() => {
        Setdescripition(location.state.NoteSide.Description)
        Settitle(location.state.NoteSide.Title)
    }, [])

    return (
        <>
            <Grid container xs={12} style={{ display: "flex", alignItems: "center" }}>

                <span
                    style={{
                        color: "#0071BC",
                        fontWeight: 600,
                        fontSize: "16px",
                        margin: "0px",
                        padding: "0px",
                        cursor: "pointer",
                    }}
                    onClick={() => { navigate(-1) }}
                >
                    Notes
                </span>
                <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
                <span
                    style={{
                        color: "#0071BC",
                        fontWeight: 600,
                        fontSize: "22px",
                        margin: "0px",
                        padding: "0px",
                    }}
                >
                    Edit Notes</span>

            </Grid>
            <Grid container xs={12} lg={12} style={{ display: 'flex', padding: "5px 10px 0px 0px" }}>
                <Card style={{ marginTop: "20px", height: "60vh", width: "100vw", overflowY: "scroll" }}>
                    <Grid item xs={12} style={{
                        display: "flex", alignItems: "center", paddingLeft: "10px "
                    }}>
                        {/* <PushPinOutlinedIcon sx={{ mr: 1, my: 0.5 }} /> */}
                        <TextField
                            id="standard-multiline-flexible"
                            label="Title"
                            onChange={(e) => Settitle(e.target.value)}
                            value={Title}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        <PushPinOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}


                        />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", marginTop: '10px' }}>

                        <textarea
                            placeholder="Start Typing"
                            value={descripition}
                            onChange={(e) => Setdescripition(e.target.value)}
                            style={{
                                width: "100%",
                                // border: "1px solid gray",
                                // background: "#f5f5f5",
                                // borderRadius: "5px",
                                padding: "10px",
                                fontWeight: "200",
                                height: "100vh",
                                outline: "none",
                                border: "hidden",
                            }}
                        ></textarea>
                    </Grid>
                </Card>
            </Grid>
            <Grid container xs={12} style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                <Button
                    variant='contained'
                    // color='primary'
                    size='small'
                    onClick={() => NotesEdit()}
                    style={{
                        color: "white",
                        maxHeight: "22px",
                        minHeight: "22px",
                        textTransform: "none",
                        margin: "5px 0px 0px 0px",
                        background: "#0071BC"
                    }}>Save</Button>
            </Grid>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                fontSize={"14px"}
                fontWeight={"600"}
                color={"#232323"}
            />
        </>
    )
}

export default EditNotes